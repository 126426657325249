import React, { useState } from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import Button from "../../atoms/button";
import TabBar from "../../molecules/tab-bar";
import AddUserModal from "../../organisms/add-user-modal";
import AllUsersTab from "./all";

const UsersPage: React.FC<{
  getUsersPageLocation: (props: {
    id: string;
  }) => React.ComponentProps<typeof Link>["to"];
}> = ({ getUsersPageLocation }) => {
  const routeMatch = useRouteMatch();
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  return (
    <div>
      <AddUserModal
        open={openAddUserModal}
        onSuccess={(user) => {
          window.location.reload();
        }}
        onRequestClose={() => {
          setOpenAddUserModal(false);
        }}
      />
      <div className="flex flex-wrap items-center gap-4">
        <TabBar className="flex-1 overflow-x-auto" title="Utilisateurs">
          <TabBar.Tab to={`${routeMatch.url}/all`}>Tous</TabBar.Tab>
        </TabBar>
        <div className="flex gap-3">
          <Button
            className="rounded-lg border-2 border-blue-600 p-2 text-white"
            type="button"
            onClick={() => {
              setOpenAddUserModal(true);
            }}
          >
            Ajouter
          </Button>
        </div>
      </div>
      <div className="pt-6">
        <Switch>
          <Route exact path={routeMatch.path}>
            <Redirect to={`${routeMatch.url}/all`} />
          </Route>
          <Route path={`${routeMatch.path}/all`}>
            <AllUsersTab getUserPageLocation={getUsersPageLocation} />
          </Route>
          <Route>
            <Redirect to={routeMatch.url} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default UsersPage;
