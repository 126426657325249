/* eslint-disable react/jsx-no-useless-fragment */
import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import React, { useCallback } from "react";
import { saveAs } from "file-saver";
import {
  RetailerPageRetailerFragment,
  RetailerPageQuery,
  RetailerPageQueryVariables,
  GenerateRetailerInvoiceMutation,
  GenerateRetailerInvoiceMutationVariables,
  GenerateRetailerVibanMutation,
  GenerateRetailerVibanMutationVariables,
} from "../../../graphql-operations-types";
import Card from "../../molecules/card";
import DataGrid from "../../molecules/data-grid";
import DropDownMenu from "../../molecules/drop-down-menu";
import Toaster from "../../atoms/toaster";
import GetBackOfficeUser from "../../../login-provider";

const RETAILER_PAGE_RETAILER_FRAGMENT = gql`
  fragment RetailerPageRetailer on Retailer {
    id
    name
    stripeSepaCreditTransferSource {
      iban
      bic
    }
  }
`;

export const RETAILER_PAGE_QUERY = gql`
  ${RETAILER_PAGE_RETAILER_FRAGMENT}

  query RetailerPage($retailerId: String!) {
    retailer(id: $retailerId) {
      id
      ...RetailerPageRetailer
    }
  }
`;

const RETAILER_INVOICE_FRAGMENT = gql`
  fragment RetailerInvoiceRetailer on RetailerInvoice {
    id
    invoice {
      id
      pdfDownloadUrl
    }
  }
`;

const GENERATE_RETAILER_INVOICE_MUTATION = gql`
  ${RETAILER_PAGE_RETAILER_FRAGMENT}
  ${RETAILER_INVOICE_FRAGMENT}

  mutation GenerateRetailerInvoice($retailerId: UUID!) {
    generateRetailerInvoice(retailerId: $retailerId) {
      __typename
      ... on GenerateRetailerInvoice_Success {
        retailer {
          id
          ...RetailerPageRetailer
        }
        retailerInvoice {
          id
          ...RetailerInvoiceRetailer
        }
      }
      ... on GenerateRetailerInvoice_Failure {
        reason
      }
    }
  }
`;

const GENERATE_VIBAN_MUTATION = gql`
  ${RETAILER_PAGE_RETAILER_FRAGMENT}

  mutation GenerateRetailerViban($retailerId: UUID!) {
    generateRetailerViban(retailerId: $retailerId) {
      __typename
      ... on GenerateRetailerViban_Success {
        retailer {
          id
          ...RetailerPageRetailer
        }
      }
      ... on GenerateRetailerViban_Failure {
        reason
      }
    }
  }
`;

const DataCard: React.FC<{
  retailer: RetailerPageRetailerFragment;
}> = ({ retailer }) => (
  <Card>
    <Card.Header title="Informations" />
    <Card.Section title="Retailer">
      <DataGrid>
        <DataGrid.Cell title="Nom">{retailer.name}</DataGrid.Cell>
        <DataGrid.Cell title="Id">{retailer.id}</DataGrid.Cell>
        <DataGrid.Cell title="Viban">
          {retailer.stripeSepaCreditTransferSource?.iban ?? "-"}
        </DataGrid.Cell>
        <DataGrid.Cell title="Bic">
          {retailer.stripeSepaCreditTransferSource?.bic ?? "-"}
        </DataGrid.Cell>
      </DataGrid>
    </Card.Section>
  </Card>
);

const _RetailerPage: React.FC<{
  retailer: RetailerPageRetailerFragment;
  onGenerateRetailerInvoice: () => void;
  onGenerateRetailerViban: () => void;
}> = ({ retailer, onGenerateRetailerInvoice, onGenerateRetailerViban }) => {
  const backOfficeUser = GetBackOfficeUser();

  return (
    <div>
      <div className="mx-auto grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
        {!backOfficeUser?.isViewer && (
          <div className="flex justify-end">
            <DropDownMenu
              button={
                <DropDownMenu.Button className="rounded-lg border-2 border-blue-600 p-2 text-blue-600">
                  Actions
                </DropDownMenu.Button>
              }
            >
              <DropDownMenu.ItemGroup>
                <DropDownMenu.ButtonItem
                  onClick={() => {
                    onGenerateRetailerInvoice();
                  }}
                >
                  Générer les factures de missions non payés
                </DropDownMenu.ButtonItem>
                <DropDownMenu.ButtonItem
                  onClick={() => {
                    onGenerateRetailerViban();
                  }}
                >
                  Générer Viban
                </DropDownMenu.ButtonItem>
              </DropDownMenu.ItemGroup>
            </DropDownMenu>
          </div>
        )}

        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <DataCard retailer={retailer} />
        </div>
      </div>
    </div>
  );
};

const RetailerPage: React.FC<{ retailerId: string }> = ({ retailerId }) => {
  const queryResult = useQuery<RetailerPageQuery, RetailerPageQueryVariables>(
    RETAILER_PAGE_QUERY,
    { variables: { retailerId } }
  );

  const toaster = Toaster.useToaster();

  const [generateRetailerInvoiceMutation] = useMutation<
    GenerateRetailerInvoiceMutation,
    GenerateRetailerInvoiceMutationVariables
  >(GENERATE_RETAILER_INVOICE_MUTATION);
  const generateRetailerInvoice = useCallback(async () => {
    const generateRetailerInvoiceMutationResult =
      await generateRetailerInvoiceMutation({ variables: { retailerId } });
    if (
      generateRetailerInvoiceMutationResult.data?.generateRetailerInvoice
        .__typename === "GenerateRetailerInvoice_Success"
    ) {
      saveAs(
        generateRetailerInvoiceMutationResult.data.generateRetailerInvoice
          .retailerInvoice.invoice.pdfDownloadUrl,
        "Facture.pdf"
      );
    } else if (
      generateRetailerInvoiceMutationResult.data?.generateRetailerInvoice
        .__typename === "GenerateRetailerInvoice_Failure"
    ) {
      const reason =
        generateRetailerInvoiceMutationResult.data?.generateRetailerInvoice
          ?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [generateRetailerInvoiceMutation, retailerId, toaster]);

  const [generateRetailerVibanMutation] = useMutation<
    GenerateRetailerVibanMutation,
    GenerateRetailerVibanMutationVariables
  >(GENERATE_VIBAN_MUTATION);
  const generateRetailerViban = useCallback(async () => {
    const generateRetailerVibanMutationResult =
      await generateRetailerVibanMutation({ variables: { retailerId } });
    if (
      generateRetailerVibanMutationResult.data?.generateRetailerViban
        .__typename === "GenerateRetailerViban_Failure"
    ) {
      const reason =
        generateRetailerVibanMutationResult.data?.generateRetailerViban?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [generateRetailerVibanMutation, retailerId, toaster]);

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (queryResult.loading) {
    return null;
  }

  if (!queryResult.data?.retailer) {
    return <>Not Found</>;
  }

  return (
    <_RetailerPage
      retailer={queryResult.data.retailer}
      onGenerateRetailerInvoice={generateRetailerInvoice}
      onGenerateRetailerViban={generateRetailerViban}
    />
  );
};

export default RetailerPage;
