import { ExclamationIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import Button from "../atoms/button";
import Alert from "../molecules/alert";

const DeleteQuoteRequestAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}> = ({ show, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const _onSubmit = async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-8 w-8 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={
        <Alert.Title>Voulez-vous vraiment supprimer ce devis?</Alert.Title>
      }
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="button"
            onClick={_onSubmit}
            disabled={isLoading}
            loading={isLoading}
            className="bg-red-500 focus:ring-red-500 hover:bg-red-700"
          >
            Supprimer le devis
          </Button>
          <Button
            type="button"
            onClick={onClose}
            disabled={isLoading}
            loading={isLoading}
          >
            Annuler
          </Button>
        </Alert.Footer>
      }
    />
  );
};

export default DeleteQuoteRequestAlert;
