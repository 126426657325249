import classNames from "classnames";
import React from "react";

const Header: React.FC<
  React.ComponentProps<"div"> & {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    danger?: boolean;
  }
> = ({ className, title, subtitle, danger, ...props }) => (
  <div
    className={
      danger
        ? classNames("px-4 py-5 sm:px-6 bg-red-400", className)
        : classNames("px-4 py-5 sm:px-6", className)
    }
    {...props}
  >
    <h2
      className={
        danger
          ? "text-lg font-medium leading-6 text-white"
          : "text-lg font-medium leading-6 text-gray-900"
      }
    >
      {title}
    </h2>
    {subtitle && (
      <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>
    )}
  </div>
);

const Section: React.FC<
  React.ComponentProps<"div"> & { title?: React.ReactNode; padded?: boolean }
> = ({ className, children, title, padded = true, ...props }) => (
  <div
    className={classNames("border-t-4 border-gray-200", className)}
    {...props}
  >
    {title && (
      <div className="bg-gray-200 px-4 py-2 sm:px-6">
        <h3 className="whitespace-nowrap text-left text-xs font-medium uppercase tracking-wider text-gray-500">
          {title}
        </h3>
      </div>
    )}
    <div className={classNames({ "px-4 py-5 sm:px-6": padded })}>
      {children}
    </div>
  </div>
);

const Card: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      "overflow-hidden bg-white shadow sm:rounded-lg",
      className
    )}
    {...props}
  />
);

export default Object.assign(Card, { Header, Section });
