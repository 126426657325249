import classNames from "classnames";
import React from "react";

const CheckboxLabel: React.FC<React.ComponentProps<"label">> = ({
  className,
  ...props
}) => (
  <label
    className={classNames(
      "flex items-center space-x-2 text-sm text-grey-2",
      className,
    )}
    {...props}
  />
);

export default CheckboxLabel;
