import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import Button from "../atoms/button";
import Alert from "../molecules/alert";

const MatchNextExpertAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  loading: boolean;
}> = ({ show, onClose, onSubmit, loading }) => {
  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-6 w-6 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={
        <Alert.Title>
          Voulez-vous vraiment annuler la job offer en cours et passer à
          l'expert suivant ?
        </Alert.Title>
      }
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="button"
            onClick={onSubmit}
            disabled={loading}
            loading={loading}
          >
            Passer à l'expert suivant
          </Button>
          <Button
            type="button"
            onClick={onClose}
            disabled={loading}
            loading={loading}
          >
            Retour
          </Button>
        </Alert.Footer>
      }
    />
  );
};

export default MatchNextExpertAlert;
