import { ApolloQueryResult, gql, QueryResult, useQuery } from "@apollo/client";
import {
  Exact,
  Maybe,
  MissionsQuery,
  MissionsQueryParameters,
  MissionsQueryVariables,
} from "../../../../graphql-operations-types";

const QUERY = gql`
  fragment ExpertMission on Expert {
    id
    phoneNumber
    fullName
    profilePictureURL
  }

  fragment JobOfferMission on JobOffer {
    id
    expert {
      ...ExpertMission
    }
  }

  fragment TabMissionRequest on Mission {
    id
    missionStatus: status
    maybeAddress: address {
      zipCode
    }
    createdAt
    date
    customerSuccessReminderDate
    offer {
      id
      name
    }
    retailer {
      id
      name
    }
    acceptedJobOffer {
      ...JobOfferMission
    }
    customer {
      firstName
      lastName
      phoneNumber
    }
    doneAt
  }

  query Missions($requestsQueryParameters: MissionsQueryParameters) {
    missions(parameters: $requestsQueryParameters) {
      totalCount
      missions {
        ...TabMissionRequest
      }
    }
  }
`;

type RetrieveMissionsProperties = {
  requestsQueryParameters: MissionsQueryParameters;
};

export const RetrieveMissions = ({
  requestsQueryParameters,
}: RetrieveMissionsProperties): QueryResult<
  MissionsQuery,
  Exact<{
    requestsQueryParameters?: Maybe<MissionsQueryParameters> | undefined;
  }>
> => {
  const queryResult = useQuery<MissionsQuery, MissionsQueryVariables>(QUERY, {
    variables: {
      requestsQueryParameters: {
        ...requestsQueryParameters,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return queryResult;
};

type RefetchMissionsProperties = {
  query: QueryResult<
    MissionsQuery,
    Exact<{
      requestsQueryParameters?: Maybe<MissionsQueryParameters> | undefined;
    }>
  >;
  requestsQueryParameters: MissionsQueryParameters;
};

export const RefetchMissions = ({
  query,
  requestsQueryParameters,
}: RefetchMissionsProperties): Promise<ApolloQueryResult<MissionsQuery>> => {
  const queryResult = query.refetch({
    requestsQueryParameters: {
      ...requestsQueryParameters,
    },
  });

  return queryResult;
};
