import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../atoms/button";
import Select from "../atoms/select";
import Alert from "../molecules/alert";
import { CanceledQuoteRequest_CancelationReason } from "../../graphql-operations-types";
import TextArea from "../atoms/text-area";

export interface IFormInputs {
  choice: CanceledQuoteRequest_CancelationReason;
  cancelationComment: string;
}

const schema = yup
  .object({
    choice: yup
      .string()
      .oneOf(
        [
          "CANCELED_BY_HOST",
          "CANT_FIND_AVAILABLE_EXPERT",
          "CANCELED_BY_PLATFORM",
        ],
        "veuillez selectionner une raison"
      )
      .required(),
    cancelationComment: yup.string().required(),
  })
  .required();

export type Choice = {
  value: CanceledQuoteRequest_CancelationReason;
  label: string;
};

const choices: Choice[] = [
  {
    value: "CANCELED_BY_HOST",
    label: "Annulé par le client",
  },
  {
    value: "CANT_FIND_AVAILABLE_EXPERT",
    label: "Pas d'Experts disponibles",
  },
  {
    value: "CANCELED_BY_PLATFORM",
    label: "Annulé par Dealt",
  },
];

const CancelQuoteRequestAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (
    choice: Choice["value"],
    cancelationComment: string
  ) => Promise<void>;
}> = ({ show, onClose, onSubmit }) => {
  const { register, handleSubmit, formState, setValue } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const _onSubmit = async (data: IFormInputs) => {
    await onSubmit(data.choice, data.cancelationComment);
  };

  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-6 w-6 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={<Alert.Title>Voulez-vous vraiment annuler ce devis ?</Alert.Title>}
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="submit"
            form="cancel-quote-request-alert"
            disabled={formState.isSubmitting || !formState.isValid}
            loading={formState.isSubmitting}
          >
            Annuler la prestation
          </Button>
          <Button
            type="button"
            onClick={onClose}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Retour
          </Button>
        </Alert.Footer>
      }
    >
      <Alert.Body>
        <form
          id="cancel-quote-request-alert"
          onSubmit={handleSubmit(_onSubmit)}
        >
          Veuillez donner les raisons de ce refus
          <Select
            {...register("choice")}
            className="w-full"
            disabled={formState.isSubmitting}
          >
            <option selected disabled>
              Choisissez...
            </option>
            {choices.map((choice) => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </Select>
          {formState.errors.choice && (
            <span className="text-red-400">
              {formState.errors.choice.message}
            </span>
          )}
          <p className="mt-5">Ajoutez un commentaire lié à l'annulation</p>
          <TextArea
            {...register("cancelationComment")}
            className="block w-full rounded-md mt-1"
            rows={5}
            disabled={formState.isSubmitting}
            onChange={(e) =>
              setValue("cancelationComment", e.target.value, {
                shouldValidate: true,
              })
            }
          />
        </form>
      </Alert.Body>
    </Alert>
  );
};

export default CancelQuoteRequestAlert;
