import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

const Head: React.FC<React.ComponentProps<"thead">> = ({
  className,
  ...props
}) => <thead className={classNames("bg-gray-50", className)} {...props} />;

const Body: React.FC<React.ComponentProps<"tbody">> = ({
  className,
  ...props
}) => (
  <tbody
    className={classNames("divide-y divide-gray-200 bg-white", className)}
    {...props}
  />
);

type RowProps = {
  odd?: boolean;
};
const Row: React.FC<React.ComponentProps<"tr"> & RowProps> = ({
  className,
  odd,
  ...props
}) => (
  <tr
    className={classNames(
      {
        "bg-white": !odd,
        "bg-gray-50": odd,
      },
      className
    )}
    {...props}
  />
);

const RowLink: React.FC<React.ComponentProps<typeof Link> & RowProps> = ({
  className,
  odd,
  ...props
}) => (
  <Link
    role="row"
    className={classNames(
      "table-row hover:bg-blue-100",
      {
        "bg-white": !odd,
        "bg-gray-50": odd,
      },
      className
    )}
    {...props}
  />
);

const HeadCell: React.FC<React.ComponentProps<"th">> = ({
  className,
  ...props
}) => (
  <th
    scope="col"
    className={classNames(
      "whitespace-nowrap px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500",
      className
    )}
    {...props}
  />
);

const DataCell: React.FC<React.ComponentProps<"td">> = ({
  className,
  ...props
}) => (
  <td
    className={classNames("whitespace-nowrap px-6 py-4 text-sm", className)}
    {...props}
  />
);

const _Table: React.FC<React.ComponentProps<"div">> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames("flex flex-col", className)} {...props}>
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            {children}
          </table>
        </div>
      </div>
    </div>
  </div>
);

const Table = Object.assign(_Table, {
  Head,
  Body,
  Row,
  RowLink,
  DataCell,
  HeadCell,
});

export default Table;
