import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link } from "react-router-dom";
import Table from "../../../molecules/table";
import {
  AllRetailersTabQuery,
  AllRetailersTabQueryVariables,
  AllRetailersTabRetailerFragment,
} from "../../../../graphql-operations-types";

const QUERY = gql`
  fragment AllRetailersTabRetailer on Retailer {
    id
    name
  }

  query AllRetailersTab {
    retailers {
      id
      ...AllRetailersTabRetailer
    }
  }
`;

const RetailerRow: React.FC<
  React.ComponentProps<typeof Table.RowLink> & {
    retailer: AllRetailersTabRetailerFragment;
  }
> = ({ retailer, ...props }) => {
  return (
    <Table.RowLink {...props}>
      {/* id */}
      <Table.DataCell className="font-normal">{retailer.id}</Table.DataCell>

      {/* nom */}
      <Table.DataCell className="font-normal">{retailer.name}</Table.DataCell>
    </Table.RowLink>
  );
};

const _AllRequestsTab: React.FC<{
  retailersQueryResult: AllRetailersTabQuery;
  getRetailersPageLocation: (
    retailer: AllRetailersTabRetailerFragment
  ) => React.ComponentProps<typeof Link>["to"];
}> = ({ retailersQueryResult, getRetailersPageLocation }) => {
  return (
    <div className="space-y-2">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>id</Table.HeadCell>
            <Table.HeadCell>nom</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {retailersQueryResult.retailers.map((retailer, i) => (
            <RetailerRow
              key={retailer.id}
              retailer={retailer}
              odd={i % 2 === 0}
              to={getRetailersPageLocation(retailer)}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

const AllRetailersTab: React.FC<{
  getRetailersPageLocation: React.ComponentProps<
    typeof _AllRequestsTab
  >["getRetailersPageLocation"];
}> = ({ getRetailersPageLocation }) => {
  const queryResult = useQuery<
    AllRetailersTabQuery,
    AllRetailersTabQueryVariables
  >(QUERY, {});

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <_AllRequestsTab
      retailersQueryResult={queryResult.data}
      getRetailersPageLocation={getRetailersPageLocation}
    />
  );
};

export default AllRetailersTab;
