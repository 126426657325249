import { ExclamationIcon } from "@heroicons/react/outline";
import React, { useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputMask from "react-input-mask";
import Button from "../atoms/button";
import Select from "../atoms/select";
import Alert from "../molecules/alert";
import InputGroup from "../atoms/input-group";
import Input from "../atoms/input";

export interface IFormInputs {
  choice: number;
  coupon?: string;
}

export type Choice = {
  value: number;
  label: string;
};

const choices: Choice[] = [
  {
    value: 0.2,
    label: "20%",
  },
  {
    value: 0.1,
    label: "10%",
  },
  {
    value: 0.055,
    label: "5.5%",
  },
  {
    value: 0.021,
    label: "2.1%",
  },
  {
    value: 0,
    label: "Non assujeti à la TVA",
  },
];

const retailerCouponRegexpString =
  "^[ABCDEFGHJKMNPQRSTUVWXYZ123456789]{4}-[ABCDEFGHJKMNPQRSTUVWXYZ123456789]{2}-[ABCDEFGHJKMNPQRSTUVWXYZ123456789]{4}$";
const retailerCouponRegexp = new RegExp(retailerCouponRegexpString);

const CompleteMissionAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (data: IFormInputs) => Promise<void>;
  requireCoupon: boolean;
}> = ({ show, onClose, onSubmit, requireCoupon }) => {
  const schema = useMemo(() => {
    return yup
      .object({
        choice: yup
          .number()
          .oneOf(
            [0.2, 0.1, 0.055, 0.021, 0],
            "veuillez sélectionner un taux de TVA"
          )
          .required(),
        ...(requireCoupon
          ? {
              coupon: yup
                .string()
                .transform((value) => {
                  if (typeof value !== "string") {
                    return value;
                  }

                  return value.toUpperCase();
                })
                .matches(retailerCouponRegexp)
                .required(),
            }
          : {}),
      })
      .required();
  }, [requireCoupon]);

  const { register, handleSubmit, formState, control } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const _onSubmit = async (data: IFormInputs) => {
    await onSubmit(data);
  };

  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-6 w-6 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={
        <Alert.Title>
          Voulez-vous vraiment terminer cette prestation ?
        </Alert.Title>
      }
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="submit"
            form="cancel-mission-alert"
            disabled={formState.isSubmitting || !formState.isValid}
            loading={formState.isSubmitting}
          >
            Terminer la prestation
          </Button>
          <Button
            type="button"
            onClick={onClose}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Retour
          </Button>
        </Alert.Footer>
      }
    >
      <Alert.Body>
        <form id="cancel-mission-alert" onSubmit={handleSubmit(_onSubmit)}>
          Veuillez selectionner un taux de TVA
          <Select
            {...register("choice")}
            className="w-full"
            disabled={formState.isSubmitting}
          >
            <option selected disabled>
              Choisissez...
            </option>
            {choices.map((choice) => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </Select>
          {formState.errors.choice && (
            <span className="text-red-400">
              {formState.errors.choice.message}
            </span>
          )}
        </form>
        {requireCoupon && (
          <div className="mt-2 text-center">
            Veuillez renseigner un coupon
            <InputGroup
              input={
                <Controller
                  control={control}
                  name="coupon"
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <InputMask
                      mask="****-**-****"
                      onBlur={onBlur}
                      onChange={onChange}
                      inputRef={ref}
                    >
                      <Input
                        placeholder="Code coupon"
                        className="placeholder-grey-2 text-center text-base"
                      />
                    </InputMask>
                  )}
                />
              }
            />
          </div>
        )}
      </Alert.Body>
    </Alert>
  );
};

export default CompleteMissionAlert;
