import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { QueryParamProvider } from "use-query-params";
import RootRouter from "./root-router";
import graphqlFragmentsTypes from "./graphql-fragments-types";
import Toaster from "./components/atoms/toaster";
import { LoginProvider } from "./login-provider";

const apolloClient = new ApolloClient({
  uri: "/graphql",
  cache: new InMemoryCache({
    possibleTypes: graphqlFragmentsTypes.possibleTypes,
  }),
});

const App: React.FC = () => (
  <IntlProvider locale="fr" defaultLocale="fr">
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ApolloProvider client={apolloClient}>
          <Toaster.ToasterProvider>
            <LoginProvider>
              <RootRouter />
            </LoginProvider>
          </Toaster.ToasterProvider>
        </ApolloProvider>
      </QueryParamProvider>
    </BrowserRouter>
  </IntlProvider>
);

export default App;
