import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import DropDownMenu from "../../molecules/drop-down-menu";
import TabBar from "../../molecules/tab-bar";
import {
  NumRequestsTabQuery,
  NumRequestsTabQueryVariables,
  MissionssPriorityNumTabQuery,
  MissionssPriorityNumTabQueryVariables,
  MissionsQueryParametersWhereStatus,
} from "../../../graphql-operations-types";
import SubmitMissionModal from "../../organisms/submit-mission-modal";
import GetBackOfficeUser from "../../../login-provider";
import { TabCount } from "../../molecules/tab-count";
import AcceptedRequestsTab from "./tabs/accepted";
import AllRequestsTab from "./tabs/all";
import CanceledRequestsTab from "./tabs/canceled";
import DoneRequestsTab from "./tabs/done";
import DraftRequestsTab from "./tabs/draft";
import SubmittedRequestsTab from "./tabs/submitted";
import PriorityMissionsTab from "./tabs/priority";
import WaitingForPaymentRequestsTab from "./tabs/waiting-for-payment";
import PaymentBlockedRequestsTab from "./tabs/payment-blocked";
import SAPMissionsTab from "./tabs/sap";

const QUERY = gql`
  query NumRequestsTab($requestsQueryParameters: MissionsQueryParameters) {
    missions(parameters: $requestsQueryParameters) {
      totalCount
    }
  }
`;

const MISSIONS_PRIORITY_NUM_TAB_QUERY = gql`
  query MissionssPriorityNumTab {
    priorityMissions {
      totalCount
    }
  }
`;

const RequestsPage: React.FC<{
  getRequestPageLocation: (props: {
    id: string;
  }) => React.ComponentProps<typeof Link>["to"];
}> = ({ getRequestPageLocation }) => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [openSubmitMissionModal, setOpenSubmitMissionModal] = useState(false);
  const backOfficeUser = GetBackOfficeUser();

  return (
    <div>
      <SubmitMissionModal
        open={openSubmitMissionModal}
        onSuccess={(mission) => {
          const getRequestPageLocationResult = getRequestPageLocation(mission);
          if (typeof getRequestPageLocationResult === "function") {
            const requestPageLocation = getRequestPageLocationResult(location);
            history.push(requestPageLocation);
          } else {
            history.push(getRequestPageLocationResult);
          }
        }}
        onRequestClose={() => {
          setOpenSubmitMissionModal(false);
        }}
      />
      <div className="flex gap-4 items-center flex-wrap">
        <TabBar className="flex-1 overflow-x-auto" title="Missions">
          <TabBar.Tab to={`${routeMatch.url}/priority`}>
            Prioritaires
            <PriorityNumRequestsTab />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/sap`}>
            SAP
            <SAPNumRequestsTab />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/payment-blocked`}>
            Bloquées
            <NumRequestsTab status="ACCEPTED" paymentBlocked={true} />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/waiting-for-payment`}>
            En attente de paiement
            <NumRequestsTab status="ACCEPTED" waitingForPayment={true} />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/all`}>Toutes</TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/submitted`}>
            Postées
            <NumRequestsTab status="SUBMITTED" />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/accepted`}>
            En cours
            <NumRequestsTab status="ACCEPTED" />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/done`}>
            Terminées
            <NumRequestsTab status="DONE" />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/draft`}>
            A transformer
            <NumRequestsTab status="DRAFT" />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/canceled`}>
            Annulées
            <NumRequestsTab status="CANCELLED" />
          </TabBar.Tab>
        </TabBar>
        {!backOfficeUser?.isViewer && (
          <div className="flex gap-3">
            <DropDownMenu
              button={
                <DropDownMenu.Button className="rounded-lg border-2 border-blue-600 p-2 text-blue-600">
                  Actions
                </DropDownMenu.Button>
              }
            >
              <DropDownMenu.ItemGroup className="absolute z-30 rounded bg-white shadow-md">
                <DropDownMenu.ButtonItem
                  onClick={() => {
                    setOpenSubmitMissionModal(true);
                  }}
                >
                  Soumettre une prestration sans paiement
                </DropDownMenu.ButtonItem>
              </DropDownMenu.ItemGroup>
            </DropDownMenu>
          </div>
        )}
      </div>
      <div className="pt-6">
        <Switch>
          <Route exact path={routeMatch.path}>
            <Redirect to={`${routeMatch.url}/priority`} />
          </Route>
          <Route path={`${routeMatch.path}/payment-blocked`}>
            <PaymentBlockedRequestsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/sap`}>
            <SAPMissionsTab getRequestPageLocation={getRequestPageLocation} />
          </Route>
          <Route path={`${routeMatch.path}/waiting-for-payment`}>
            <WaitingForPaymentRequestsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/all`}>
            <AllRequestsTab getRequestPageLocation={getRequestPageLocation} />
          </Route>
          <Route path={`${routeMatch.path}/priority`}>
            <PriorityMissionsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/submitted`}>
            <SubmittedRequestsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/accepted`}>
            <AcceptedRequestsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/done`}>
            <DoneRequestsTab getRequestPageLocation={getRequestPageLocation} />
          </Route>
          <Route path={`${routeMatch.path}/draft`}>
            <DraftRequestsTab getRequestPageLocation={getRequestPageLocation} />
          </Route>
          <Route path={`${routeMatch.path}/canceled`}>
            <CanceledRequestsTab
              getRequestPageLocation={getRequestPageLocation}
            />
          </Route>
          <Route>
            <Redirect to={routeMatch.url} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const NumRequestsTab: React.FC<{
  status: MissionsQueryParametersWhereStatus;
  waitingForPayment?: boolean;
  paymentBlocked?: boolean;
}> = ({ status, waitingForPayment, paymentBlocked }) => {
  const queryResult = useQuery<
    NumRequestsTabQuery,
    NumRequestsTabQueryVariables
  >(QUERY, {
    variables: {
      requestsQueryParameters: {
        where: {
          status,
          waitingForPayment: waitingForPayment ?? false,
          paymentBlocked: paymentBlocked ?? false,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return <TabCount count={queryResult.data.missions.totalCount} />;
};

const PriorityNumRequestsTab: React.FC = () => {
  const queryResult = useQuery<
    MissionssPriorityNumTabQuery,
    MissionssPriorityNumTabQueryVariables
  >(MISSIONS_PRIORITY_NUM_TAB_QUERY, {
    fetchPolicy: "cache-and-network",
  });

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <TabCount count={queryResult.data.priorityMissions?.totalCount ?? 0} />
  );
};

const SAPNumRequestsTab: React.FC = () => {
  const queryResult = useQuery<
    NumRequestsTabQuery,
    NumRequestsTabQueryVariables
  >(QUERY, {
    variables: {
      requestsQueryParameters: {
        where: {
          isSap: true,
        },
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return <TabCount count={queryResult.data.missions.totalCount} />;
};

export default RequestsPage;
