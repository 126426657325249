import React from "react";
import { gql, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { noop } from "lodash";
import { useAsyncFn } from "react-use";
import Button from "../../atoms/button";
import {
  LogInMutation,
  LogInMutationVariables,
} from "../../../graphql-operations-types";
import InputGroup from "../../atoms/input-group";
import logoSrc from "./logo.svg";

export const LOG_IN_MUTATION = gql`
  mutation LogIn($emailAddress: EmailAddress!, $password: String!) {
    logIn(emailAddress: $emailAddress, password: $password) {
      __typename
      ... on LogInMutation_Failure {
        reason
      }
    }
  }
`;

type FormData = {
  emailAddress: string;
  password: string;
};

const schema = yup.object().shape({
  emailAddress: yup
    .string()
    .email("Adresse email invalide")
    .required("Adresse email requise"),
  password: yup.string().required("Mot de passe requis"),
});

const LoginPage: React.FC<{
  onLoginSuccess?: () => void;
}> = ({ onLoginSuccess = noop }) => {
  const [triggerLoginMutation] = useMutation<
    LogInMutation,
    LogInMutationVariables
  >(LOG_IN_MUTATION);
  const form = useForm<FormData>({
    resolver: yupResolver(schema),
  });
  const [validSubmitHandlerState, validSubmitHandler] = useAsyncFn(
    async (formData: FormData) => {
      const triggerLoginMutationResult = await triggerLoginMutation({
        variables: {
          emailAddress: formData.emailAddress,
          password: formData.password,
        },
      });

      if (
        triggerLoginMutationResult.data?.logIn.__typename ===
        "LogInMutation_Failure"
      ) {
        switch (triggerLoginMutationResult.data.logIn.reason) {
          case "INVALID_PASSWORD":
            return form.setError(
              "password",
              {
                message: "Mot de passe incorrect",
              },
              { shouldFocus: true }
            );
          case "USER_NOT_FOUND":
            return form.setError(
              "emailAddress",
              {
                message: "Addresse email non enregistrée",
              },
              { shouldFocus: true }
            );
        }
      }

      if (
        triggerLoginMutationResult.data?.logIn.__typename ===
        "LogInMutation_Success"
      ) {
        return onLoginSuccess();
      }
    },
    [form, onLoginSuccess, triggerLoginMutation]
  );

  if (validSubmitHandlerState.error) {
    throw validSubmitHandlerState.error;
  }

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logoSrc} alt="Workflow" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={form.handleSubmit(validSubmitHandler)}
          >
            <InputGroup
              loading={form.formState.isSubmitting}
              label={
                <InputGroup.Label htmlFor="emailAddress">
                  Adresse email
                </InputGroup.Label>
              }
              input={
                <InputGroup.Input
                  id="emailAddress"
                  type="email"
                  autoComplete="email"
                  readOnly={form.formState.isSubmitting}
                  {...form.register("emailAddress")}
                />
              }
              error={
                form.formState.errors.emailAddress && (
                  <InputGroup.Error>
                    {form.formState.errors.emailAddress.message}
                  </InputGroup.Error>
                )
              }
            />

            <InputGroup
              loading={form.formState.isSubmitting}
              label={
                <InputGroup.Label htmlFor="password">
                  Mot de passe
                </InputGroup.Label>
              }
              input={
                <InputGroup.Input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  readOnly={form.formState.isSubmitting}
                  {...form.register("password")}
                />
              }
              error={
                form.formState.errors.password && (
                  <InputGroup.Error>
                    {form.formState.errors.password.message}
                  </InputGroup.Error>
                )
              }
            />

            <div>
              <Button
                type="submit"
                className="w-full"
                disabled={form.formState.isSubmitting}
                loading={form.formState.isSubmitting}
              >
                Connexion
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
