import React from "react";
import classNames from "classnames";

const Checkbox = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input">
>(({ className, ...props }, ref) => (
  <input
    type="checkbox"
    ref={ref}
    className={classNames(
      className,
      "form-checkbox h-[18px] w-[18px] rounded-md border-grey-3 text-primary shadow-lg focus:outline-none focus:ring-primary ml-3"
    )}
    {...props}
  />
));

export default Checkbox;
