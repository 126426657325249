import React, { useCallback } from "react";
import Radio from "../../atoms/radio";
import { MultipleChoiceQuestionFragment } from "../../../graphql-operations-types";

type _MultipleChoiceQuestionProps = {
  question: MultipleChoiceQuestionFragment;
  onChange: (value: string) => void;
  error?: string | undefined;
  answerValue?: string[];
};
const _MultipleChoiceQuestion = ({
  question,
  onChange,
  error,
  answerValue,
}: _MultipleChoiceQuestionProps) => {
  const handleInputChange = useCallback(
    (value: string) => () => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <>
      <div
        className={`mt-2 ${error && "border-2 border-red-600 rounded-md py-2"}`}
      >
        {question.options.map((option) => (
          <Radio.Label key={option.value}>
            <Radio
              name={question.reference}
              onClick={handleInputChange(option.value)}
              defaultChecked={
                answerValue ? answerValue.includes(option.value) : false
              }
            />
            <div>{option.label}</div>
          </Radio.Label>
        ))}
      </div>
      {error && <div className="mt-2 text-sm text-red-600 flex">{error}</div>}
    </>
  );
};

type Props = {
  question: MultipleChoiceQuestionFragment;
  onChange: (reference: string, value: string, typename?: string) => void;
  error?: string | undefined;
  value?: Map<string, string[]>;
};
const MultipleChoiceQuestion = ({
  question,
  onChange,
  error,
  value,
}: Props) => {
  const handleQuestionAnswerChange = useCallback(
    (value: string) => {
      onChange(question.reference, value, question.__typename);
    },
    [onChange, question.reference, question.__typename]
  );

  return (
    <_MultipleChoiceQuestion
      onChange={handleQuestionAnswerChange}
      question={question}
      error={error}
      answerValue={value?.get(question.reference)}
    />
  );
};

export default MultipleChoiceQuestion;
