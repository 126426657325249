import React from "react";
import { Link, Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import TabBar from "../../molecules/tab-bar";
import AllRetailersTab from "./tabs/all";

const RetailersPage: React.FC<{
  getRetailersPageLocation: (props: {
    id: string;
  }) => React.ComponentProps<typeof Link>["to"];
}> = ({ getRetailersPageLocation }) => {
  const routeMatch = useRouteMatch();

  return (
    <div>
      <div className="flex flex-wrap items-center gap-4">
        <TabBar className="flex-1 overflow-x-auto" title="Retailers">
          <TabBar.Tab to={`${routeMatch.url}/all`}>Tous</TabBar.Tab>
        </TabBar>
      </div>
      <div className="pt-6">
        <Switch>
          <Route exact path={routeMatch.path}>
            <Redirect to={`${routeMatch.url}/all`} />
          </Route>
          <Route path={`${routeMatch.path}/all`}>
            <AllRetailersTab
              getRetailersPageLocation={getRetailersPageLocation}
            />
          </Route>
          <Route>
            <Redirect to={routeMatch.url} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default RetailersPage;
