import React, { useCallback, useState } from "react";
import Button from "../atoms/button";
import Input from "../atoms/input";
import { useLocation } from "react-use";
import { useHistory } from "react-router-dom";

const FilterDropDown: React.FC<{
  onSubmit: (properties: {
    afterCreatedDate?: string | null;
    afterSubmittedDate?: string | null;
    beforeCsReminderDate?: string | null;
    afterCsReminderDate?: string | null;
    customer?: string | null;
    phoneNumber?: string | null;
    postCode?: string | null;
    expert?: string | null;
    store?: string | null;
    retailer?: string | null;
  }) => void;
  afterCreatedDateLabel?: string;
  afterSubmittedDateLabel?: string;
  beforeCsReminderDateLabel?: string;
  afterCsReminderDateLabel?: string;
  customerLabel?: string;
  phoneNumberLabel?: string;
  postCodeLabel?: string;
  expertLabel?: string;
  storeLabel?: string;
  retailerLabel?: string;
}> = ({
  onSubmit,
  afterCreatedDateLabel,
  afterSubmittedDateLabel,
  beforeCsReminderDateLabel,
  afterCsReminderDateLabel,
  customerLabel,
  phoneNumberLabel,
  postCodeLabel,
  expertLabel,
  storeLabel,
  retailerLabel,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const history = useHistory();

  const [afterCreatedDateValue, setAfterCreatedDateValue] = useState(
    queryParams.get("after_created_date") ?? ""
  );
  const [afterSubmittedDateValue, setAfterSubmittedDateValue] = useState(
    queryParams.get("after_submitted_date") ?? ""
  );
  const [beforeCsReminderDateValue, setBeforeCsReminderDateValue] = useState(
    queryParams.get("before_cs_reminder_date") ?? ""
  );
  const [afterCsReminderDateValue, setAfterCsReminderDateValue] = useState(
    queryParams.get("after_cs_reminder_date") ?? ""
  );
  const [customerValue, setCustomerValue] = useState(
    queryParams.get("customer") ?? ""
  );
  const [phoneNumberValue, setPhoneNumberValue] = useState(
    queryParams.get("phone_number") ?? ""
  );
  const [postCodeValue, setPostCodeValue] = useState(
    queryParams.get("post_code") ?? ""
  );
  const [expertValue, setExpertValue] = useState(
    queryParams.get("expert") ?? ""
  );
  const [storeValue, setStoreValue] = useState(queryParams.get("store") ?? "");
  const [retailerValue, setRetailerValue] = useState(
    queryParams.get("retailer") ?? ""
  );
  const [open, setOpen] = useState(false);

  const handleSubmit = useCallback(() => {
    if (afterCreatedDateValue) {
      queryParams.set("after_created_date", afterCreatedDateValue);
    } else {
      queryParams.delete("after_created_date");
    }
    if (afterSubmittedDateValue) {
      queryParams.set("after_submitted_date", afterSubmittedDateValue);
    } else {
      queryParams.delete("after_submitted_date");
    }
    if (beforeCsReminderDateValue) {
      queryParams.set("before_cs_reminder_date", beforeCsReminderDateValue);
    } else {
      queryParams.delete("before_cs_reminder_date");
    }
    if (afterCsReminderDateValue) {
      queryParams.set("after_cs_reminder_date", afterCsReminderDateValue);
    } else {
      queryParams.delete("after_cs_reminder_date");
    }
    if (customerValue) {
      queryParams.set("customer", customerValue);
    } else {
      queryParams.delete("customer");
    }
    if (phoneNumberValue) {
      queryParams.set("phone_number", phoneNumberValue);
    } else {
      queryParams.delete("phone_number");
    }
    if (postCodeValue) {
      queryParams.set("post_code", postCodeValue);
    } else {
      queryParams.delete("post_code");
    }
    if (expertValue) {
      queryParams.set("expert", expertValue);
    } else {
      queryParams.delete("expert");
    }
    if (storeValue) {
      queryParams.set("store", storeValue);
    } else {
      queryParams.delete("store");
    }
    if (retailerValue) {
      queryParams.set("retailer", retailerValue);
    } else {
      queryParams.delete("retailer");
    }
    history.push(window.location.pathname + "?" + queryParams.toString());

    setOpen(false);
    onSubmit({
      afterCreatedDate: afterCreatedDateValue,
      afterSubmittedDate: afterSubmittedDateValue,
      beforeCsReminderDate: beforeCsReminderDateValue,
      afterCsReminderDate: afterCsReminderDateValue,
      customer: customerValue,
      phoneNumber: phoneNumberValue,
      postCode: postCodeValue,
      expert: expertValue,
      store: storeValue,
      retailer: retailerValue,
    });
  }, [
    onSubmit,
    afterCreatedDateValue,
    afterSubmittedDateValue,
    beforeCsReminderDateValue,
    afterCsReminderDateValue,
    customerValue,
    phoneNumberValue,
    postCodeValue,
    expertValue,
    storeValue,
    retailerValue,
  ]);

  return (
    <div className="flex flex-col">
      <div className="flex justify-end">
        <button
          type="button"
          className="rounded-lg border border-gray-400 bg-white py-2 px-4 font-semibold text-gray-800 shadow hover:bg-gray-100 text-base"
          onClick={() => setOpen(!open)}
        >
          <span className="z-10 w-12">Filtrer</span>
        </button>
      </div>
      {open && (
        <div className="self-end flex-wrap rounded-lg bg-white p-4 shadow-md">
          <p className="text-sm font-medium text-gray-500">
            Filtrer les prestations
          </p>
          {afterCreatedDateLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {afterCreatedDateLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="created-date"
                  onChange={(event) => {
                    setAfterCreatedDateValue(event.currentTarget.value);
                  }}
                  value={afterCreatedDateValue}
                  type="date"
                />
              </div>
            </>
          )}
          {afterSubmittedDateLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {afterSubmittedDateLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="submitted-date"
                  onChange={(event) =>
                    setAfterSubmittedDateValue(event.currentTarget.value)
                  }
                  value={afterSubmittedDateValue}
                  type="date"
                />
              </div>
            </>
          )}
          {beforeCsReminderDateLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {beforeCsReminderDateLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="before-cs-reminder-date"
                  onChange={(event) =>
                    setBeforeCsReminderDateValue(event.currentTarget.value)
                  }
                  value={beforeCsReminderDateValue}
                  type="date"
                />
              </div>
            </>
          )}
          {afterCsReminderDateLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {afterCsReminderDateLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="after-cs-reminder-date"
                  onChange={(event) =>
                    setAfterCsReminderDateValue(event.currentTarget.value)
                  }
                  value={afterCsReminderDateValue}
                  type="date"
                />
              </div>
            </>
          )}
          {customerLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {customerLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="customer"
                  onChange={(event) =>
                    setCustomerValue(event.currentTarget.value)
                  }
                  value={customerValue}
                  type="text"
                  placeholder="Ex: prénom/nom"
                />
              </div>
            </>
          )}
          {phoneNumberLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {phoneNumberLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="phone-number"
                  onChange={(event) =>
                    setPhoneNumberValue(event.currentTarget.value)
                  }
                  value={phoneNumberValue}
                  type="number"
                  placeholder="Ex: XXXX0642XX"
                />
              </div>
            </>
          )}
          {postCodeLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {postCodeLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="post-code"
                  onChange={(event) =>
                    setPostCodeValue(event.currentTarget.value)
                  }
                  value={postCodeValue}
                  type="number"
                  autoComplete="postal-code"
                  placeholder="Ex: 75XXX"
                />
              </div>
            </>
          )}
          {expertLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {expertLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="expert"
                  onChange={(event) =>
                    setExpertValue(event.currentTarget.value)
                  }
                  value={expertValue}
                  type="text"
                  placeholder="Ex:prénom/nom/company"
                />
              </div>
            </>
          )}
          {storeLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {storeLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="store"
                  onChange={(event) => setStoreValue(event.currentTarget.value)}
                  value={storeValue}
                  type="text"
                  placeholder="Ex: villeurbanne"
                />
              </div>
            </>
          )}
          {retailerLabel && (
            <>
              <p className="flex mt-2 text-sm font-medium text-gray-500">
                {retailerLabel}
              </p>
              <div className="flex">
                <Input
                  className="flex-wrap border-gray-300"
                  id="retailer"
                  onChange={(event) =>
                    setRetailerValue(event.currentTarget.value)
                  }
                  value={retailerValue}
                  type="text"
                  placeholder="Ex: botanic"
                />
              </div>
            </>
          )}
          <div className="mt-8 flex justify-end">
            <Button type="button" onClick={handleSubmit}>
              Appliquer
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropDown;
