import { ApolloQueryResult, gql, useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
import Table from "../../molecules/table";
import {
  AllUsersTabFragment,
  AllUsersTabQuery,
  DeleteUserMutation,
  MutationDeleteUserArgs,
} from "../../../graphql-operations-types";
import DeleteUserAlert from "../../organisms/delete-user-modal";
import ModifyUserModal from "../../organisms/modify-user-modal";
import Toaster from "../../atoms/toaster";

const QUERY = gql`
  fragment AllUsersTab on BackOfficeUser {
    id
    emailAddress
    firstName
    lastName
    securityGroup
  }

  query AllUsersTab {
    users {
      id
      ...AllUsersTab
    }
  }
`;

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($id: String!) {
    deleteUser(id: $id) {
      ... on DeleteUser_Success {
        message
      }
      ... on DeleteUser_Failure {
        reason
      }
    }
  }
`;

const _DeleteUserAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  user: AllUsersTabFragment;
  refetch: () => Promise<ApolloQueryResult<AllUsersTabQuery>>;
}> = ({ user, show = false, onClose, refetch }) => {
  const toaster = Toaster.useToaster();

  const [handleDeleteUserMutation] = useMutation<
    DeleteUserMutation,
    MutationDeleteUserArgs
  >(DELETE_USER_MUTATION);
  const handleDeleteUser = useCallback(async () => {
    const result = await handleDeleteUserMutation({
      variables: {
        id: user.id,
      },
    });

    if (result.data?.deleteUser.__typename === "DeleteUser_Success") {
      onClose();
      toaster.push(({ onClose }) => (
        <Toaster.SuccessToast
          onCloseClick={onClose}
          body="Utilisateur supprimé"
        />
      ));
      refetch();
    } else if (result.data?.deleteUser.__typename === "DeleteUser_Failure") {
      const reason = result.data?.deleteUser?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [handleDeleteUserMutation, user.id, onClose, toaster, refetch]);

  return (
    <DeleteUserAlert
      show={show}
      onClose={onClose}
      onSubmit={handleDeleteUser}
    />
  );
};

const UserRow: React.FC<
  React.ComponentProps<"tr"> & {
    user: AllUsersTabFragment;
    refetch: () => Promise<ApolloQueryResult<AllUsersTabQuery>>;
  }
> = ({ user, refetch, ...props }) => {
  const [openDeleteUserAlert, setOpenDeleteUserAlert] = useState(false);
  const [openModifyUserAlert, setOpenModifyUserAlert] = useState(false);
  return (
    <>
      <_DeleteUserAlert
        show={openDeleteUserAlert}
        onClose={() => {
          setOpenDeleteUserAlert(false);
        }}
        user={user}
        refetch={refetch}
      />
      <ModifyUserModal
        user={user}
        open={openModifyUserAlert}
        onRequestClose={() => setOpenModifyUserAlert(false)}
        onSuccess={() => window.location.reload()}
      />
      <Table.Row {...props} className="scale-100">
        {/* id */}
        <Table.DataCell className="font-normal">
          {user.emailAddress}
        </Table.DataCell>

        {/* nom */}
        <Table.DataCell className="font-normal">{user.lastName}</Table.DataCell>

        {/* prénom */}
        <Table.DataCell className="font-normal">
          {user.firstName}
        </Table.DataCell>

        {/* groupe */}
        <Table.DataCell className="font-normal">
          {user.securityGroup}
        </Table.DataCell>
        <Table.DataCell className="absolute top-3 right-12 !p-0">
          <PencilIcon
            className="h-6 w-6 text-blue-700 cursor-pointer"
            onClick={() => setOpenModifyUserAlert(true)}
          />
        </Table.DataCell>
        <Table.DataCell className="absolute top-3 right-3 !p-0">
          <TrashIcon
            className="h-6 w-6 text-red-500 cursor-pointer"
            onClick={() => setOpenDeleteUserAlert(true)}
          />
        </Table.DataCell>
      </Table.Row>
    </>
  );
};

const _AllUsersTab: React.FC<{
  allUsersQueryResult: AllUsersTabQuery;
  getUserPageLocation: (
    user: AllUsersTabFragment
  ) => React.ComponentProps<typeof Link>["to"];
  refetch: () => Promise<ApolloQueryResult<AllUsersTabQuery>>;
}> = ({ allUsersQueryResult, getUserPageLocation, refetch }) => {
  return (
    <div className="space-y-2">
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Mail</Table.HeadCell>
            <Table.HeadCell>Nom</Table.HeadCell>
            <Table.HeadCell>Prénom</Table.HeadCell>
            <Table.HeadCell>Groupe</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {allUsersQueryResult.users?.map((user, i) => (
            <UserRow
              key={user.id}
              user={user}
              odd={i % 2 === 0}
              to={getUserPageLocation(user)}
              refetch={refetch}
            />
          ))}
        </Table.Body>
      </Table>{" "}
    </div>
  );
};

const AllUsersTab: React.FC<{
  getUserPageLocation: React.ComponentProps<
    typeof _AllUsersTab
  >["getUserPageLocation"];
}> = ({ getUserPageLocation }) => {
  const { data, refetch, error, loading } = useQuery<AllUsersTabQuery>(
    QUERY,
    {}
  );

  if (error) {
    throw error;
  }

  if (!data || loading) {
    return null;
  }

  return (
    <_AllUsersTab
      allUsersQueryResult={data}
      getUserPageLocation={getUserPageLocation}
      refetch={refetch}
    />
  );
};

export default AllUsersTab;
