import React from "react";

export const TabCount: React.FC<{
  count: number;
}> = ({ count }) => {
  return (
    <span className="ml-2 rounded-full bg-gray-200 py-1 px-3">
      {count < 100 ? count : "+99"}
    </span>
  );
};
