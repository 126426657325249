import React, { Fragment, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { gql, useApolloClient } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../atoms/button";
import InputGroup from "../atoms/input-group";
import {
  AssignExpertAlertExpertFragment,
  AssignExpertAlertSearchExpertsQuery,
  AssignExpertAlertSearchExpertsQueryVariables,
} from "../../graphql-operations-types";

const SEARCH_EXPERTS = gql`
  fragment AssignExpertAlertExpert on Expert {
    id
    fullName
  }

  query AssignExpertAlertSearchExperts($limit: Int, $search: String) {
    experts(limit: $limit, search: $search) {
      ...AssignExpertAlertExpert
    }
  }
`;

const AssignExpertAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (expertId: string) => Promise<void>;
}> = ({ show, onClose, onSubmit }) => {
  const apolloClient = useApolloClient();

  const searchExpertsCallback = useCallback(
    async (inputValue: string) => {
      const searchExpertsQueryResult = await apolloClient.query<
        AssignExpertAlertSearchExpertsQuery,
        AssignExpertAlertSearchExpertsQueryVariables
      >({
        query: SEARCH_EXPERTS,
        variables: { limit: 30, search: inputValue },
      });

      return searchExpertsQueryResult.data.experts ?? [];
    },
    [apolloClient]
  );

  type FormInputs = {
    expert: AssignExpertAlertExpertFragment;
  };

  const { handleSubmit, control, formState } = useForm<FormInputs>();
  const _onSubmit = async (data: FormInputs) => {
    await onSubmit(data.expert.id);
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
              <form autoComplete="off" onSubmit={handleSubmit(_onSubmit)}>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Assigner un Expert
                      </Dialog.Title>
                      <div className="mt-4 space-y-3">
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="expertId">
                              <InputGroup.RequiredAsterisk /> Expert
                            </InputGroup.Label>
                          }
                          error={
                            (formState.errors.expert as any)?.message && (
                              <InputGroup.Error>
                                {(formState.errors.expert as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="expert"
                              control={control}
                              render={({ field }) => (
                                <AsyncSelect<AssignExpertAlertExpertFragment>
                                  loadOptions={searchExpertsCallback}
                                  defaultOptions
                                  styles={{
                                    input: (base) => ({
                                      ...base,
                                      "input:focus": {
                                        boxShadow: "none",
                                      },
                                    }),
                                  }}
                                  formatOptionLabel={(expert) => (
                                    <>
                                      {expert.fullName} | {expert.id}
                                    </>
                                  )}
                                  cacheOptions
                                  isDisabled={formState.isSubmitting}
                                  getOptionValue={(expert) => expert.id}
                                  {...field}
                                />
                              )}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end bg-gray-50 px-4 py-3 sm:px-6">
                  <Button
                    type="submit"
                    className="inline-flex w-full justify-center sm:w-auto"
                    disabled={!formState.isValid || formState.isSubmitting}
                    loading={formState.isSubmitting}
                  >
                    Envoyer
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AssignExpertAlert;
