import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AsyncSelect from "react-select/async";
import * as yup from "yup";
import {
  SubmitQuoteRequestModalOfferFragment,
  SubmitQuoteRequestModalQuoteRequestFragment,
  SubmitQuoteRequestModalSearchOffersQuery,
  SubmitQuoteRequestModalSearchOffersQueryVariables,
  SubmitQuoteRequestModalSearchStoresQuery,
  SubmitQuoteRequestModalSearchStoresQueryVariables,
  SubmitQuoteRequestModalStoreFragment,
  SubmitQuoteRequestModalSubmitQuoteRequestMutation,
  SubmitQuoteRequestModalSubmitQuoteRequestMutationVariables,
  QuoteRequestFormOfferQuestionsFragment,
  QuoteRequestFormOfferQuery,
  QuoteRequestFormOfferQueryVariables,
} from "../../graphql-operations-types";
import Button from "../atoms/button";
import InputGroup from "../atoms/input-group";
import TextQuestionInput from "../molecules/questions/text-question-input";
import MultipleChoiceQuestion from "../molecules/questions/multiple-choice-question-input";
import MultipleChoicesQuestion from "../molecules/questions/multiple-choices-question-input";
import NumberQuestion from "../molecules/questions/number-question-input";

const QUOTE_REQUEST_FRAGMENT = gql`
  fragment SubmitQuoteRequestModalQuoteRequest on QuoteRequest {
    __typename
    id
  }
`;

const SEARCH_STORES = gql`
  fragment SubmitQuoteRequestModalStore on Store {
    id
    name
    retailer {
      id
      name
    }
  }

  query SubmitQuoteRequestModalSearchStores($limit: Int, $search: String) {
    stores(limit: $limit, search: $search) {
      ...SubmitQuoteRequestModalStore
    }
  }
`;

const SEARCH_OFFERS = gql`
  fragment SubmitQuoteRequestModalOffer on Offer {
    id
    name
  }

  query SubmitQuoteRequestModalSearchOffers(
    $limit: Int
    $search: String
    $storeId: String!
  ) {
    offers(limit: $limit, search: $search, storeId: $storeId, isComplex: true) {
      ...SubmitQuoteRequestModalOffer
    }
  }
`;

const SUBMIT_QUOTE_REQUEST_MUTATION = gql`
  ${QUOTE_REQUEST_FRAGMENT}

  mutation SubmitQuoteRequestModalSubmitQuoteRequest(
    $offerId: UUID!
    $storeId: UUID!
    $storeClerkId: UUID
    $contactEmailAddress: EmailAddress!
    $contactPhoneNumber: PhoneNumber!
    $contactFirstName: String!
    $contactLastName: String!
    $details: String!
    $address: SubmitQuoteRequest_Address!
    $date: DateTime
    $platformFeesRate: Float
    $storeClerkCommissionRate: Float
    $whiteLabelFeesRate: Float
    $questionsAnswers: [QuestionsAnswersInput!]
    $quoteMissionIdToCancel: String
  ) {
    submitQuoteRequest(
      offerId: $offerId
      storeId: $storeId
      contactEmailAddress: $contactEmailAddress
      contactPhoneNumber: $contactPhoneNumber
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      address: $address
      details: $details
      storeClerkId: $storeClerkId
      date: $date
      platformFeesRate: $platformFeesRate
      storeClerkCommissionRate: $storeClerkCommissionRate
      whiteLabelFeesRate: $whiteLabelFeesRate
      questionsAnswers: $questionsAnswers
      quoteMissionIdToCancel: $quoteMissionIdToCancel
    ) {
      ... on SubmitQuoteRequest_Failure {
        reason
      }
      ... on SubmitQuoteRequest_Success {
        quoteRequest {
          __typename
          ...SubmitQuoteRequestModalQuoteRequest
        }
      }
    }
  }
`;

export const NUMBER_QUESTION_FRAGMENT = gql`
  fragment NumberQuestion on NumberQuestion {
    isMandatory
    reference
    unit
    step
    min
    max
    defaultValue
    label
  }
`;

export const MULTIPLE_CHOICE_FRAGMENT = gql`
  fragment MultipleChoiceQuestion on MultipleChoiceQuestion {
    isMandatory
    reference
    label
    options {
      value
      label
    }
  }
`;

export const MULTIPLE_CHOICES_FRAGMENT = gql`
  fragment MultipleChoicesQuestion on MultipleChoicesQuestion {
    isMandatory
    reference
    label
    options {
      value
      label
    }
  }
`;

export const TEXT_QUESTION_FRAGMENT = gql`
  fragment TextQuestion on TextQuestion {
    isMandatory
    reference
    label
  }
`;

const QUOTE_REQUEST_FORM_OFFER_QUESTIONS_FRAGMENT = gql`
  ${NUMBER_QUESTION_FRAGMENT}
  ${MULTIPLE_CHOICE_FRAGMENT}
  ${MULTIPLE_CHOICES_FRAGMENT}
  ${TEXT_QUESTION_FRAGMENT}
  fragment QuoteRequestFormOfferQuestions on Question {
    ... on TextQuestion {
      ...TextQuestion
    }
    ... on NumberQuestion {
      ...NumberQuestion
    }
    ... on MultipleChoiceQuestion {
      ...MultipleChoiceQuestion
    }
    ... on MultipleChoicesQuestion {
      ...MultipleChoicesQuestion
    }
  }
`;

const QUOTE_REQUEST_FORM_OFFER_QUERY = gql`
  ${QUOTE_REQUEST_FORM_OFFER_QUESTIONS_FRAGMENT}
  query QuoteRequestFormOffer($offerId: String!, $storeId: String!) {
    offer(offerId: $offerId, storeId: $storeId) {
      id
      isDateMandatory
      questions {
        ...QuoteRequestFormOfferQuestions
      }
    }
  }
`;

type FormInputs = {
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmailAddress: string;
  zipCode: string;
  city: string;
  street1: string;
  details: string;
  offer: SubmitQuoteRequestModalOfferFragment;
  store: SubmitQuoteRequestModalStoreFragment;
  quoteMissionIdToCancel: string;
};

const schema = yup.object().shape({
  contactFirstName: yup.string().required("Le prénom du client est requis"),
  contactLastName: yup.string().required("Le nom du client est requis"),
  contactPhoneNumber: yup
    .string()
    .required("Le numéro de téléphone du client est requis"),
  contactEmailAddress: yup
    .string()
    .email("L'adresse email du client n'est pas valide")
    .required("L'adresse email du client est requise"),
  zipCode: yup.string().required("Le code postal est requis"),
  city: yup.string(),
  street1: yup.string(),
  details: yup.string().required("Les details sont requis"),
  offer: yup.object().required("L'offre est requise"),
  store: yup.object().required("Le magasin est requis"),
});
type QuestionProps = {
  question: QuoteRequestFormOfferQuestionsFragment;
  onChange: (reference: string, value: string, typename?: string) => void;
  errors: Map<string, string>;
};
const Question = ({ question, onChange, errors }: QuestionProps) => {
  switch (question.__typename) {
    case "MultipleChoiceQuestion":
      return (
        <MultipleChoiceQuestion
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    case "MultipleChoicesQuestion":
      return (
        <MultipleChoicesQuestion
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    case "TextQuestion":
      return (
        <TextQuestionInput
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    case "NumberQuestion":
      return (
        <NumberQuestion
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    default:
      return null;
  }
};

const SubmitQuoteRequestModal: React.FC<{
  open?: boolean;
  onRequestClose?: () => void;
  onSuccess?: (
    quoteRequest: SubmitQuoteRequestModalQuoteRequestFragment
  ) => void;
}> = ({ open = false, onRequestClose = noop, onSuccess }) => {
  const [triggerSubmitQuoteRequestMutation] = useMutation<
    SubmitQuoteRequestModalSubmitQuoteRequestMutation,
    SubmitQuoteRequestModalSubmitQuoteRequestMutationVariables
  >(SUBMIT_QUOTE_REQUEST_MUTATION);

  const apolloClient = useApolloClient();

  const {
    register,
    handleSubmit,
    control,
    setError,
    trigger,
    getValues,
    setFocus,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [storeId, setStoreId] = useState<string>("");
  const [date, setDate] = useState<string | undefined>();
  const [offer, setOffer] = useState<
    QuoteRequestFormOfferQuery["offer"] | null
  >(null);
  const [offerId, setOfferId] = useState<string>("");
  const [offerQuestions, setOfferQuestions] = useState<
    readonly QuoteRequestFormOfferQuestionsFragment[]
  >([]);
  const [formHasBeenAlreadyChecked, setFormHasBeenAlreadyChecked] =
    useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadStoreSelectOptions = useCallback(
    async (inputValue: string) => {
      const pointsOfSaleQueryResult = await apolloClient.query<
        SubmitQuoteRequestModalSearchStoresQuery,
        SubmitQuoteRequestModalSearchStoresQueryVariables
      >({
        query: SEARCH_STORES,
        variables: { limit: 10, search: inputValue },
      });

      return pointsOfSaleQueryResult.data.stores ?? [];
    },
    [apolloClient]
  );

  const loadOfferOptions = useCallback(
    async (inputValue: string) => {
      const offersQueryResult = await apolloClient.query<
        SubmitQuoteRequestModalSearchOffersQuery,
        SubmitQuoteRequestModalSearchOffersQueryVariables
      >({
        query: SEARCH_OFFERS,
        variables: { limit: 10, search: inputValue, storeId },
      });

      return offersQueryResult.data.offers ?? [];
    },
    [apolloClient, storeId]
  );

  const loadOfferQuestions = useCallback(async () => {
    const offerQuestionsQueryResult = await apolloClient.query<
      QuoteRequestFormOfferQuery,
      QuoteRequestFormOfferQueryVariables
    >({
      query: QUOTE_REQUEST_FORM_OFFER_QUERY,
      variables: { storeId, offerId },
    });
    return offerQuestionsQueryResult.data.offer;
  }, [apolloClient, offerId, storeId]);

  const questionsAnswers = useMemo(() => {
    return new Map<string, string[]>();
  }, []);
  const questionsAnswersErrors = useMemo(() => {
    return new Map<string, string>();
  }, []);
  const setAnswersErrors = useCallback((): void => {
    for (const question of offerQuestions) {
      if (question.isMandatory) {
        const answer = questionsAnswers.get(question.reference);
        if (!answer || answer?.length === 0) {
          questionsAnswersErrors.set(question.reference, "Champ requis");
        } else {
          questionsAnswersErrors.delete(question.reference);
        }
      }
    }

    if (formHasBeenAlreadyChecked) {
      setForceRefresh((state) => !state);
    }
  }, [
    formHasBeenAlreadyChecked,
    offerQuestions,
    questionsAnswers,
    questionsAnswersErrors,
  ]);

  const onQuestionAnswerChange = useCallback(
    (reference: string, value: string, typeName?: string) => {
      if (typeName && typeName === "MultipleChoicesQuestion") {
        const choicesSelected = questionsAnswers.get(reference);
        if (choicesSelected) {
          const index = choicesSelected.indexOf(value, 0);
          if (index > -1) {
            choicesSelected.splice(index, 1);
          } else {
            choicesSelected.push(value);
          }
        } else {
          questionsAnswers.set(reference, [value]);
        }
      } else if (typeName && typeName === "TextQuestion" && value === "") {
        questionsAnswers.delete(reference);
      } else {
        questionsAnswers.set(reference, [value]);
      }

      setAnswersErrors();
    },
    [questionsAnswers, setAnswersErrors]
  );

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setAnswersErrors();
    trigger();
    setFormHasBeenAlreadyChecked(true);
    if (questionsAnswersErrors.size === 0 && isValid) {
      handleSubmit(onSubmit(getValues()));
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    async ({
      contactFirstName,
      contactLastName,
      contactPhoneNumber,
      contactEmailAddress,
      zipCode,
      city,
      street1,
      details,
      offer,
      store,
      quoteMissionIdToCancel,
    }) => {
      setIsSubmitting(true);
      const arrayOfQuestionsAnswers = Array.from(
        questionsAnswers,
        ([reference, value]) => ({ reference, value })
      );
      const result = await triggerSubmitQuoteRequestMutation({
        variables: {
          contactFirstName,
          contactLastName,
          contactPhoneNumber,
          contactEmailAddress,
          address: {
            country: "France",
            zipCode,
            city,
            street1,
          },
          details,
          offerId: offer.id,
          storeId: store.id,
          questionsAnswers:
            arrayOfQuestionsAnswers.length > 0 ? arrayOfQuestionsAnswers : null,
          date: date ? new Date(date).toISOString() : null,
          quoteMissionIdToCancel,
        },
      });

      if (
        result.data?.submitQuoteRequest.__typename ===
        "SubmitQuoteRequest_Failure"
      ) {
        switch (result.data.submitQuoteRequest.reason) {
          case "INVALID_ADDRESS":
            setError("zipCode", { message: "Addresse invalide" });
            setError("street1", { message: "Addresse invalide" });
            setError("city", { message: "Addresse invalide" });
            break;
        }
        setIsSubmitting(false);
      }

      if (
        onSuccess &&
        result.data?.submitQuoteRequest.__typename ===
          "SubmitQuoteRequest_Success"
      ) {
        onSuccess(result.data?.submitQuoteRequest.quoteRequest);
        setIsSubmitting(false);
      }
    },
    [
      date,
      onSuccess,
      questionsAnswers,
      setError,
      triggerSubmitQuoteRequestMutation,
    ]
  );

  useEffect(() => {
    const fetchQuestions = async () => {
      const offer = await loadOfferQuestions();

      if (offer) {
        setOffer(offer);
        setOfferQuestions(offer.questions);
      }
    };
    if (offerId) fetchQuestions();
  }, [loadOfferQuestions, offerId]);

  useEffect(() => {
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    if (formHasBeenAlreadyChecked) {
      if (offer?.isDateMandatory === "MANDATORY" && !date) {
        questionsAnswersErrors.set("date", "Date requise");
      } else questionsAnswersErrors.delete("date");
      setForceRefresh((state) => !state);
    }
  }, [
    date,
    formHasBeenAlreadyChecked,
    offer?.isDateMandatory,
    questionsAnswersErrors,
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onRequestClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
              <form autoComplete="on" onSubmit={validateAndSubmit}>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Soumettre une demande de devis
                      </Dialog.Title>
                      <div className="mt-4 space-y-3">
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactFirstName">
                              <InputGroup.RequiredAsterisk /> Prénom du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactFirstName?.message && (
                              <InputGroup.Error>
                                {errors.contactFirstName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactFirstName"
                              readOnly={isSubmitting}
                              {...register("contactFirstName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactLastName">
                              <InputGroup.RequiredAsterisk /> Nom du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactLastName?.message && (
                              <InputGroup.Error>
                                {errors.contactLastName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactLastName"
                              readOnly={isSubmitting}
                              {...register("contactLastName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactPhoneNumber">
                              <InputGroup.RequiredAsterisk /> Numéro de
                              téléphone du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactPhoneNumber?.message && (
                              <InputGroup.Error>
                                {errors.contactPhoneNumber.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="contactPhoneNumber"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput
                                  defaultCountry="FR"
                                  inputComponent={InputGroup.Input}
                                  readOnly={isSubmitting}
                                  {...field}
                                />
                              )}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactEmailAddress">
                              <InputGroup.RequiredAsterisk /> Adresse mail du
                              client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactEmailAddress?.message && (
                              <InputGroup.Error>
                                {errors.contactEmailAddress.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              type="email"
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactEmailAddress"
                              readOnly={isSubmitting}
                              {...register("contactEmailAddress")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="street1">
                              Adresse d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.street1?.message && (
                              <InputGroup.Error>
                                {errors.street1.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="street1"
                              readOnly={isSubmitting}
                              {...register("street1")}
                            />
                          }
                        />
                        <InputGroup
                          className="block w-full"
                          label={
                            <InputGroup.Label htmlFor="zipCode">
                              <InputGroup.RequiredAsterisk /> Code postal
                              d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.zipCode?.message && (
                              <InputGroup.Error>
                                {errors.zipCode.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="zipCode"
                              readOnly={isSubmitting}
                              {...register("zipCode")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="city">
                              Ville d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.city?.message && (
                              <InputGroup.Error>
                                {errors.city.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="city"
                              readOnly={isSubmitting}
                              {...register("city")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="store">
                              <InputGroup.RequiredAsterisk /> Magasin
                            </InputGroup.Label>
                          }
                          error={
                            (errors.store as any)?.message && (
                              <InputGroup.Error>
                                {(errors.store as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="store"
                              control={control}
                              render={({ field }) => (
                                <AsyncSelect<SubmitQuoteRequestModalStoreFragment>
                                  loadOptions={loadStoreSelectOptions}
                                  defaultOptions
                                  styles={{
                                    input: (base) => ({
                                      ...base,
                                      "input:focus": {
                                        boxShadow: "none",
                                      },
                                    }),
                                  }}
                                  formatOptionLabel={(store) => (
                                    <>
                                      {store.retailer.name} | {store.name}
                                    </>
                                  )}
                                  cacheOptions
                                  isDisabled={isSubmitting}
                                  getOptionValue={(store) => store.id}
                                  {...field}
                                  onChange={(value) => {
                                    if (value) setStoreId(value.id);
                                    field.onChange(value);
                                  }}
                                />
                              )}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="offerId">
                              <InputGroup.RequiredAsterisk /> Offre
                            </InputGroup.Label>
                          }
                          error={
                            (errors.offer as any)?.message && (
                              <InputGroup.Error>
                                {(errors.offer as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="offer"
                              control={control}
                              /**
                               * this is the part you will have to delete if you
                               * reactivate the offers by store feature
                               */
                              render={({ field }) => (
                                <AsyncSelect<SubmitQuoteRequestModalOfferFragment>
                                  loadOptions={loadOfferOptions}
                                  styles={{
                                    input: (base) => ({
                                      ...base,
                                      "input:focus": {
                                        boxShadow: "none",
                                      },
                                    }),
                                  }}
                                  defaultOptions
                                  formatOptionLabel={(offer) => offer.name}
                                  cacheOptions
                                  getOptionValue={(offer) => offer.id}
                                  isDisabled={isSubmitting}
                                  {...field}
                                  onChange={(value) => {
                                    if (value) setOfferId(value.id);
                                    field.onChange(value);
                                  }}
                                />
                              )}
                              /**
                               * this commented part is about filter the offers by store
                               * if you want to reactivate this feature please uncomment this part and the
                               * backend code in the query server/src/application/graphql/back-office-api/types/queries/offers.ts
                               */
                              // render={({ field }) =>
                              //   storeId ? (
                              //     <AsyncSelect<SubmitQuoteRequestModalOfferFragment>
                              //       loadOptions={loadOfferOptions}
                              //       styles={{
                              //         input: (base) => ({
                              //           ...base,
                              //           "input:focus": {
                              //             boxShadow: "none",
                              //           },
                              //         }),
                              //       }}
                              //       defaultOptions
                              //       formatOptionLabel={(offer) => offer.name}
                              //       cacheOptions
                              //       getOptionValue={(offer) => offer.id}
                              //       isDisabled={isSubmitting}
                              //       {...field}
                              //     />
                              //   ) : (
                              //     <Select<SubmitQuoteRequestModalOfferFragment>
                              //       isDisabled={!storeId}
                              //     />
                              //   )
                              // }
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="quoteMissionIdToCancel">
                              Identifiant de la mission devis du SaaS à écraser
                            </InputGroup.Label>
                          }
                          error={
                            errors.city?.message && (
                              <InputGroup.Error>
                                {errors.city.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="quoteMissionIdToCancel"
                              readOnly={isSubmitting}
                              {...register("quoteMissionIdToCancel")}
                            />
                          }
                        />
                        <Transition.Child as="div" className="mt-3">
                          {offer?.isDateMandatory === "MANDATORY" && (
                            <span className="text-red-600">&#42; </span>
                          )}
                          <span className="text-sm">
                            Date d&apos;intervention
                          </span>
                        </Transition.Child>

                        <Transition.Child
                          className={`w-full border-gray-300 !mt-1 ${
                            questionsAnswersErrors.get("date") &&
                            "border border-red-600 rounded"
                          }`}
                          as="input"
                          id="date"
                          name="date"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setDate(event.target.value)}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                        />
                        {questionsAnswersErrors.get("date") && (
                          <div className="mt-2 text-sm text-red-600 flex">
                            {questionsAnswersErrors.get("date")}
                          </div>
                        )}

                        <Transition
                          as="div"
                          show={offerQuestions.length > 0}
                          enter="transition-opacity duration-75"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          {offerQuestions.map((question) => (
                            <Transition.Child
                              as="div"
                              key={question.reference}
                              className="mt-3"
                            >
                              {question.isMandatory && (
                                <Transition.Child
                                  as="span"
                                  className="text-red-600"
                                >
                                  &#42;{" "}
                                </Transition.Child>
                              )}
                              <Transition.Child
                                as="label"
                                htmlFor={question.reference}
                                className="text-sm mb-2"
                              >
                                {question.label}
                              </Transition.Child>
                              <Question
                                question={question}
                                onChange={onQuestionAnswerChange}
                                errors={questionsAnswersErrors}
                              />
                            </Transition.Child>
                          ))}
                        </Transition>

                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="details">
                              <InputGroup.RequiredAsterisk /> Détails sur la
                              prestation
                            </InputGroup.Label>
                          }
                          error={
                            errors.details?.message && (
                              <InputGroup.Error>
                                {errors.details.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.TextArea
                              autoComplete="off"
                              autoCorrect="off"
                              id="details"
                              readOnly={isSubmitting}
                              {...register("details")}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end bg-gray-50 px-4 py-3 sm:px-6">
                  <Button
                    type="submit"
                    className="inline-flex w-full justify-center sm:w-auto"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Envoyer
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubmitQuoteRequestModal;
