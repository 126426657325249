import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const Button: React.FC<React.ComponentProps<"button">> = Menu.Button;

const ItemGroup: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => <div className={classNames("px-1 py-1", className)} {...props} />;

const ButtonItem: React.FC<
  React.ComponentProps<"button"> & {
    onClick?: React.ComponentProps<typeof Menu.Item>["onClick"];
    children?:
      | ((props: { active: boolean; disabled: boolean }) => React.ReactNode)
      | React.ReactNode;
  }
> = ({ className, children, onClick, ...props }) => (
  <Menu.Item onClick={onClick}>
    {({ active, disabled }) => (
      <button
        className={classNames(
          {
            "bg-blue-500 text-white": active,
            "text-gray-900": !active,
          },
          "group flex w-full items-center rounded-md px-2 py-2 text-left text-sm",
          className
        )}
        {...props}
      >
        {typeof children === "function"
          ? children({ active, disabled })
          : children}
      </button>
    )}
  </Menu.Item>
);

const LinkItem: React.FC<
  React.ComponentProps<typeof Link> & {
    children?: (props: {
      active: boolean;
      disabled: boolean;
    }) => React.ReactElement;
  }
> = ({ className, children, ...props }) => (
  <Menu.Item>
    {({ active, disabled }) => (
      <Link
        className={classNames(
          {
            "bg-blue-500 text-white": active,
            "text-gray-900": !active,
          },
          "group flex w-full items-center rounded-md px-2 py-2 text-sm",
          className
        )}
        {...props}
      >
        {children && children({ active, disabled })}
      </Link>
    )}
  </Menu.Item>
);

const DropDownMenu: React.FC<
  React.ComponentProps<"div"> & { button: React.ReactElement }
> = ({ className, button, children, ...props }) => {
  return (
    <Menu
      as="div"
      className={classNames("relative inline-block text-left", className)}
      {...props}
    >
      <div>{button}</div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {children}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Object.assign(DropDownMenu, {
  Button,
  ItemGroup,
  ButtonItem,
  LinkItem,
});
