import React from "react";
import classNames from "classnames";

const Label: React.FC<React.ComponentProps<"label">> = ({
  className,
  ...props
}) => (
  <label
    className={classNames("flex items-center space-x-2 text-sm", className)}
    {...props}
  />
);

const _Radio = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input">
>(({ className, ...props }, ref) => (
  <input
    type="radio"
    ref={ref}
    className={classNames(
      className,
      "border-gray-300 form-radio h-4 w-4 text-primary focus:ring-primary ml-3"
    )}
    {...props}
  />
));

const Radio = Object.assign(_Radio, { Label });

export default Radio;
