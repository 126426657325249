import React, { useCallback, useState } from "react";
import { NumberQuestionFragment } from "../../../graphql-operations-types";
import { Minus, Plus } from "../../atoms/icons";
import Input from "../../atoms/input";

type _NumberQuestionProps = {
  question: NumberQuestionFragment;
  onChange: (value: number) => void;
  error?: string | undefined;
  answerValue?: string[];
};
const _NumberQuestion = ({
  question,
  onChange,
  error,
  answerValue,
}: _NumberQuestionProps) => {
  const [value, setValue] = useState(
    answerValue ? Number(answerValue[0]) : question.defaultValue ?? 0
  );
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.value) {
        return;
      }
      const value = parseInt(event.currentTarget.value, 10);
      if (value < question.min || value > question.max) {
        return;
      }
      const newValue = parseInt(event.currentTarget.value, 10);
      onChange(newValue);
      setValue(newValue);
    },
    [onChange, question.max, question.min]
  );

  const handlePlusClick = useCallback(() => {
    let newValue;
    if (value + question.step > question.max) {
      newValue = question.max;
      onChange(newValue);
      setValue(newValue);
    } else {
      newValue = value + question.step;
      onChange(newValue);
      setValue(newValue);
    }
  }, [value, onChange, question.max, question.step]);
  const handleMinusClick = useCallback(() => {
    let newValue;
    if (value - question.step < question.min) {
      newValue = question.min;
      onChange(newValue);
      setValue(newValue);
    } else {
      newValue = value - question.step;
      onChange(newValue);
      setValue(newValue);
    }
  }, [value, onChange, question.min, question.step]);

  return (
    <>
      <div
        className={`flex mt-2 ${
          error && "border-2 border-red-600 rounded-md py-2"
        }`}
      >
        <button
          disabled={value <= question.min}
          type="button"
          onClick={handleMinusClick}
          className="w-8 h-8 flex items-center justify-center"
        >
          <Minus color="#0069ff" />
        </button>
        <Input
          id={question.reference}
          type="number"
          onChange={handleInputChange}
          value={value.toString()}
          className="w-20 text-sm py-1 px-1 text-center removeNumberArrow"
          min={question.min}
          max={question.max}
        />
        <button
          disabled={value >= question.max}
          type="button"
          onClick={handlePlusClick}
          className="w-8 h-8 flex items-center justify-center"
        >
          <Plus color="#0069ff" />
        </button>
      </div>
      {error && <div className="mt-2 text-sm text-red-600 flex">{error}</div>}
    </>
  );
};

type NumberQuestionProps = {
  question: NumberQuestionFragment;
  onChange: (reference: string, value: string, typename?: string) => void;
  error?: string | undefined;
  value?: Map<string, string[]>;
};
const NumberQuestion = ({
  question,
  onChange,
  error,
  value,
}: NumberQuestionProps) => {
  const handleQuestionAnswerChange = useCallback(
    (value: number) => {
      onChange(question.reference, value.toString(), question.__typename);
    },
    [onChange, question.reference, question.__typename]
  );

  return (
    <_NumberQuestion
      onChange={handleQuestionAnswerChange}
      question={question}
      error={error}
      answerValue={value?.get(question.reference)}
    />
  );
};

export default NumberQuestion;
