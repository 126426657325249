import { gql, useQuery } from "@apollo/client";
import React, { createContext, useContext } from "react";
import LoginPage from "./components/pages/login/login";
import {
  LoginManagerBackOfficeUserFragment,
  LoginManagerQuery,
  LoginManagerQueryVariables,
} from "./graphql-operations-types";

const LOGIN_MANAGER_QUERY = gql`
  fragment LoginManagerBackOfficeUser on BackOfficeUser {
    id
    isAdmin
    isEditor
    isViewer
  }

  query LoginManager {
    me {
      id
      ...LoginManagerBackOfficeUser
    }
  }
`;

const BackOfficeUserContext =
  createContext<LoginManagerBackOfficeUserFragment | null>(null);

export const LoginProvider: React.FC = ({ children }) => {
  const queryResult = useQuery<LoginManagerQuery, LoginManagerQueryVariables>(
    LOGIN_MANAGER_QUERY
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (queryResult.loading) {
    return null;
  }

  if (!queryResult.data?.me) {
    return (
      <LoginPage
        onLoginSuccess={() => {
          queryResult.refetch();
        }}
      />
    );
  }

  return (
    <BackOfficeUserContext.Provider value={queryResult.data.me}>
      {children}
    </BackOfficeUserContext.Provider>
  );
};

function GetBackOfficeUser(): LoginManagerBackOfficeUserFragment | null {
  const backOfficeUser = useContext(BackOfficeUserContext);

  return backOfficeUser;
}

export default GetBackOfficeUser;
