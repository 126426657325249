import { gql, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import { nanoid } from "nanoid";
import React, { Fragment, useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import * as yup from "yup";
import {
  AddUserModalFragment,
  AddUserModalMutation,
  AddUserModalMutationVariables,
  User_SecurityGroup,
} from "../../graphql-operations-types";
import Button from "../atoms/button";
import InputGroup from "../atoms/input-group";
import Select from "../atoms/select";

const USER_FRAGMENT = gql`
  fragment AddUserModal on BackOfficeUser {
    __typename
    id
    fullName
  }
`;

const ADD_USER_MUTATION = gql`
  ${USER_FRAGMENT}

  mutation AddUserModal(
    $contactFirstName: String!
    $contactLastName: String!
    $contactEmailAddress: EmailAddress!
    $contactPassword: String!
    $contactSecurityGroup: User_SecurityGroup!
  ) {
    addUser(
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEmailAddress: $contactEmailAddress
      contactPassword: $contactPassword
      contactSecurityGroup: $contactSecurityGroup
    ) {
      ... on AddUser_Failure {
        reason
      }
      ... on AddUser_Success {
        user {
          __typename
          ...AddUserModal
        }
      }
    }
  }
`;

type FormInputs = {
  contactFirstName: string;
  contactLastName: string;
  contactEmailAddress: string;
  contactPassword: string;
  contactSecurityGroup: User_SecurityGroup;
};

type Choice = {
  value: User_SecurityGroup;
  label: string;
};

const choices: Choice[] = [
  {
    value: "ADMIN",
    label: "Admin",
  },
  {
    value: "EDITOR",
    label: "Editor",
  },
  {
    value: "VIEWER",
    label: "Viewer",
  },
];

const schema = yup.object().shape({
  contactFirstName: yup.string().required("Le prénom est requis"),
  contactLastName: yup.string().required("Le nom est requis"),
  contactEmailAddress: yup
    .string()
    .email("L'adresse email n'est pas valide")
    .required("L'adresse email est requise"),
  contactPassword: yup.string().required("Le mot de passe est requis"),
  contactSecurityGroup: yup
    .string()
    .oneOf(["ADMIN", "EDITOR", "VIEWER"], "veuillez selectionner un rôle")
    .required(),
});

const AddUserModal: React.FC<{
  open?: boolean;
  onRequestClose?: () => void;
  onSuccess?: (mission: AddUserModalFragment) => void;
}> = ({ open = false, onRequestClose = noop, onSuccess }) => {
  const [triggerAddUserMutation] = useMutation<
    AddUserModalMutation,
    AddUserModalMutationVariables
  >(ADD_USER_MUTATION);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const [randomPassword, generateRandomPassword] = useState<string>("");

  const handleRandomPasswordOnChange = async (event: {
    target: { value: string };
  }) => {
    const { value } = event.target;
    generateRandomPassword(value);
  };

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    async ({
      contactFirstName,
      contactLastName,
      contactEmailAddress,
      contactPassword,
      contactSecurityGroup,
    }) => {
      const result = await triggerAddUserMutation({
        variables: {
          contactFirstName,
          contactLastName,
          contactEmailAddress,
          contactPassword,
          contactSecurityGroup,
        },
      });

      if (result.data?.addUser.__typename === "AddUser_Failure") {
        switch (result.data.addUser.reason) {
          case "INVALID_EMAIL_ADDRESS":
            setError("contactEmailAddress", {
              message: "Addresse mail invalide",
            });
            break;
          case "EMAIL_ADDRESS_ALREADY_EXIST":
            setError("contactEmailAddress", {
              message: "Addresse mail déjà utilisée",
            });
            break;
          case "INVALID_PASSWORD":
            setError("contactPassword", {
              message:
                "Mot de passe invalide (Au moins 12 caractères, majuscule, minuscule et chiffres",
            });
            break;
        }
      }

      if (onSuccess && result.data?.addUser.__typename === "AddUser_Success") {
        onSuccess(result.data?.addUser.user);
      }
    },
    [onSuccess, setError, triggerAddUserMutation]
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onRequestClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Ajouter un utilisateur
                      </Dialog.Title>
                      <div className="mt-4 space-y-3">
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactFirstName">
                              <InputGroup.RequiredAsterisk /> Prénom
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactFirstName?.message && (
                              <InputGroup.Error>
                                {errors.contactFirstName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactFirstName"
                              readOnly={isSubmitting}
                              {...register("contactFirstName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactLastName">
                              <InputGroup.RequiredAsterisk /> Nom
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactLastName?.message && (
                              <InputGroup.Error>
                                {errors.contactLastName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactLastName"
                              readOnly={isSubmitting}
                              {...register("contactLastName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactEmailAddress">
                              <InputGroup.RequiredAsterisk /> Adresse mail
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactEmailAddress?.message && (
                              <InputGroup.Error>
                                {errors.contactEmailAddress.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactEmailAddress"
                              readOnly={isSubmitting}
                              {...register("contactEmailAddress")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactPassword">
                              <InputGroup.RequiredAsterisk /> Mot de passe
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactPassword?.message && (
                              <InputGroup.Error>
                                {errors.contactPassword.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              type="text"
                              autoComplete="off"
                              autoCorrect="off"
                              value={randomPassword}
                              id="contactPassword"
                              readOnly={isSubmitting}
                              onChange={(value) => {
                                setValue(
                                  "contactPassword",
                                  value.currentTarget.value
                                );
                                handleRandomPasswordOnChange(value);
                              }}
                            />
                          }
                        />
                        <Button
                          className="rounded-lg border-2 border-blue-600 p-2 text-white"
                          type="button"
                          onClick={() => {
                            const newPassword = nanoid(12);
                            setValue("contactPassword", newPassword);
                            generateRandomPassword(newPassword);
                          }}
                        >
                          Générer un mot de passe
                        </Button>
                      </div>
                    </div>
                  </div>
                  <InputGroup.Label
                    className="mt-4"
                    htmlFor="contactSecurityGroup"
                  >
                    <InputGroup.RequiredAsterisk /> Groupe de sécurité
                  </InputGroup.Label>
                  <Select
                    {...register("contactSecurityGroup")}
                    className="w-full"
                    disabled={isSubmitting}
                  >
                    <option selected disabled>
                      Sélectionnez un rôle...
                    </option>
                    {choices.map((choice) => (
                      <option key={choice.value} value={choice.value}>
                        {choice.label}
                      </option>
                    ))}
                  </Select>
                  {errors.contactSecurityGroup?.message && (
                    <InputGroup.Error>
                      {errors.contactSecurityGroup.message}
                    </InputGroup.Error>
                  )}
                </div>
                <div className="flex justify-end bg-gray-50 px-4 py-3 sm:px-6">
                  <Button
                    type="submit"
                    className="inline-flex w-full justify-center sm:w-auto"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Valider
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddUserModal;
