import { ExclamationIcon } from "@heroicons/react/outline";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Button from "../atoms/button";
import Select from "../atoms/select";
import Alert from "../molecules/alert";

export interface IFormInputs {
  choice: number;
}

export type Choice = {
  value: number;
  label: string;
};

const choices: Choice[] = [
  {
    value: 8,
    label: "8h",
  },
  {
    value: 16,
    label: "16h",
  },
  {
    value: 24,
    label: "24h",
  },
];

const RelaunchTimerAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: (data: IFormInputs) => Promise<void>;
}> = ({ show, onClose, onSubmit }) => {
  const schema = useMemo(() => {
    return yup
      .object({
        choice: yup
          .number()
          .oneOf(
            [8, 16, 24],
            "veuillez selectionner le nombre d'heures à ajouter"
          )
          .required(),
      })
      .required();
  }, []);

  const { register, handleSubmit, formState } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const _onSubmit = async (data: IFormInputs) => {
    await onSubmit(data);
  };

  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-8 w-8 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={
        <Alert.Title>
          Voulez-vous vraiment ajouter du temps à la candidature?
        </Alert.Title>
      }
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="submit"
            form="increase-timer-alert"
            disabled={formState.isSubmitting || !formState.isValid}
            loading={formState.isSubmitting}
          >
            Ajouter du temps
          </Button>
          <Button
            type="button"
            onClick={onClose}
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            Annuler
          </Button>
        </Alert.Footer>
      }
    >
      <Alert.Body>
        <form id="increase-timer-alert" onSubmit={handleSubmit(_onSubmit)}>
          Veuillez selectionner le nombre d'heures à ajouter
          <Select
            {...register("choice")}
            className="w-full"
            disabled={formState.isSubmitting}
          >
            <option selected disabled>
              Choisissez...
            </option>
            {choices.map((choice) => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </Select>
          {formState.errors.choice && (
            <span className="text-red-400">
              {formState.errors.choice.message}
            </span>
          )}
        </form>
      </Alert.Body>
    </Alert>
  );
};

export default RelaunchTimerAlert;
