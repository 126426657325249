import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";

const Icon: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      "mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10",
      className
    )}
    {...props}
  />
);

const Title: React.FC<React.ComponentProps<typeof Dialog.Title>> = ({
  className,
  ...props
}) => (
  <Dialog.Title
    as="h3"
    className={classNames(
      "text-lg font-medium leading-6 text-gray-900",
      className
    )}
    {...props}
  />
);

const Body: React.FC<React.ComponentProps<"p">> = ({ className, ...props }) => (
  <p className={classNames("text-sm text-gray-500", className)} {...props} />
);

const Footer: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse",
      className
    )}
    {...props}
  />
);

const _Alert: React.FC<{
  show: boolean;
  onClose: () => void;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
}> = ({ show, onClose, icon, title, children, footer }) => (
  <Transition.Root show={show} as={Fragment}>
    <Dialog
      as="div"
      className="fixed inset-0 z-10 overflow-y-auto"
      onClose={onClose}
    >
      <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
            <div className="sm:flex sm:items-start">
              {icon}
              <div className="mt-3 space-y-2 text-center sm:mt-0 sm:ml-4 sm:text-left">
                {title}
                <div>{children}</div>
              </div>
            </div>
            {footer}
          </div>
        </Transition.Child>
      </div>
    </Dialog>
  </Transition.Root>
);

const Alert = Object.assign(_Alert, { Icon, Title, Body, Footer });

export default Alert;
