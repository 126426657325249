/* eslint-disable react/jsx-no-useless-fragment */
import { useMutation, useQuery } from "@apollo/client";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { gql } from "graphql-tag";
import { sortBy } from "lodash";
import React, {
  SetStateAction,
  useCallback,
  useState,
  Dispatch,
  useMemo,
} from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedRelativeTime,
  useIntl,
} from "react-intl";
import { differenceInSeconds } from "date-fns";
import {
  AssignExpertMutation,
  AssignExpertMutationVariables,
  CanceledMission_CancelationReason,
  CancelMissionMutation,
  CompleteMissionMutation,
  JobOfferDeclineReason,
  JobOfferRejectionReason,
  MissionPageQuery,
  MissionPageQueryVariables,
  MissionPageCouponFragment,
  MissionPageJobOfferFragment,
  MissionPageMissionFragment,
  MissionPageSubmitMissionCommentMutation,
  MissionPageSubmitMissionCommentMutationVariables,
  MutationCancelMissionArgs,
  MutationMarkAcceptedMissionAsDoneArgs,
  MissionPageSubmitMissionEditionMutation,
  MissionPageSubmitMissionEditionMutationVariables,
  MatchNextExpertMutation,
  MatchNextExpertMutationVariables,
} from "../../../graphql-operations-types";
import DropDownMenu from "../../molecules/drop-down-menu";
import Card from "../../molecules/card";
import StackedList from "../../molecules/stacked-list";
import CommentsCard from "../../organisms/comments-card";
import OfferStackedListItem from "../../organisms/offer-stacked-list-item";
import Table from "../../molecules/table";
import DataGrid from "../../molecules/data-grid";
import CancelMissionAlert from "../../organisms/cancel-mission-alert";
import Toaster from "../../atoms/toaster";
import CompleteMissionAlert, {
  IFormInputs,
} from "../../organisms/complete-mission-alert";
import AssignExpertAlert from "../../organisms/assign-expert-alert";
import Input from "../../atoms/input";
import GetBackOfficeUser from "../../../login-provider";
import MatchNextExpertAlert from "../../organisms/match-next-expert-alert";
import Button from "../../atoms/button";

const sapActivities = [
  {
    id: 1,
    name: "Garde d'enfants",
  },
  {
    id: 2,
    name: "Petits travaux de jardinage",
  },
  {
    id: 3,
    name: "Entretien de la maison et travaux ménagers",
  },
  {
    id: 4,
    name: "Travaux de petit bricolage",
  },
  {
    id: 5,
    name: "Soutien scolaire à domicile",
  },
  {
    id: 6,
    name: "Soins d'esthétique à domicile aux personnes dépendantes",
  },
  {
    id: 7,
    name: "Cours à domicile (hors soutien scolaire)",
  },
  {
    id: 8,
    name: "Préparation de repas à domicile",
  },
  {
    id: 9,
    name: "Livraison de repas à domicile",
  },
  {
    id: 10,
    name: "Collecte et livraison à domicile de linge repassé",
  },
  {
    id: 11,
    name: "Livraison de courses à domicile",
  },
  {
    id: 12,
    name: "Assistance informatique à domicile",
  },
  {
    id: 13,
    name: "Maintenance, entretien et vigilance temporaires à domicile",
  },
  {
    id: 14,
    name: "Assistance administrative à domicile",
  },
  {
    id: 15,
    name: "Téléassistance et visio assistance",
  },
  {
    id: 16,
    name: "Soins et promenades d'animaux de compagnie",
  },
  {
    id: 17,
    name: "Coordination et délivrance des SAP",
  },
  {
    id: 18,
    name: "Assistance aux personnes ayant besoin d’une aide temporaire",
  },
  {
    id: 19,
    name: "Prestation de conduite du véhicule personnel",
  },
];

const MISSION_PAGE_MISSION_FRAGMENT = gql`
  ${CommentsCard.COMMENT_FRAGMENT}

  fragment MissionPageExpert on Expert {
    id
    phoneNumber
    fullName
    profilePictureURL
  }

  fragment MissionPageJobOffer on JobOffer {
    id
    status
    rejectionReasons
    declineReasons
    expiresAt
    createdAt
    expert {
      id
      ...MissionPageExpert
    }
    expertTrackingUrl
  }

  fragment MissionPageCoupon on Coupon {
    __typename
    ... on PlatformCoupon {
      code
    }
    ... on MiraklCoupon {
      maybeCode: code
    }
  }

  fragment MissionPageQuestion on Question {
    ... on TextQuestion {
      label
    }
    ... on NumberQuestion {
      label
    }
    ... on MultipleChoiceQuestion {
      label
    }
    ... on MultipleChoicesQuestion {
      label
    }
  }

  fragment MissionPageMissionQuestionAnswer on MissionQuestionAnswer {
    ... on StringMissionQuestionAnswer {
      questionReference
      label
      question {
        ...MissionPageQuestion
      }
    }
    ... on NumberMissionQuestionAnswer {
      questionReference
      value
      question {
        ...MissionPageQuestion
      }
    }
  }

  fragment MissionPageMission on Mission {
    id
    status
    retailerProvidedDetails
    customerProvidedDetails
    customerCorporateName
    customerVatNumber
    billingAddress {
      street1
      zipCode
      city
      country
    }
    address {
      street1
      zipCode
      city
      country
      postalFormat
    }
    retailer {
      id
      name
    }
    price {
      type
      gross {
        amount
        currencyCode
      }
      TTC {
        amount
        currencyCode
      }
      expertProfit {
        amount
        currencyCode
      }
      serviceFeesSetupTTC {
        amount
        currencyCode
      }
    }
    coupon {
      ...MissionPageCoupon
    }
    createdAt
    date
    customerSuccessReminderDate
    customer {
      firstName
      lastName
      emailAddress
      phoneNumber
    }
    offer {
      id
      name
      requirements
      sapActivityId
    }
    jobOffers {
      ...MissionPageJobOffer
    }
    upcomingMatchingExperts {
      id
      ...MissionPageExpert
    }
    comments {
      ...CommentsCardComment
    }
    miraklOrderId
    missionQuestionsAnswers {
      ...MissionPageMissionQuestionAnswer
    }
    customerTrackingUrl
    doneAt
    isPaymentBlocked
    wishedDates {
      expectStart
      expectEnd
    }
    expectStart
    expectEnd
    isSap
    mediaFiles {
      mediaFileId
      mediaFileUrl
    }
  }
`;

export const MISSION_PAGE_QUERY = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  query MissionPage($missionId: String!) {
    mission(id: $missionId) {
      ...MissionPageMission
    }
  }
`;

const CANCEL_MISSION_MUTATION = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation CancelMission(
    $missionId: UUID!
    $cancelationReason: CanceledMission_CancelationReason!
    $cancelationComment: String!
    $refundClient: Boolean!
  ) {
    cancelMission(
      missionId: $missionId
      cancelationReason: $cancelationReason
      cancelationComment: $cancelationComment
      refundClient: $refundClient
    ) {
      ... on CancelMission_Success {
        mission {
          ...MissionPageMission
        }
      }
      ... on CancelMission_Failure {
        reason
      }
    }
  }
`;

const COMPLETE_MISSION_MUTATION = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation CompleteMission(
    $missionId: UUID!
    $VATRate: Float
    $retailerCoupon: String
  ) {
    markAcceptedMissionAsDone(
      missionId: $missionId
      VATRate: $VATRate
      retailerCoupon: $retailerCoupon
    ) {
      ... on MarkAcceptedMissionAsDone_Success {
        mission {
          ...MissionPageMission
        }
      }
      ... on MarkAcceptedMissionAsDone_Failure {
        reason
      }
    }
  }
`;

const SUBMIT_MISSION_COMMENT_MUTATION = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation MissionPageSubmitMissionComment(
    $missionId: UUID!
    $content: String!
  ) {
    submitMissionComment(missionId: $missionId, content: $content) {
      ... on SubmitMissionComment_Success {
        mission {
          ...MissionPageMission
        }
      }
    }
  }
`;

const SUBMIT_MISSION_EDITION_MUTATION = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation MissionPageSubmitMissionEdition(
    $missionId: UUID!
    $lastName: String!
    $firstName: String!
    $email: String!
    $phoneNumber: String!
    $address: SubmitMissionEdition_Address!
    $date: String!
    $csReminderDate: String!
    $customerDetails: String!
    $isPaymentBlocked: Boolean
    $expectStart: String
    $expectEnd: String
    $mediaFiles: [SubmitMissionEdition_MediaFiles]
  ) {
    submitMissionEdition(
      missionId: $missionId
      lastName: $lastName
      firstName: $firstName
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      date: $date
      csReminderDate: $csReminderDate
      customerDetails: $customerDetails
      isPaymentBlocked: $isPaymentBlocked
      expectStart: $expectStart
      expectEnd: $expectEnd
      mediaFiles: $mediaFiles
    ) {
      ... on SubmitMissionEdition_Success {
        mission {
          ...MissionPageMission
        }
      }
      ... on SubmitMissionEdition_Failure {
        reason
      }
    }
  }
`;

const ASSIGN_EXPERT_TO_MISSION_MUTATION = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation AssignExpert(
    $missionId: String!
    $expertId: String!
    $replaceJobOffer: Boolean!
  ) {
    assignExpertToMission(
      missionId: $missionId
      expertId: $expertId
      replaceJobOffer: $replaceJobOffer
    ) {
      ... on AssignExpertToMission_Success {
        mission {
          ...MissionPageMission
        }
      }
      ... on AssignExpertToMission_Failure {
        reason
      }
    }
  }
`;

const MATCH_NEXT_EXPERT = gql`
  ${MISSION_PAGE_MISSION_FRAGMENT}

  mutation MatchNextExpert($missionId: String!) {
    matchNextExpert(missionId: $missionId) {
      ... on MatchNextExpert_Success {
        mission {
          ...MissionPageMission
        }
      }
      ... on MatchNextExpert_Failure {
        reason
      }
    }
  }
`;

const REJECTION_REASON_MAP: Record<JobOfferRejectionReason, string> = {
  NOT_SKILLED: "pas compétent",
  OTHER: "autre",
  POOR_IMPRESSION: "mauvaise impression",
  REQUESTED_BYPASS: "demande de bypass",
  TOO_FAR: "trop loin",
};

const DECLINE_REASON_MAP: Record<JobOfferDeclineReason, string> = {
  DO_NOT_ACCEPT_REQUESTED_PAYMENT_METHOD: "refuse la méthode de paiement",
  NOT_AVAILABLE: "pas disponible",
  NOT_ENOUGH_DETAILS: "pas assez de details",
  NOT_SKILLED: "pas compétent",
  TOO_CHEAP: "pas assez bien payé",
  TOO_FAR: "trop loin",
};

const JobOfferStatusOfferStackedListItemRightSection: React.FC<{
  jobOffer: MissionPageJobOfferFragment;
}> = ({ jobOffer }) => {
  const intl = useIntl();

  switch (jobOffer.status) {
    case "ACCEPTED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-green-400"
                aria-hidden="true"
              />
              Acceptée
            </>
          }
        />
      );
    case "CANCELED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Annulée
            </>
          }
        />
      );
    case "DECLINED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Refusée par l&apos;expert
            </>
          }
          subtitle={`Raison(s): ${jobOffer.declineReasons
            ?.map((declineReason) => DECLINE_REASON_MAP[declineReason])
            ?.join(", ")}`}
        />
      );
    case "EXPIRED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Expirée
              {jobOffer.expiresAt && (
                <>
                  &nbsp;le&nbsp;
                  <FormattedDate
                    value={jobOffer.expiresAt}
                    dateStyle="full"
                    timeStyle="long"
                  />
                </>
              )}
            </>
          }
        />
      );
    case "PENDING_REPLY":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <ExclamationIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400"
                aria-hidden="true"
              />
              En attente de réponse
              {jobOffer.expiresAt && (
                <>
                  , expire&nbsp;
                  <span
                    title={intl.formatDate(jobOffer.expiresAt, {
                      dateStyle: "full",
                      timeStyle: "long",
                    })}
                  >
                    <FormattedRelativeTime
                      value={differenceInSeconds(
                        new Date(jobOffer.expiresAt),
                        new Date()
                      )}
                      updateIntervalInSeconds={1}
                    />
                  </span>
                </>
              )}
            </>
          }
        />
      );
    case "REJECTED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Rejettée par le client
            </>
          }
          subtitle={`Raison(s): ${jobOffer.rejectionReasons
            ?.map((rejectionReason) => REJECTION_REASON_MAP[rejectionReason])
            ?.join(", ")}`}
        />
      );
  }
};

const CouponDataTableRowValueCell: React.FC<{
  coupon: MissionPageCouponFragment | undefined | null;
}> = ({ coupon }) => {
  if (!coupon) {
    return <>-</>;
  }

  if (coupon.__typename === "PlatformCoupon") {
    return <>{coupon.code}</>;
  }

  if (!coupon.maybeCode) {
    return <>Mirakl - non renseigné</>;
  }

  return <>Mirakl - {coupon.maybeCode}</>;
};

interface MissionMediaFile {
  mediaFileId?: string;
  mediaFileName?: string;
  mediaFileBuffer?: string;
  mediaFileType?: string;
  mediaFileUrl?: string;
}

const DataTableRow: React.FC<{
  title: React.ReactNode;
}> = ({ title, children }) => (
  <Table.Row>
    <Table.DataCell className="w-[1%] text-sm font-medium text-gray-500">
      {title}
    </Table.DataCell>
    <Table.DataCell className="text-sm">{children}</Table.DataCell>
  </Table.Row>
);

const DataCard: React.FC<{
  editionValues: {
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    date: string;
    csReminderDate: string;
    customerDetails: string;
    expectStart: string;
    expectEnd: string;
  };
  setEditionValues: Dispatch<
    SetStateAction<{
      lastName: string;
      firstName: string;
      email: string;
      phoneNumber: string;
      street: string;
      zipCode: string;
      city: string;
      country: string;
      date: string;
      csReminderDate: string;
      customerDetails: string;
      expectStart: string;
      expectEnd: string;
    }>
  >;
  onMediaFileAdded: (mediaFile: MissionMediaFile) => void;
  onMediaFileDeleted: (index: number) => void;
  mediaFiles: MissionMediaFile[];
  edition: boolean;
  mission: MissionPageMissionFragment;
}> = ({
  editionValues,
  setEditionValues,
  onMediaFileAdded,
  onMediaFileDeleted,
  mediaFiles,
  edition,
  mission,
}) => {
  const editionChangeHandler = (e: { target: { name: any; value: any } }) => {
    setEditionValues({ ...editionValues, [e.target.name]: e.target.value });
  };
  const hiddenFileInputRef = React.useRef<HTMLInputElement>(null);
  const [mediaUploadError, displayMediaUploadError] = useState("");
  const [mediaFilesList, setMediaFilesList] =
    useState<MissionMediaFile[]>(mediaFiles);

  const handleMediaFileInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.files) {
        return;
      }

      const file = event.currentTarget.files[0];
      if (
        file &&
        !mediaFilesList.some((item) => file.name === item.mediaFileName)
      ) {
        // don't submit if file is greater than 5MB
        if (file.size > 5 * 1024 * 1024) {
          displayMediaUploadError(
            "Le fichier est trop volumineux pour être chargé. (5MB max)"
          );
          return;
        }
        const arrayBuffer = await file.arrayBuffer();
        if (!arrayBuffer) {
          return;
        }
        const buffer = Buffer.from(arrayBuffer);
        if (!buffer) {
          return;
        }

        setMediaFilesList([
          ...mediaFilesList,
          {
            mediaFileName: file.name,
            mediaFileBuffer: buffer.toString("base64"),
            mediaFileType: file.type,
            mediaFileUrl: undefined,
          },
        ]);
        onMediaFileAdded({
          mediaFileName: file.name,
          mediaFileBuffer: buffer.toString("base64"),
          mediaFileType: file.type,
        });
        displayMediaUploadError("");
      }
    },
    [mediaFilesList, onMediaFileAdded]
  );

  const onItemTrachClicked = useCallback(
    (index: number) => {
      const array = [...mediaFilesList];
      array.splice(index, 1);
      setMediaFilesList(array);
      onMediaFileDeleted(index);
      displayMediaUploadError("");
    },
    [mediaFilesList, onMediaFileDeleted]
  );

  return (
    <Card>
      {mission.status === "ACCEPTED" &&
        mission.doneAt &&
        mission.isPaymentBlocked && (
          <Card.Header title="Paiement Bloqué - Litige en cours" danger />
        )}
      {mission.status === "ACCEPTED" &&
        mission.doneAt &&
        !mission.isPaymentBlocked && (
          <Card.Header title="Mission terminée - Paiement en attente" />
        )}
      <Card.Header title="Informations" />
      <Card.Section title="Client">
        <DataGrid>
          <DataGrid.Cell title="Nom">
            {!edition && (mission.customer?.lastName ?? "-")}
            {edition && (
              <Input
                className="w-full border-gray-300"
                id="lastName"
                name="lastName"
                onChange={editionChangeHandler}
                defaultValue={mission.customer?.lastName ?? ""}
                type="text"
              />
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Email">
            {!edition &&
              (mission.customer?.emailAddress ? (
                <a href={`mailto:${mission.customer.emailAddress}`}>
                  {mission.customer.emailAddress}
                </a>
              ) : (
                "-"
              ))}
            {edition && (
              <Input
                className="w-full border-gray-300"
                id="email"
                name="email"
                onChange={editionChangeHandler}
                defaultValue={mission.customer?.emailAddress ?? ""}
                type="email"
              />
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Prénom">
            {!edition && (mission.customer?.firstName ?? "-")}
            {edition && (
              <Input
                className="w-full border-gray-300"
                id="firstName"
                name="firstName"
                onChange={editionChangeHandler}
                defaultValue={mission.customer?.firstName ?? ""}
                type="text"
              />
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Téléphone">
            {!edition &&
              (mission.customer?.phoneNumber ? (
                <a href={`tel:${mission.customer.phoneNumber}`}>
                  {mission.customer.phoneNumber}
                </a>
              ) : (
                "-"
              ))}
            {edition && (
              <Input
                className="w-full border-gray-300"
                id="phoneNumber"
                name="phoneNumber"
                onChange={editionChangeHandler}
                defaultValue={mission.customer?.phoneNumber ?? ""}
                type="text"
              />
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Adresse">
            {!edition && (mission.address?.postalFormat ?? "-")}
          </DataGrid.Cell>
          <DataGrid.Cell title="Dénomination sociale">
            {!edition && (mission.customerCorporateName ?? "-")}
          </DataGrid.Cell>
        </DataGrid>
        {edition && (
          <div className="flex flex-wrap">
            <Input
              className="border-gray-300 w-1/3"
              id="street"
              name="street"
              onChange={editionChangeHandler}
              defaultValue={mission.address?.street1 ?? ""}
              type="text"
              placeholder="Street"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="zipCode"
              name="zipCode"
              onChange={editionChangeHandler}
              defaultValue={mission.address?.zipCode ?? ""}
              type="text"
              placeholder="Post code"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="city"
              name="city"
              onChange={editionChangeHandler}
              defaultValue={mission.address?.city ?? ""}
              type="text"
              placeholder="City"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="country"
              name="country"
              onChange={editionChangeHandler}
              defaultValue={mission.address?.country ?? ""}
              type="text"
              placeholder="Country"
            />
          </div>
        )}
      </Card.Section>
      <Card.Section title="Prestation" padded={false}>
        <Table>
          <Table.Body>
            <DataTableRow title="Date de relance CS">
              {!edition &&
                (mission.customerSuccessReminderDate ? (
                  <FormattedDate value={mission.customerSuccessReminderDate} />
                ) : (
                  "-"
                ))}
              {edition && (
                <Input
                  className="w-full border-gray-300"
                  id="csReminderDate"
                  name="csReminderDate"
                  onChange={editionChangeHandler}
                  defaultValue={
                    mission.customerSuccessReminderDate
                      ? mission.customerSuccessReminderDate.slice(0, 10)
                      : ""
                  }
                  type="date"
                />
              )}
            </DataTableRow>
            <DataTableRow title="Offre">
              {mission.isSap ? (
                <span className="bg-magenta-6 text-white py-1 px-2 rounded-md text-xs">
                  SAP
                </span>
              ) : null}{" "}
              {mission.offer.name}
            </DataTableRow>
            {mission.offer.sapActivityId ? (
              <DataTableRow title="Domaine d'activité SAP">
                {
                  sapActivities.find(
                    (activity) =>
                      activity.id === Number(mission.offer.sapActivityId)
                  )?.name
                }
              </DataTableRow>
            ) : null}
            <DataTableRow title="Date de réalisation">
              <div>
                <div className="w-[1%] text-sm font-medium text-gray-500">
                  Dates souhaitées par le client:
                </div>
                <div className="mt-2">
                  {mission.wishedDates
                    ? mission.wishedDates.map((wishedDates, i) => (
                        <div key={i}>
                          <span className="font-bold">Date {i + 1}</span> entre
                          le{" "}
                          {new Date(wishedDates.expectStart).toLocaleString(
                            "fr-FR",
                            {
                              dateStyle: "full",
                              timeStyle: "short",
                              timeZone: "CET",
                            }
                          )}{" "}
                          et le{" "}
                          {new Date(wishedDates.expectEnd).toLocaleString(
                            "fr-FR",
                            {
                              dateStyle: "full",
                              timeStyle: "short",
                              timeZone: "CET",
                            }
                          )}
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
              <div className="mt-4">
                <div className="w-[1%] text-sm font-medium text-gray-500">
                  Dates prévue:
                </div>
                <div className="mt-2">
                  {!edition &&
                    (mission.expectStart && mission.expectEnd ? (
                      <div>
                        Entre le{" "}
                        {new Date(mission.expectStart).toLocaleString("fr-FR", {
                          dateStyle: "full",
                          timeStyle: "short",
                          timeZone: "CET",
                        })}{" "}
                        et le{" "}
                        {new Date(mission.expectEnd).toLocaleString("fr-FR", {
                          dateStyle: "full",
                          timeStyle: "short",
                          timeZone: "CET",
                        })}
                      </div>
                    ) : (
                      "-"
                    ))}
                  {edition && (
                    <div>
                      Entre le{" "}
                      <Input
                        className=" border-gray-300"
                        id="expectStart"
                        name="expectStart"
                        onChange={editionChangeHandler}
                        defaultValue={
                          mission.expectStart
                            ? mission.expectStart.slice(0, 16)
                            : ""
                        }
                        type="datetime-local"
                      />{" "}
                      et le{" "}
                      <Input
                        className=" border-gray-300"
                        id="expectEnd"
                        name="expectEnd"
                        onChange={editionChangeHandler}
                        defaultValue={
                          mission.expectEnd
                            ? mission.expectEnd.slice(0, 16)
                            : ""
                        }
                        type="datetime-local"
                      />
                    </div>
                  )}
                </div>
              </div>
            </DataTableRow>
            <DataTableRow title="Prix total TTC">
              {mission.price ? (
                <FormattedNumber
                  style="currency"
                  value={
                    (mission.price?.type ===
                      "TransientMarketplaceMissionPrice" ||
                      mission.price?.type === "TransientApiMissionPrice" ||
                      mission.price?.type === "FinalMarketplaceMissionPrice" ||
                      mission.price?.type === "FinalApiMissionPrice") &&
                    mission.price?.TTC?.amount
                      ? mission.price?.TTC.amount
                      : mission.price.gross?.amount
                      ? mission.price?.gross.amount
                      : -1
                  }
                  currency={
                    (mission.price?.type ===
                      "TransientMarketplaceMissionPrice" ||
                      mission.price?.type === "TransientApiMissionPrice" ||
                      mission.price?.type === "FinalMarketplaceMissionPrice" ||
                      mission.price?.type === "FinalApiMissionPrice") &&
                    mission.price?.TTC?.currencyCode
                      ? mission.price?.TTC.currencyCode
                      : mission.price.gross?.currencyCode
                      ? mission.price?.gross.currencyCode
                      : "€"
                  }
                />
              ) : (
                "-"
              )}
            </DataTableRow>
            <DataTableRow title="Rémunération Expert">
              {mission.price && mission.price.expertProfit ? (
                <FormattedNumber
                  style="currency"
                  value={mission.price.expertProfit.amount}
                  currency={mission.price.expertProfit.currencyCode}
                />
              ) : (
                "-"
              )}
            </DataTableRow>
            <DataTableRow title="Frais de service">
              {mission.price && mission.price.serviceFeesSetupTTC ? (
                <FormattedNumber
                  style="currency"
                  value={mission.price.serviceFeesSetupTTC.amount}
                  currency={mission.price.serviceFeesSetupTTC.currencyCode}
                />
              ) : (
                "-"
              )}
            </DataTableRow>
            <DataTableRow title="Code coupon">
              <CouponDataTableRowValueCell coupon={mission.coupon} />
            </DataTableRow>
            <DataTableRow title="Date de création">
              <FormattedDate value={mission.createdAt} />
            </DataTableRow>
            <DataTableRow title="Statut">{mission.status}</DataTableRow>
            <DataTableRow title="Lien de la mission">
              <a
                href={mission.customerTrackingUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-[blue]"
              >
                {mission.customerTrackingUrl}
              </a>
            </DataTableRow>
            <DataTableRow title="Retailer">
              {mission.retailer.name}
            </DataTableRow>
            <DataTableRow title="Détails client">
              {!edition && (mission.customerProvidedDetails ?? "-")}
              {edition && (
                <Input
                  className="w-full border-gray-300"
                  id="customerDetails"
                  name="customerDetails"
                  onChange={editionChangeHandler}
                  defaultValue={mission.customerProvidedDetails ?? ""}
                  type="text"
                />
              )}
            </DataTableRow>
            <DataTableRow title="Détails retailer">
              {mission.retailerProvidedDetails ?? "-"}
            </DataTableRow>
            <DataTableRow title="Mirakl ID">
              {mission.miraklOrderId ?? "-"}
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.conditions"
                  defaultMessage="Conditions d’exécutions"
                />
              }
            >
              <ul className="list-inside list-disc">
                {mission.offer.requirements.map((requirement) => (
                  <li key={requirement}>{requirement}</li>
                ))}
              </ul>
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.questions.answers"
                  defaultMessage="Questions / Réponses"
                />
              }
            >
              {mission.missionQuestionsAnswers.length > 0 ? (
                <ul className="list-inside list-disc">
                  {mission.missionQuestionsAnswers.map((questionAnswer, i) => (
                    <div key={i}>
                      <li className="font-medium">
                        {questionAnswer.question?.label}
                      </li>
                      {questionAnswer.__typename ===
                      "NumberMissionQuestionAnswer" ? (
                        <div key={questionAnswer.value} className="italic px-5">
                          {questionAnswer.value}
                        </div>
                      ) : questionAnswer.__typename ===
                        "StringMissionQuestionAnswer" ? (
                        <div key={questionAnswer.label} className="italic px-5">
                          {questionAnswer.label}
                        </div>
                      ) : null}
                      <br />
                    </div>
                  ))}
                </ul>
              ) : (
                "-"
              )}
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.medias"
                  defaultMessage="Pièces jointes"
                />
              }
            >
              {mediaFilesList.length > 0 ? (
                <ul className="list-inside list-disc">
                  {mediaFilesList.map((mediaFile, i) => (
                    <div key={i} className="flex flex-row">
                      <li className="font-medium">
                        {mediaFile.mediaFileName ? (
                          mediaFile.mediaFileName
                        ) : (
                          <a
                            href={mediaFile.mediaFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline text-blue-600"
                          >{`Fichier ${i + 1}`}</a>
                        )}
                      </li>
                      {edition && (
                        <div className="ml-3">
                          <TrashIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            onClick={() => onItemTrachClicked(i)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              ) : (
                "-"
              )}
              {edition && mediaUploadError && (
                <div className="mt-2 flex flex-row text-red-600">
                  <ExclamationCircleIcon
                    className="h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                  {mediaUploadError}
                </div>
              )}
              {edition && mediaFilesList.length !== 5 && (
                <Button
                  className="mt-2"
                  onClick={() => {
                    hiddenFileInputRef?.current?.click();
                  }}
                >
                  Ajouter
                </Button>
              )}
              <input
                name="profile_picture"
                id="profile_picture"
                type="file"
                className="hidden"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(event) => {
                  handleMediaFileInputChange(event);
                  event.target.value = "";
                }}
                disabled={mediaFilesList.length === 5}
                ref={hiddenFileInputRef}
              />
            </DataTableRow>
          </Table.Body>
        </Table>
      </Card.Section>
    </Card>
  );
};

const _AssignExpertAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  mission: MissionPageMissionFragment;
}> = ({ mission, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [assignExpertToMissionMutation] = useMutation<
    AssignExpertMutation,
    AssignExpertMutationVariables
  >(ASSIGN_EXPERT_TO_MISSION_MUTATION);
  const handleAssignExpert = useCallback(
    async (expertId: string) => {
      const result = await assignExpertToMissionMutation({
        variables: {
          missionId: mission.id,
          expertId,
          replaceJobOffer: mission.status === "SUBMITTED" ? false : true,
        },
      });
      if (
        result.data?.assignExpertToMission.__typename ===
        "AssignExpertToMission_Success"
      ) {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast onCloseClick={onClose} body="Expert assigné" />
        ));
      } else if (
        result.data?.assignExpertToMission.__typename ===
        "AssignExpertToMission_Failure"
      ) {
        const reason = result.data?.assignExpertToMission?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [
      assignExpertToMissionMutation,
      mission.id,
      mission.status,
      onClose,
      toaster,
    ]
  );

  return (
    <AssignExpertAlert
      show={show}
      onClose={onClose}
      onSubmit={handleAssignExpert}
    />
  );
};

const _MatchNextExpertAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  mission: MissionPageMissionFragment;
}> = ({ mission, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [matchNextExpertMutation, { loading }] = useMutation<
    MatchNextExpertMutation,
    MatchNextExpertMutationVariables
  >(MATCH_NEXT_EXPERT);
  const handleMatchNextExpert = useCallback(async () => {
    const result = await matchNextExpertMutation({
      variables: {
        missionId: mission.id,
      },
    });
    if (result.data?.matchNextExpert.__typename === "MatchNextExpert_Success") {
      onClose();
      toaster.push(({ onClose }) => (
        <Toaster.SuccessToast
          onCloseClick={onClose}
          body="Passage à l'expert suivant"
        />
      ));
    } else if (
      result.data?.matchNextExpert.__typename === "MatchNextExpert_Failure"
    ) {
      const reason = result.data?.matchNextExpert?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [matchNextExpertMutation, mission.id, onClose, toaster]);

  return (
    <MatchNextExpertAlert
      show={show}
      onClose={onClose}
      onSubmit={handleMatchNextExpert}
      loading={loading}
    />
  );
};

const _CompleteMissionAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  mission: MissionPageMissionFragment;
}> = ({ mission, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [handleCompleteMissionMutation] = useMutation<
    CompleteMissionMutation,
    MutationMarkAcceptedMissionAsDoneArgs
  >(COMPLETE_MISSION_MUTATION);
  const handleCompleteMission = useCallback(
    async (data: IFormInputs) => {
      let coupon = null;
      if (mission.coupon) {
        if (mission.coupon.__typename === "PlatformCoupon") {
          coupon = mission.coupon.code;
        } else if (mission.coupon.__typename === "MiraklCoupon") {
          coupon = data.coupon;
        }
      }

      const result = await handleCompleteMissionMutation({
        variables: {
          missionId: mission.id,
          VATRate: data.choice,
          retailerCoupon: coupon,
        },
      });

      if (
        result.data?.markAcceptedMissionAsDone.__typename ===
        "MarkAcceptedMissionAsDone_Success"
      ) {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast
            onCloseClick={onClose}
            body="Mission terminée"
          />
        ));
      } else if (
        result.data?.markAcceptedMissionAsDone.__typename ===
        "MarkAcceptedMissionAsDone_Failure"
      ) {
        const reason = result.data?.markAcceptedMissionAsDone?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [
      handleCompleteMissionMutation,
      mission.id,
      mission.coupon,
      onClose,
      toaster,
    ]
  );

  return (
    <CompleteMissionAlert
      show={show}
      onClose={onClose}
      onSubmit={handleCompleteMission}
      requireCoupon={mission?.coupon?.__typename === "MiraklCoupon"}
    />
  );
};

const _CancelMissionAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  mission: MissionPageMissionFragment;
}> = ({ mission, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [handleCancelMissionMutation] = useMutation<
    CancelMissionMutation,
    MutationCancelMissionArgs
  >(CANCEL_MISSION_MUTATION);
  const handleCancelMission = useCallback(
    async (
      choice: CanceledMission_CancelationReason,
      cancelationComment: string,
      refundClient: boolean
    ) => {
      const result = await handleCancelMissionMutation({
        variables: {
          cancelationReason: choice,
          missionId: mission.id,
          cancelationComment,
          refundClient,
        },
      });

      if (result.data?.cancelMission.__typename === "CancelMission_Success") {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast onCloseClick={onClose} body="Mission annulée" />
        ));
      } else if (
        result.data?.cancelMission.__typename === "CancelMission_Failure"
      ) {
        const reason = result.data?.cancelMission?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [handleCancelMissionMutation, mission.id, onClose, toaster]
  );

  return (
    <CancelMissionAlert
      show={show}
      onClose={onClose}
      onSubmit={handleCancelMission}
    />
  );
};

const _MissionPage: React.FC<{
  mission: MissionPageMissionFragment;
  onSubmitComment: (content: string) => void;
}> = ({ mission, onSubmitComment }) => {
  const [openAssignExpertAlert, setOpenAssignExpertAlert] = useState(false);
  const [openCancelMissionAlert, setOpenCancelMissionAlert] = useState(false);
  const [openCompleteMissionAlert, setOpenCompleteMissionAlert] =
    useState(false);
  const [openMatchNextExpertAlert, setOpenMatchNextExpertAlert] =
    useState(false);
  const [edition, setEdition] = useState(false);
  const [editionValues, setEditionValues] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    street: "",
    zipCode: "",
    city: "",
    country: "",
    date: "",
    csReminderDate: "",
    customerDetails: "",
    expectStart: "",
    expectEnd: "",
  });
  const mediaFiles: MissionMediaFile[] = useMemo(() => {
    return mission.mediaFiles
      ? mission.mediaFiles.map((mediaFile) => {
          return {
            mediaFileId: mediaFile.mediaFileId,
            mediaFileName: undefined,
            mediaFileBuffer: undefined,
            mediaFileType: undefined,
            mediaFileUrl: mediaFile.mediaFileUrl,
          };
        })
      : [];
  }, [mission.mediaFiles]);
  const onMediaFileAdded = useCallback(
    (mediaFile: MissionMediaFile) => {
      mediaFiles.push({
        mediaFileName: mediaFile.mediaFileName,
        mediaFileBuffer: mediaFile.mediaFileBuffer,
        mediaFileType: mediaFile.mediaFileType,
      });
    },
    [mediaFiles]
  );
  const onMediaFileDeleted = useCallback(
    (index: number) => {
      mediaFiles.splice(index, 1);
    },
    [mediaFiles]
  );

  const toaster = Toaster.useToaster();
  const [submitMissionEditionMutation] = useMutation<
    MissionPageSubmitMissionEditionMutation,
    MissionPageSubmitMissionEditionMutationVariables
  >(SUBMIT_MISSION_EDITION_MUTATION);
  const handleEditionSubmit = useCallback(
    async ({
      lastName,
      firstName,
      email,
      phoneNumber,
      street,
      zipCode,
      city,
      country,
      date,
      csReminderDate,
      customerDetails,
      isPaymentBlocked,
      expectStart,
      expectEnd,
      mediaFiles,
    }: {
      lastName: string;
      firstName: string;
      email: string;
      phoneNumber: string;
      street: string;
      zipCode: string;
      city: string;
      country: string;
      date: string;
      csReminderDate: string;
      customerDetails: string;
      isPaymentBlocked?: boolean;
      expectStart?: string;
      expectEnd?: string;
      mediaFiles: MissionMediaFile[];
    }) => {
      const result = await submitMissionEditionMutation({
        variables: {
          missionId: mission.id,
          lastName,
          firstName,
          email,
          phoneNumber,
          address: {
            street,
            zipCode,
            city,
            country,
          },
          date,
          csReminderDate,
          customerDetails,
          isPaymentBlocked,
          expectStart,
          expectEnd,
          mediaFiles,
        },
      });

      if (
        result.data?.submitMissionEdition.__typename ===
        "SubmitMissionEdition_Success"
      ) {
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast
            onCloseClick={onClose}
            body="Mission modifiée"
          />
        ));
        setEdition(false);
      } else if (
        result.data?.submitMissionEdition.__typename ===
        "SubmitMissionEdition_Failure"
      ) {
        const { reason } = result.data?.submitMissionEdition;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [submitMissionEditionMutation, mission.id, toaster]
  );
  const backOfficeUser = GetBackOfficeUser();

  return (
    <div className="flex w-full flex-col">
      <_AssignExpertAlert
        show={openAssignExpertAlert}
        onClose={() => {
          setOpenAssignExpertAlert(false);
        }}
        mission={mission}
      />

      <_MatchNextExpertAlert
        show={openMatchNextExpertAlert}
        onClose={() => {
          setOpenMatchNextExpertAlert(false);
        }}
        mission={mission}
      />

      <_CompleteMissionAlert
        show={openCompleteMissionAlert}
        onClose={() => {
          setOpenCompleteMissionAlert(false);
        }}
        mission={mission}
      />

      <_CancelMissionAlert
        show={openCancelMissionAlert}
        onClose={() => {
          setOpenCancelMissionAlert(false);
        }}
        mission={mission}
      />

      {!backOfficeUser?.isViewer &&
        mission.status !== "DONE" &&
        mission.status !== "CANCELLED" && (
          <div className="place-self-end">
            {mission.status === "ACCEPTED" &&
              !mission.isPaymentBlocked &&
              mission.doneAt && (
                <button
                  type="button"
                  className="bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                  onClick={() =>
                    handleEditionSubmit({
                      lastName: editionValues.lastName,
                      firstName: editionValues.firstName,
                      email: editionValues.email,
                      phoneNumber: editionValues.phoneNumber,
                      street: editionValues.street,
                      zipCode: editionValues.zipCode,
                      city: editionValues.city,
                      country: editionValues.country,
                      date: editionValues.date,
                      csReminderDate: editionValues.csReminderDate,
                      customerDetails: editionValues.customerDetails,
                      isPaymentBlocked: true,
                      expectStart: editionValues.expectStart,
                      expectEnd: editionValues.expectEnd,
                      mediaFiles,
                    })
                  }
                >
                  <span className="w-12 z-10">Bloquer le paiement</span>
                </button>
              )}
            {mission.status === "ACCEPTED" &&
              mission.isPaymentBlocked &&
              mission.doneAt && (
                <button
                  type="button"
                  className="bg-green-400 hover:bg-green-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                  onClick={() =>
                    handleEditionSubmit({
                      lastName: editionValues.lastName,
                      firstName: editionValues.firstName,
                      email: editionValues.email,
                      phoneNumber: editionValues.phoneNumber,
                      street: editionValues.street,
                      zipCode: editionValues.zipCode,
                      city: editionValues.city,
                      country: editionValues.country,
                      date: editionValues.date,
                      csReminderDate: editionValues.csReminderDate,
                      customerDetails: editionValues.customerDetails,
                      isPaymentBlocked: false,
                      expectStart: editionValues.expectStart,
                      expectEnd: editionValues.expectEnd,
                      mediaFiles,
                    })
                  }
                >
                  <span className="w-12 z-10">Débloquer le paiement</span>
                </button>
              )}
            {edition && (
              <>
                <button
                  onClick={() =>
                    handleEditionSubmit({
                      lastName: editionValues.lastName,
                      firstName: editionValues.firstName,
                      email: editionValues.email,
                      phoneNumber: editionValues.phoneNumber,
                      street: editionValues.street,
                      zipCode: editionValues.zipCode,
                      city: editionValues.city,
                      country: editionValues.country,
                      date: editionValues.date,
                      csReminderDate: editionValues.csReminderDate,
                      customerDetails: editionValues.customerDetails,
                      expectStart: editionValues.expectStart,
                      expectEnd: editionValues.expectEnd,
                      mediaFiles,
                    })
                  }
                  type="button"
                  className="bg-green-400 hover:bg-green-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                >
                  <span className="w-12 z-10">Appliquer</span>
                </button>
                <button
                  type="button"
                  className="bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                  onClick={() => setEdition(false)}
                >
                  <span className="w-12 z-10">Annuler</span>
                </button>
              </>
            )}
            {!edition && mission.status !== "DRAFT" && (
              <button
                type="button"
                className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                onClick={() => setEdition(true)}
              >
                <span className="w-12 z-10">Modifier</span>
              </button>
            )}

            {!backOfficeUser?.isViewer && (
              <DropDownMenu
                button={
                  <DropDownMenu.Button className="rounded-lg border-2 border-blue-600 p-2 text-blue-600">
                    Options
                  </DropDownMenu.Button>
                }
              >
                <DropDownMenu.ItemGroup>
                  {mission.status === "SUBMITTED" && (
                    <>
                      <DropDownMenu.ButtonItem
                        onClick={() => {
                          setOpenAssignExpertAlert(true);
                        }}
                      >
                        Assigner un Expert
                      </DropDownMenu.ButtonItem>
                      <DropDownMenu.ButtonItem
                        onClick={() => {
                          setOpenMatchNextExpertAlert(true);
                        }}
                      >
                        Passer à l&apos;expert suivant
                      </DropDownMenu.ButtonItem>
                    </>
                  )}
                  {mission.status === "ACCEPTED" && (
                    <DropDownMenu.ButtonItem
                      onClick={() => {
                        setOpenAssignExpertAlert(true);
                      }}
                    >
                      Modifier l`Expert
                    </DropDownMenu.ButtonItem>
                  )}
                  {mission.status === "ACCEPTED" && (
                    <DropDownMenu.ButtonItem
                      onClick={() => {
                        setOpenCompleteMissionAlert(true);
                      }}
                    >
                      Terminer la prestation
                    </DropDownMenu.ButtonItem>
                  )}
                  <DropDownMenu.ButtonItem
                    onClick={() => {
                      setOpenCancelMissionAlert(true);
                    }}
                  >
                    Annuler la prestation
                  </DropDownMenu.ButtonItem>
                </DropDownMenu.ItemGroup>
              </DropDownMenu>
            )}
          </div>
        )}
      <div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3 mt-4">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <DataCard
            editionValues={editionValues}
            setEditionValues={setEditionValues}
            edition={edition}
            mission={mission}
            onMediaFileAdded={onMediaFileAdded}
            onMediaFileDeleted={onMediaFileDeleted}
            mediaFiles={mediaFiles}
          />
          <Card>
            <Card.Header title="Offres" />
            <Card.Section padded={false} title="Envoyées">
              <StackedList>
                {sortBy(
                  mission.jobOffers,
                  (jobOffer) => jobOffer.createdAt
                ).map((jobOffer) => (
                  <OfferStackedListItem
                    key={jobOffer.id}
                    expert={{
                      id: jobOffer.expert.id,
                      name: jobOffer.expert.fullName,
                      phoneNumber: jobOffer.expert.phoneNumber,
                      profilePictureURL: jobOffer.expert.profilePictureURL,
                      expertTrackingUrl: jobOffer.expertTrackingUrl,
                    }}
                    rightSection={
                      <JobOfferStatusOfferStackedListItemRightSection
                        jobOffer={jobOffer}
                      />
                    }
                  />
                ))}
              </StackedList>
            </Card.Section>
            <Card.Section padded={false} title="À venir">
              <StackedList>
                {mission.upcomingMatchingExperts.map((expert) => (
                  <OfferStackedListItem
                    key={expert.id}
                    expert={{
                      id: expert.id,
                      name: expert.fullName,
                      phoneNumber: expert.phoneNumber,
                      profilePictureURL: expert.profilePictureURL,
                    }}
                  />
                ))}
              </StackedList>
            </Card.Section>
          </Card>
        </div>
        <div className="lg:col-span-1 lg:col-start-3">
          <CommentsCard
            onSubmitComment={onSubmitComment}
            comments={sortBy(
              mission.comments,
              (comment) => comment.createdAt
            ).map((comment) => (
              <CommentsCard.Comment key={comment.id} comment={comment} />
            ))}
          />
        </div>
      </div>
    </div>
  );
};

const MissionPage: React.FC<{ missionId: string }> = ({ missionId }) => {
  const queryResult = useQuery<MissionPageQuery, MissionPageQueryVariables>(
    MISSION_PAGE_QUERY,
    { variables: { missionId } }
  );
  const [submitMissionCommentMutation] = useMutation<
    MissionPageSubmitMissionCommentMutation,
    MissionPageSubmitMissionCommentMutationVariables
  >(SUBMIT_MISSION_COMMENT_MUTATION);
  const handleSubmitComment = useCallback(
    (content: string) => {
      submitMissionCommentMutation({ variables: { content, missionId } });
    },
    [missionId, submitMissionCommentMutation]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (queryResult.loading) {
    return null;
  }

  if (!queryResult.data?.mission) {
    return <>Not Found</>;
  }

  return (
    <_MissionPage
      mission={queryResult.data.mission}
      onSubmitComment={handleSubmitComment}
    />
  );
};

export default MissionPage;
