import { gql, useQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import {
  useQueryParam,
  withDefault,
  NumberParam,
  StringParam,
} from "use-query-params";
import Table from "../../../molecules/table";
import {
  DraftQuoteRequestsTabQuery,
  DraftQuoteRequestsTabQueryVariables,
  DraftQuoteRequestsTabQuoteRequestFragment,
} from "../../../../graphql-operations-types";
import Pagination from "../../../molecules/pagination";
import FilterDropDown from "../../../organisms/filter-drop-down";

const QUERY = gql`
  fragment DraftQuoteRequestsTabQuoteRequest on QuoteRequest {
    id
    createdAt
    customerSuccessReminderDate
    offer {
      id
      name
    }
    retailer {
      id
      name
    }
    address {
      zipCode
    }
    customerFirstName
    customerLastName
    customerPhoneNumber
    expert {
      id
      fullName
    }
  }

  query DraftQuoteRequestsTab(
    $limit: Int
    $offset: Int
    $quoteRequestStatus: QuoteRequestQueryQuoteRequestStatus!
    $quoteOfferStatus: QuoteRequestQueryQuoteOfferStatus
    $quoteStatus: QuoteRequestQueryQuoteStatus
    $quoteRequestsQueryParametersWhere: QuoteRequestsQueryParametersWhere
  ) {
    quoteRequestsByQuoteOfferStatus(
      limit: $limit
      offset: $offset
      quoteRequestStatus: $quoteRequestStatus
      quoteOfferStatus: $quoteOfferStatus
      quoteStatus: $quoteStatus
      where: $quoteRequestsQueryParametersWhere
    ) {
      __typename
      totalCount
      quoteRequests {
        ...DraftQuoteRequestsTabQuoteRequest
      }
    }
  }
`;

const QuoteRequestRow: React.FC<
  React.ComponentProps<typeof Table.RowLink> & {
    quoteRequest: DraftQuoteRequestsTabQuoteRequestFragment;
  }
> = ({ quoteRequest, ...props }) => {
  return (
    <Table.RowLink {...props}>
      {/* date de création */}
      <Table.DataCell className="font-normal">
        <FormattedDate value={quoteRequest.createdAt} />
      </Table.DataCell>

      {/* date de relance CS */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerSuccessReminderDate ? (
          <FormattedDate value={quoteRequest.customerSuccessReminderDate} />
        ) : (
          "-"
        )}
      </Table.DataCell>

      {/* offre */}
      <Table.DataCell className="font-normal">
        {quoteRequest.offer.name}
      </Table.DataCell>

      {/* expert */}
      <Table.DataCell className="font-normal">
        {quoteRequest.expert?.fullName ?? "-"}
      </Table.DataCell>

      {/* code postal */}
      <Table.DataCell className="font-normal">
        {quoteRequest.address.zipCode ?? "-"}
      </Table.DataCell>

      {/* nom du client */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerLastName && quoteRequest.customerFirstName
          ? `${quoteRequest.customerFirstName} ${quoteRequest.customerLastName}`
          : "-"}
      </Table.DataCell>

      {/* numéro de téléphone du client */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerPhoneNumber ?? "-"}
      </Table.DataCell>

      {/* retailer */}
      <Table.DataCell className="font-normal">
        {quoteRequest.retailer.name ?? "-"}
      </Table.DataCell>
    </Table.RowLink>
  );
};

const _DraftQuoteRequestsTab: React.FC<{
  currentPage: number;
  pageSize: number;
  draftQuoteRequestsQueryResult: DraftQuoteRequestsTabQuery;
  getQuoteRequestPageLocation: (
    quoteRequest: DraftQuoteRequestsTabQuoteRequestFragment
  ) => React.ComponentProps<typeof Link>["to"];
  handleFilterQuoteSubmit: (properties: {
    afterCreatedDate?: string | null;
    afterSubmittedDate?: string | null;
    afterCsReminderDate?: string | null;
    customer?: string | null;
    phoneNumber?: string | null;
    postCode?: string | null;
  }) => void;
}> = ({
  draftQuoteRequestsQueryResult,
  getQuoteRequestPageLocation,
  currentPage,
  pageSize,
  handleFilterQuoteSubmit,
}) => {
  return (
    <div className="space-y-2">
      <div className="mb-10">
        <div className="absolute right-8 top-24 z-20 place-items-end text-lg text-gray-900">
          <FilterDropDown
            onSubmit={handleFilterQuoteSubmit}
            afterCreatedDateLabel="Created depuis"
            beforeCsReminderDateLabel="Relance CS jusqu’à"
            afterCsReminderDateLabel="Relance CS depuis"
            customerLabel="Customer"
            phoneNumberLabel="Téléphone"
            postCodeLabel="Code postal"
            storeLabel="Store"
            retailerLabel="Retailer"
          />
        </div>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>date de création</Table.HeadCell>
            <Table.HeadCell>date de relance CS</Table.HeadCell>
            <Table.HeadCell>offre</Table.HeadCell>
            <Table.HeadCell>expert</Table.HeadCell>
            <Table.HeadCell>code postal</Table.HeadCell>
            <Table.HeadCell>nom du client</Table.HeadCell>
            <Table.HeadCell>téléphone du client</Table.HeadCell>
            <Table.HeadCell>retailer</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {draftQuoteRequestsQueryResult.quoteRequestsByQuoteOfferStatus?.quoteRequests?.map(
            (quoteRequest, i) => (
              <QuoteRequestRow
                key={quoteRequest.id}
                quoteRequest={quoteRequest}
                odd={i % 2 === 0}
                to={getQuoteRequestPageLocation(quoteRequest)}
              />
            )
          )}
        </Table.Body>
      </Table>{" "}
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={
          draftQuoteRequestsQueryResult.quoteRequestsByQuoteOfferStatus
            .totalCount
        }
        getPageLocation={(page) => (location) => {
          const search = new URLSearchParams(location.search);
          search.set("page", page.toString());

          return {
            ...location,
            search: search.toString(),
          };
        }}
      />
    </div>
  );
};

const DraftQuoteRequestsTab: React.FC<{
  getQuoteRequestPageLocation: React.ComponentProps<
    typeof _DraftQuoteRequestsTab
  >["getQuoteRequestPageLocation"];
}> = ({ getQuoteRequestPageLocation }) => {
  const [afterCreatedDateQuery] = useQueryParam(
    "after_created_date",
    withDefault(StringParam, null)
  );
  const [beforeCsReminderDateQuery] = useQueryParam(
    "before_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [afterCsReminderDateQuery] = useQueryParam(
    "after_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [customerQuery] = useQueryParam(
    "customer",
    withDefault(StringParam, null)
  );
  const [phoneNumberQuery] = useQueryParam(
    "phone_number",
    withDefault(StringParam, null)
  );
  const [postCodeQuery] = useQueryParam(
    "post_code",
    withDefault(StringParam, null)
  );
  const [storeQuery] = useQueryParam("store", withDefault(StringParam, null));
  const [retailerQuery] = useQueryParam(
    "retailer",
    withDefault(StringParam, null)
  );
  const pageSize = 50;
  const [currentPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const offset = (currentPage - 1) * pageSize;
  const queryResult = useQuery<
    DraftQuoteRequestsTabQuery,
    DraftQuoteRequestsTabQueryVariables
  >(QUERY, {
    variables: {
      limit: pageSize,
      offset,
      quoteRequestStatus: "DRAFT",
      quoteRequestsQueryParametersWhere: {
        createdAfter: afterCreatedDateQuery,
        csReminderBefore: beforeCsReminderDateQuery,
        csReminderAfter: afterCsReminderDateQuery,
        customer: customerQuery,
        phoneNumber: phoneNumberQuery,
        postCode: postCodeQuery,
        store: storeQuery,
        retailer: retailerQuery,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const handleFilterQuoteSubmit = useCallback(
    ({
      afterCreatedDate,
      beforeCsReminderDate,
      afterCsReminderDate,
      customer,
      phoneNumber,
      postCode,
      store,
      retailer,
    }: {
      afterCreatedDate?: string | null;
      beforeCsReminderDate?: string | null;
      afterCsReminderDate?: string | null;
      customer?: string | null;
      phoneNumber?: string | null;
      postCode?: string | null;
      store?: string | null;
      retailer?: string | null;
    }) => {
      queryResult.refetch({
        quoteRequestsQueryParametersWhere: {
          createdAfter: afterCreatedDate,
          csReminderBefore: beforeCsReminderDate,
          csReminderAfter: afterCsReminderDate,
          customer,
          phoneNumber,
          postCode,
          store,
          retailer,
        },
      });
    },
    [queryResult]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <_DraftQuoteRequestsTab
      currentPage={currentPage}
      pageSize={pageSize}
      draftQuoteRequestsQueryResult={queryResult.data}
      getQuoteRequestPageLocation={getQuoteRequestPageLocation}
      handleFilterQuoteSubmit={handleFilterQuoteSubmit}
    />
  );
};

export default DraftQuoteRequestsTab;
