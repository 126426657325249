import classNames from "classnames";
import React from "react";
import { NavLink } from "react-router-dom";

const Tab: React.FC<React.ComponentProps<typeof NavLink>> = ({
  activeClassName,
  className,
  ...props
}) => (
  <NavLink
    activeClassName={classNames(
      "!border-blue-500 !text-blue-600",
      activeClassName
    )}
    className={classNames(
      className,
      "whitespace-nowrap border-b-2 border-transparent px-1 pb-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
    )}
    {...props}
  />
);

const TabBar: React.FC<
  React.ComponentProps<"div"> & {
    title?: React.ReactNode;
  }
> = ({ title, className, children }) => (
  <div className={classNames("border-b border-gray-200", className)}>
    <div className="relative sm:flex sm:items-baseline">
      <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
      <div className="mt-4 sm:mt-0 sm:ml-10">
        <nav className="-mb-px flex space-x-8">{children}</nav>
      </div>
    </div>
  </div>
);

TabBar.displayName = "TabBar";

export default Object.assign(TabBar, { Tab });
