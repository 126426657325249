import { gql, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import React, { Fragment, useCallback } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import * as yup from "yup";
import {
  AllUsersTabFragment,
  ModifyUserModalFragment,
  ModifyUserModalMutation,
  ModifyUserModalMutationVariables,
  User_SecurityGroup,
} from "../../graphql-operations-types";
import Button from "../atoms/button";
import InputGroup from "../atoms/input-group";
import Select from "../atoms/select";

const MODIFY_USER_FRAGMENT = gql`
  fragment ModifyUserModal on BackOfficeUser {
    id
    firstName
    lastName
    securityGroup
    emailAddress
  }
`;

const MODIFY_USER_MUTATION = gql`
  ${MODIFY_USER_FRAGMENT}

  mutation ModifyUserModal(
    $id: String!
    $contactFirstName: String!
    $contactLastName: String!
    $contactEmailAddress: EmailAddress!
    $contactSecurityGroup: User_SecurityGroup!
  ) {
    modifyUser(
      id: $id
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      contactEmailAddress: $contactEmailAddress
      contactSecurityGroup: $contactSecurityGroup
    ) {
      ... on ModifyUser_Failure {
        reason
      }
      ... on ModifyUser_Success {
        user {
          __typename
          ...ModifyUserModal
        }
      }
    }
  }
`;

type FormInputs = {
  contactFirstName: string;
  contactLastName: string;
  contactEmailAddress: string;
  contactSecurityGroup: User_SecurityGroup;
};

type Choice = {
  value: User_SecurityGroup;
  label: string;
};

const choices: Choice[] = [
  {
    value: "ADMIN",
    label: "Admin",
  },
  {
    value: "EDITOR",
    label: "Editor",
  },
  {
    value: "VIEWER",
    label: "Viewer",
  },
];

const schema = yup.object().shape({
  contactFirstName: yup.string().required("Le prénom est requis"),
  contactLastName: yup.string().required("Le nom est requis"),
  contactEmailAddress: yup
    .string()
    .email("L'adresse email n'est pas valide")
    .required("L'adresse email est requise"),
  contactSecurityGroup: yup
    .string()
    .oneOf(["ADMIN", "EDITOR", "VIEWER"], "veuillez selectionner un rôle")
    .required(),
});

const ModifyUserModal: React.FC<{
  open?: boolean;
  onRequestClose?: () => void;
  onSuccess?: (mission: ModifyUserModalFragment) => void;
  user: AllUsersTabFragment;
}> = ({ open = false, onRequestClose = noop, onSuccess, user }) => {
  const [triggerModifyUserMutation] = useMutation<
    ModifyUserModalMutation,
    ModifyUserModalMutationVariables
  >(MODIFY_USER_MUTATION);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    async ({
      contactFirstName,
      contactLastName,
      contactEmailAddress,
      contactSecurityGroup,
    }) => {
      const result = await triggerModifyUserMutation({
        variables: {
          id: user.id,
          contactFirstName,
          contactLastName,
          contactEmailAddress,
          contactSecurityGroup,
        },
      });

      if (result.data?.modifyUser.__typename === "ModifyUser_Failure") {
        switch (result.data.modifyUser.reason) {
          case "INVALID_EMAIL_ADDRESS":
            setError("contactEmailAddress", {
              message: "Addresse mail invalide",
            });
            break;
          case "EMAIL_ADDRESS_ALREADY_EXIST":
            setError("contactEmailAddress", {
              message: "Addresse mail déjà utilisée",
            });
            break;
          case "USER_DOES_NOT_EXIST":
            setError("contactSecurityGroup", {
              message: "L'utilisateur n'existe pas",
            });
            break;
        }
      }

      if (
        onSuccess &&
        result.data?.modifyUser.__typename === "ModifyUser_Success"
      ) {
        onSuccess(result.data?.modifyUser.user);
      }
    },
    [onSuccess, setError, triggerModifyUserMutation, user.id]
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onRequestClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
              <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Modifier un utilisateur
                      </Dialog.Title>
                      <div className="mt-4 space-y-3">
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactFirstName">
                              <InputGroup.RequiredAsterisk /> Prénom
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactFirstName?.message && (
                              <InputGroup.Error>
                                {errors.contactFirstName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactFirstName"
                              readOnly={isSubmitting}
                              defaultValue={user.firstName}
                              {...register("contactFirstName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactLastName">
                              <InputGroup.RequiredAsterisk /> Nom
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactLastName?.message && (
                              <InputGroup.Error>
                                {errors.contactLastName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactLastName"
                              readOnly={isSubmitting}
                              defaultValue={user.lastName}
                              {...register("contactLastName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactEmailAddress">
                              <InputGroup.RequiredAsterisk /> Adresse mail
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactEmailAddress?.message && (
                              <InputGroup.Error>
                                {errors.contactEmailAddress.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="off"
                              autoCorrect="off"
                              id="contactEmailAddress"
                              readOnly={isSubmitting}
                              defaultValue={user.emailAddress}
                              {...register("contactEmailAddress")}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <InputGroup.Label
                    className="mt-4"
                    htmlFor="contactSecurityGroup"
                  >
                    <InputGroup.RequiredAsterisk /> Groupe de sécurité
                  </InputGroup.Label>
                  <Select
                    {...register("contactSecurityGroup")}
                    className="w-full"
                    disabled={isSubmitting}
                    defaultValue={user.securityGroup}
                  >
                    {choices.map((choice) => (
                      <option key={choice.value} value={choice.value}>
                        {choice.label}
                      </option>
                    ))}
                  </Select>
                  {errors.contactSecurityGroup?.message && (
                    <InputGroup.Error>
                      {errors.contactSecurityGroup.message}
                    </InputGroup.Error>
                  )}
                </div>
                <div className="flex justify-end bg-gray-50 px-4 py-3 sm:px-6">
                  <Button
                    type="submit"
                    className="inline-flex w-full justify-center sm:w-auto"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Valider
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModifyUserModal;
