import React from "react";

type MinusProps = {
  className?: string;
  color?: string;
};
export const Minus = ({ className, color }: MinusProps) => {
  return (
    <svg
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={`
        fill: #0069ff;
        height: 20px;
        width: 20px;
      `}
    >
      <rect
        x="9.91675"
        y="15.5833"
        width="14.1667"
        height="2.83333"
        rx="1.41667"
        fill={color ?? "#0069ff"}
      />
    </svg>
  );
};

type PlusProps = {
  className?: string;
  color?: string;
};
export const Plus = ({ className, color }: PlusProps) => {
  return (
    <svg
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={`
        fill: #0069ff;
        height: 20px;
        width: 20px;
      `}
      fill={color ?? "#0069ff"}
    >
      <path d="M18.5938 15.4062H24.0466C24.8493 15.4062 25.5 16.1198 25.5 17C25.5 17.8802 24.8493 18.5938 24.0466 18.5938H18.5938V24.0466C18.5938 24.8493 17.8802 25.5 17 25.5C16.1198 25.5 15.4062 24.8493 15.4062 24.0466V18.5938H9.95337C9.1507 18.5938 8.5 17.8802 8.5 17C8.5 16.1198 9.1507 15.4062 9.95337 15.4062H15.4062V9.95337C15.4062 9.1507 16.1198 8.5 17 8.5C17.8802 8.5 18.5938 9.1507 18.5938 9.95337V15.4062Z" />
    </svg>
  );
};
