import { ExclamationIcon } from "@heroicons/react/outline";
import React from "react";
import Button from "../atoms/button";
import Alert from "../molecules/alert";

const RelaunchTimerAlert: React.FC<{
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ show, onClose, onSubmit }) => {
  return (
    <Alert
      show={show}
      onClose={onClose}
      icon={
        <Alert.Icon className="bg-yellow-100">
          <ExclamationIcon
            className="h-8 w-8 text-yellow-600"
            aria-hidden="true"
          />
        </Alert.Icon>
      }
      title={<Alert.Title>Voulez-vous vraiment relancer le temps?</Alert.Title>}
      footer={
        <Alert.Footer className="gap-2">
          <Button
            type="button"
            onClick={() => {
              onSubmit();
            }}
            className="bg-green-500 focus:ring-green-500 hover:bg-green-700"
          >
            Relancer le temps
          </Button>
          <Button type="button" onClick={onClose}>
            Annuler
          </Button>
        </Alert.Footer>
      }
    />
  );
};

export default RelaunchTimerAlert;
