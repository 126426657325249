import React, { useContext, useState, useCallback, useMemo } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  XIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { nanoid } from "nanoid";

type ToastProps = {
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
};
const Toast = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div"> & ToastProps
>(({ className, title, body, icon, onCloseClick, ...props }, ref) => (
  <div
    ref={ref}
    className={classNames(
      "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5",
      className
    )}
    {...props}
  >
    <div className="p-4">
      <div className="flex items-start">
        {icon && <div className="shrink-0">{icon}</div>}
        <div className="ml-3 w-0 flex-1 pt-0.5">
          {title && (
            <p className="text-sm font-medium text-gray-900">{title}</p>
          )}
          {body && <p className="mt-1 text-sm text-gray-500">{body}</p>}
        </div>
        {onCloseClick && (
          <div className="ml-4 flex shrink-0">
            <button
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={onCloseClick}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
));

Toast.displayName = "Toast";

const SuccessToast: React.FC<React.ComponentProps<typeof Toast>> = ({
  title = "Succès",
  ...props
}) => (
  <Toast
    className="bg-white"
    icon={
      <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
    }
    title={title}
    {...props}
  />
);

const WarningToast: React.FC<React.ComponentProps<typeof Toast>> = ({
  title = "Attention",
  ...props
}) => (
  <Toast
    className="bg-white"
    icon={
      <ExclamationIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
    }
    title={title}
    {...props}
  />
);

const ErrorToast: React.FC<React.ComponentProps<typeof Toast>> = ({
  title = "Erreur",
  ...props
}) => (
  <Toast
    className="bg-white"
    icon={
      <ExclamationCircleIcon
        className="h-6 w-6 text-red-400"
        aria-hidden="true"
      />
    }
    title={title}
    {...props}
  />
);

const _Toaster: React.FC<React.ComponentProps<"div">> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      aria-live="assertive"
      className={classNames(
        "pointer-events-none fixed inset-0 z-30 flex items-end px-4 py-6 sm:items-start sm:p-6",
        className
      )}
      {...props}
    >
      <div className="w-full">
        <Transition show appear className="w-full space-y-4">
          {React.Children.map(children, (child) => (
            <Transition.Child
              enter="ease-out duration-300 transition"
              enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="flex w-full flex-col items-center sm:items-end"
            >
              {child}
            </Transition.Child>
          ))}
        </Transition>
      </div>
    </div>
  );
};

type ToastRenderer = (props: { onClose: () => void }) => React.ReactElement;

type IToasterContext = {
  push: (render: ToastRenderer) => void;
};
const ToasterContext = React.createContext<IToasterContext | null>(null);

const ToasterProvider: React.FC = ({ children }) => {
  const [toastsRenderers, setToastsRenderers] = useState<
    Array<{ id: string; renderer: ToastRenderer }>
  >([]);

  const handleToastClose = useCallback(
    (id: string) => () =>
      setToastsRenderers((renderers) =>
        renderers.filter((renderer) => renderer.id !== id)
      ),
    []
  );

  const contextValue = useMemo<IToasterContext>(
    () => ({
      push: (renderer) =>
        setToastsRenderers((renderers) => [
          ...renderers,
          { id: nanoid(), renderer },
        ]),
    }),
    []
  );

  return (
    <>
      <Toaster>
        <div className="hidden" />
        {toastsRenderers.map(({ renderer, id }) => (
          <React.Fragment key={id}>
            {renderer({ onClose: handleToastClose(id) })}
          </React.Fragment>
        ))}
      </Toaster>
      <ToasterContext.Provider value={contextValue}>
        {children}
      </ToasterContext.Provider>
    </>
  );
};

const useToaster = () => {
  const toasterContext = useContext(ToasterContext);

  if (!toasterContext) {
    throw new Error("ToasterProvider not found in tree");
  }

  return { push: toasterContext.push };
};

const Toaster = Object.assign(_Toaster, {
  Toast,
  SuccessToast,
  WarningToast,
  ErrorToast,
  useToaster,
  ToasterProvider,
});

export default Toaster;
