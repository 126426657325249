import React, { useCallback } from "react";
import Input from "../../atoms/input";
import { QuoteRequestFormOfferQuestionsFragment } from "../../../graphql-operations-types";

type _TextInputQuestionProps = {
  onChange: (value: string) => void;
  error?: string | undefined;
  answerValue?: string[];
};
const _TextQuestionInput = ({
  onChange,
  error,
  answerValue,
}: _TextInputQuestionProps) => {
  return (
    <>
      <div
        className={`mt-2 ${error && "border-2 border-red-600 rounded-md py-2"}`}
      >
        <Input
          className="w-full"
          onChange={(event) => {
            onChange(event.currentTarget.value);
          }}
          defaultValue={answerValue ? answerValue[0] : ""}
        />
      </div>
      {error && <div className="mt-2 text-sm text-red-600 flex">{error}</div>}
    </>
  );
};

type Props = {
  question: QuoteRequestFormOfferQuestionsFragment;
  onChange: (reference: string, value: string, typename?: string) => void;
  error?: string | undefined;
  value?: Map<string, string[]>;
};
const TextQuestionInput = ({ question, onChange, error, value }: Props) => {
  const handleQuestionAnswerChange = useCallback(
    (value: string) => {
      onChange(question.reference, value, question.__typename);
    },
    [onChange, question.reference, question.__typename]
  );

  return (
    <_TextQuestionInput
      onChange={handleQuestionAnswerChange}
      error={error}
      answerValue={value?.get(question.reference)}
    />
  );
};

export default TextQuestionInput;
