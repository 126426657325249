import classNames from "classnames";
import React from "react";

const Button = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<"button"> & { loading?: boolean }
>(function Button({ className, loading, ...props }, ref) {
  return (
    <button
      ref={ref}
      className={classNames(
        "flex justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-600",
        {
          "animate-pulse": loading,
        },
        className
      )}
      {...props}
    />
  );
});

export default Button;
