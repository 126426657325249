import { gql, useApolloClient, useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { noop } from "lodash";
import React, {
  Fragment,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import * as yup from "yup";
import {
  SubmitMissionModalOfferFragment,
  SubmitMissionModalMissionFragment,
  SubmitMissionModalSearchOffersQuery,
  SubmitMissionModalSearchOffersQueryVariables,
  SubmitMissionModalSearchStoresQuery,
  SubmitMissionModalSearchStoresQueryVariables,
  SubmitMissionModalStoreFragment,
  SubmitMissionModalSubmitMissionMutation,
  SubmitMissionModalSubmitMissionMutationVariables,
  SubmitMissionModalSearchStoreClerksQuery,
  SubmitMissionModalSearchStoreClerksQueryVariables,
  SubmitMissionModalStoreClerkFragment,
  MissionFormOfferQuestionsFragment,
  MissionFormOfferQuery,
  MissionFormOfferQueryVariables,
} from "../../graphql-operations-types";
import Button from "../atoms/button";
import InputGroup from "../atoms/input-group";
import MultipleChoiceQuestion from "../molecules/questions/multiple-choice-question-input";
import NumberQuestion from "../molecules/questions/number-question-input";
import {
  NUMBER_QUESTION_FRAGMENT,
  MULTIPLE_CHOICE_FRAGMENT,
  TEXT_QUESTION_FRAGMENT,
  MULTIPLE_CHOICES_FRAGMENT,
} from "./submit-quote-request-modal";

const MISSION_FRAGMENT = gql`
  fragment SubmitMissionModalMission on Mission {
    __typename
    id
  }
`;

const SEARCH_STORES = gql`
  fragment SubmitMissionModalStore on Store {
    id
    name
    retailer {
      id
      name
    }
  }

  query SubmitMissionModalSearchStores($limit: Int, $search: String) {
    stores(limit: $limit, search: $search) {
      ...SubmitMissionModalStore
    }
  }
`;

const SEARCH_STORE_CLERKS = gql`
  fragment SubmitMissionModalStoreClerk on StoreClerk {
    id
    displayName
  }

  query SubmitMissionModalSearchStoreClerks(
    $limit: Int
    $storeId: String!
    $search: String
  ) {
    storeClerks(limit: $limit, storeId: $storeId, search: $search) {
      ...SubmitMissionModalStoreClerk
    }
  }
`;

const SEARCH_OFFERS = gql`
  fragment SubmitMissionModalOffer on Offer {
    id
    name
    grossPrice
  }

  query SubmitMissionModalSearchOffers(
    $limit: Int
    $search: String
    $storeId: String!
  ) {
    offers(
      limit: $limit
      search: $search
      storeId: $storeId
      isComplex: false
    ) {
      ...SubmitMissionModalOffer
    }
  }
`;

const SUBMIT_MISSION_MUTATION = gql`
  ${MISSION_FRAGMENT}

  mutation SubmitMissionModalSubmitMission(
    $offerId: UUID!
    $storeId: UUID!
    $storeClerkId: UUID
    $contactEmailAddress: EmailAddress!
    $contactPhoneNumber: PhoneNumber!
    $contactFirstName: String!
    $contactLastName: String!
    $details: String
    $address: SubmitMission_Address!
    $date: DateTime
    $platformFeesRate: Float
    $storeClerkCommissionRate: Float
    $whiteLabelFeesRate: Float
    $purchaseOrder: String
    $productPriceTtc: String
    $questionsAnswers: [MissionQuestionsAnswersInput!]
  ) {
    submitMission(
      offerId: $offerId
      storeId: $storeId
      contactEmailAddress: $contactEmailAddress
      contactPhoneNumber: $contactPhoneNumber
      contactFirstName: $contactFirstName
      contactLastName: $contactLastName
      address: $address
      details: $details
      storeClerkId: $storeClerkId
      date: $date
      platformFeesRate: $platformFeesRate
      storeClerkCommissionRate: $storeClerkCommissionRate
      whiteLabelFeesRate: $whiteLabelFeesRate
      purchaseOrder: $purchaseOrder
      productPriceTtc: $productPriceTtc
      questionsAnswers: $questionsAnswers
    ) {
      ... on SubmitMission_Failure {
        reason
      }
      ... on SubmitMission_Success {
        mission {
          __typename
          ...SubmitMissionModalMission
        }
      }
    }
  }
`;

const MISSION_FORM_OFFER_QUESTIONS_FRAGMENT = gql`
  ${NUMBER_QUESTION_FRAGMENT}
  ${MULTIPLE_CHOICE_FRAGMENT}
  ${MULTIPLE_CHOICES_FRAGMENT}
  ${TEXT_QUESTION_FRAGMENT}

  fragment MissionFormOfferQuestions on Question {
    ... on TextQuestion {
      ...TextQuestion
    }
    ... on NumberQuestion {
      ...NumberQuestion
    }
    ... on MultipleChoiceQuestion {
      ...MultipleChoiceQuestion
    }
    ... on MultipleChoicesQuestion {
      ...MultipleChoicesQuestion
    }
  }
`;

const MISSION_FORM_OFFER_QUERY = gql`
  ${MISSION_FORM_OFFER_QUESTIONS_FRAGMENT}
  query MissionFormOffer($offerId: String!, $storeId: String!) {
    offer(offerId: $offerId, storeId: $storeId) {
      id
      isDateMandatory
      questions {
        ...MissionFormOfferQuestions
      }
    }
  }
`;

type QuestionProps = {
  question: MissionFormOfferQuestionsFragment;
  onChange: (reference: string, value: string, typename?: string) => void;
  errors: Map<string, string>;
};

const Question = ({ question, onChange, errors }: QuestionProps) => {
  switch (question.__typename) {
    case "MultipleChoiceQuestion":
      return (
        <MultipleChoiceQuestion
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    case "NumberQuestion":
      return (
        <NumberQuestion
          question={question}
          onChange={onChange}
          error={errors.get(question.reference)}
        />
      );
    default:
      return null;
  }
};

type FormInputs = {
  contactFirstName: string;
  contactLastName: string;
  contactPhoneNumber: string;
  contactEmailAddress: string;
  zipCode: string;
  city: string;
  street1: string;
  details: string;
  offer: SubmitMissionModalOfferFragment;
  store: SubmitMissionModalStoreFragment;
  storeClerk: SubmitMissionModalStoreClerkFragment;
  purchaseOrder: string;
  productPriceTtc: string;
};

const schema = yup.object().shape({
  contactFirstName: yup.string().required("Le prénom du client est requis"),
  contactLastName: yup.string().required("Le nom du client est requis"),
  contactPhoneNumber: yup
    .string()
    .required("Le numéro de téléphone du client est requis"),
  contactEmailAddress: yup
    .string()
    .email("L'adresse email du client n'est pas valide")
    .required("L'adresse email du client est requise"),
  zipCode: yup.string().required("Le code postal est requis"),
  city: yup.string().required("La ville est requise"),
  street1: yup.string().required("L'addresse est requise"),
  details: yup.string(),
  offer: yup.object().required("L'offre est requise"),
  store: yup.object().required("Le magasin est requis"),
  storeClerk: yup.object(),
});

const SubmitMissionModal: React.FC<{
  open?: boolean;
  onRequestClose?: () => void;
  onSuccess?: (mission: SubmitMissionModalMissionFragment) => void;
}> = ({ open = false, onRequestClose = noop, onSuccess }) => {
  const [triggerSubmitMissionMutation] = useMutation<
    SubmitMissionModalSubmitMissionMutation,
    SubmitMissionModalSubmitMissionMutationVariables
  >(SUBMIT_MISSION_MUTATION);

  const apolloClient = useApolloClient();

  const {
    register,
    handleSubmit,
    control,
    setError,
    trigger,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [storeId, setStoreId] = useState<string>("");
  const [date, setDate] = useState<string | undefined>();
  const [offer, setOffer] = useState<MissionFormOfferQuery["offer"] | null>(
    null
  );
  const [offerId, setOfferId] = useState<string>("");
  const [offerQuestions, setOfferQuestions] = useState<
    readonly MissionFormOfferQuestionsFragment[]
  >([]);
  const [formHasBeenAlreadyChecked, setFormHasBeenAlreadyChecked] =
    useState(false);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadStoreSelectOptions = useCallback(
    async (inputValue: string) => {
      const pointsOfSaleQueryResult = await apolloClient.query<
        SubmitMissionModalSearchStoresQuery,
        SubmitMissionModalSearchStoresQueryVariables
      >({
        query: SEARCH_STORES,
        variables: { limit: 10, search: inputValue },
      });

      return pointsOfSaleQueryResult.data.stores ?? [];
    },
    [apolloClient]
  );

  const loadStoreClerkSelectOptions = useCallback(
    async (inputValue: string) => {
      const storeClerksQueryResult = await apolloClient.query<
        SubmitMissionModalSearchStoreClerksQuery,
        SubmitMissionModalSearchStoreClerksQueryVariables
      >({
        query: SEARCH_STORE_CLERKS,
        variables: {
          limit: 10,
          storeId,
          search: inputValue != "" ? inputValue : undefined,
        },
      });

      return storeClerksQueryResult.data.storeClerks ?? [];
    },
    [apolloClient, storeId]
  );

  const loadOfferOptions = useCallback(
    async (inputValue: string) => {
      const offersQueryResult = await apolloClient.query<
        SubmitMissionModalSearchOffersQuery,
        SubmitMissionModalSearchOffersQueryVariables
      >({
        query: SEARCH_OFFERS,
        variables: { limit: 10, search: inputValue, storeId },
      });

      return offersQueryResult.data.offers ?? [];
    },
    [apolloClient, storeId]
  );

  const loadOfferQuestions = useCallback(async () => {
    const offerQuestionsQueryResult = await apolloClient.query<
      MissionFormOfferQuery,
      MissionFormOfferQueryVariables
    >({
      query: MISSION_FORM_OFFER_QUERY,
      variables: { storeId, offerId },
    });
    return offerQuestionsQueryResult.data.offer;
  }, [apolloClient, offerId, storeId]);

  const questionsAnswers = useMemo(() => {
    const answers = new Map<string, string>();
    offerQuestions?.forEach((question) => {
      if (question.__typename === "NumberQuestion") {
        answers.set(
          question.reference,
          question.defaultValue ? question.defaultValue.toString() : "0"
        );
      }
    });
    return answers;
  }, [offerQuestions]);
  const questionsAnswersErrors = useMemo(() => {
    return new Map<string, string>();
  }, []);
  const setAnswersErrors = useCallback((): void => {
    for (const question of offerQuestions) {
      if (question.__typename !== "NumberQuestion") {
        const answer = questionsAnswers.get(question.reference);
        if (!answer) {
          questionsAnswersErrors.set(question.reference, "Champ requis");
        } else {
          questionsAnswersErrors.delete(question.reference);
        }
      }
    }

    if (formHasBeenAlreadyChecked) {
      setForceRefresh((state) => !state);
    }
  }, [
    formHasBeenAlreadyChecked,
    offerQuestions,
    questionsAnswers,
    questionsAnswersErrors,
  ]);

  const onQuestionAnswerChange = useCallback(
    (reference: string, value: string, typeName?: string) => {
      if (
        (typeName && typeName === "MultipleChoicesQuestion") ||
        typeName === "TextQuestion"
      ) {
        return;
      } else {
        questionsAnswers.set(reference, value);
      }

      setAnswersErrors();
    },
    [questionsAnswers, setAnswersErrors]
  );

  const validateAndSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setAnswersErrors();
    trigger();
    setFormHasBeenAlreadyChecked(true);
    if (questionsAnswersErrors.size === 0 && isValid) {
      handleSubmit(onSubmit(getValues()));
    }
  };

  const onSubmit: SubmitHandler<FormInputs> = useCallback(
    async ({
      contactFirstName,
      contactLastName,
      contactPhoneNumber,
      contactEmailAddress,
      zipCode,
      city,
      street1,
      details,
      offer,
      store,
      purchaseOrder,
      storeClerk,
      productPriceTtc,
    }) => {
      setIsSubmitting(true);
      const arrayOfQuestionsAnswers = Array.from(
        questionsAnswers,
        ([reference, value]) => ({ reference, value })
      );
      const result = await triggerSubmitMissionMutation({
        variables: {
          contactFirstName,
          contactLastName,
          contactPhoneNumber,
          contactEmailAddress,
          address: {
            country: "France",
            zipCode,
            city,
            street1,
          },
          details,
          offerId: offer.id,
          storeId: store.id,
          purchaseOrder,
          storeClerkId: storeClerk ? storeClerk.id : null,
          productPriceTtc,
          questionsAnswers:
            arrayOfQuestionsAnswers.length > 0 ? arrayOfQuestionsAnswers : null,
          date: date ? new Date(date).toISOString() : null,
        },
      });

      if (result.data?.submitMission.__typename === "SubmitMission_Failure") {
        switch (result.data.submitMission.reason) {
          case "INVALID_ADDRESS":
            setError("zipCode", { message: "Addresse invalide" });
            setError("street1", { message: "Addresse invalide" });
            setError("city", { message: "Addresse invalide" });
            break;
        }
        setIsSubmitting(false);
      }

      if (
        onSuccess &&
        result.data?.submitMission.__typename === "SubmitMission_Success"
      ) {
        onSuccess(result.data?.submitMission.mission);
        setIsSubmitting(false);
      }
    },
    [date, onSuccess, questionsAnswers, setError, triggerSubmitMissionMutation]
  );

  useEffect(() => {
    const fetchQuestions = async () => {
      const offer = await loadOfferQuestions();

      if (offer) {
        setOffer(offer);
        setOfferQuestions(offer.questions);
      }
    };
    if (offerId) fetchQuestions();
  }, [loadOfferQuestions, offerId]);

  useEffect(() => {
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    if (formHasBeenAlreadyChecked) {
      if (offer?.isDateMandatory === "MANDATORY" && !date) {
        questionsAnswersErrors.set("date", "Date requise");
      } else questionsAnswersErrors.delete("date");
      setForceRefresh((state) => !state);
    }
  }, [
    date,
    formHasBeenAlreadyChecked,
    offer?.isDateMandatory,
    questionsAnswersErrors,
  ]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onRequestClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block transform rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:align-middle">
              <form autoComplete="on" onSubmit={validateAndSubmit}>
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 w-full sm:mt-0">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Soumettre une prestation sans paiement
                      </Dialog.Title>
                      <div className="mt-4 space-y-3">
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactFirstName">
                              <InputGroup.RequiredAsterisk /> Prénom du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactFirstName?.message && (
                              <InputGroup.Error>
                                {errors.contactFirstName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactFirstName"
                              readOnly={isSubmitting}
                              {...register("contactFirstName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactLastName">
                              <InputGroup.RequiredAsterisk /> Nom du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactLastName?.message && (
                              <InputGroup.Error>
                                {errors.contactLastName.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactLastName"
                              readOnly={isSubmitting}
                              {...register("contactLastName")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactPhoneNumber">
                              <InputGroup.RequiredAsterisk /> Numéro de
                              téléphone du client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactPhoneNumber?.message && (
                              <InputGroup.Error>
                                {errors.contactPhoneNumber.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="contactPhoneNumber"
                              control={control}
                              render={({ field }) => (
                                <PhoneInput
                                  inputComponent={InputGroup.Input}
                                  defaultCountry="FR"
                                  readOnly={isSubmitting}
                                  {...field}
                                />
                              )}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="contactEmailAddress">
                              <InputGroup.RequiredAsterisk /> Adresse mail du
                              client
                            </InputGroup.Label>
                          }
                          error={
                            errors.contactEmailAddress?.message && (
                              <InputGroup.Error>
                                {errors.contactEmailAddress.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              type="email"
                              autoComplete="on"
                              autoCorrect="off"
                              id="contactEmailAddress"
                              readOnly={isSubmitting}
                              {...register("contactEmailAddress")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="street1">
                              <InputGroup.RequiredAsterisk /> Adresse
                              d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.street1?.message && (
                              <InputGroup.Error>
                                {errors.street1.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="street1"
                              readOnly={isSubmitting}
                              {...register("street1")}
                            />
                          }
                        />
                        <InputGroup
                          className="block w-full"
                          label={
                            <InputGroup.Label htmlFor="zipCode">
                              <InputGroup.RequiredAsterisk /> Code postal
                              d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.zipCode?.message && (
                              <InputGroup.Error>
                                {errors.zipCode.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="zipCode"
                              readOnly={isSubmitting}
                              {...register("zipCode")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="city">
                              <InputGroup.RequiredAsterisk /> Ville
                              d&apos;intervention
                            </InputGroup.Label>
                          }
                          error={
                            errors.city?.message && (
                              <InputGroup.Error>
                                {errors.city.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="city"
                              readOnly={isSubmitting}
                              {...register("city")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="store">
                              <InputGroup.RequiredAsterisk /> Magasin
                            </InputGroup.Label>
                          }
                          error={
                            (errors.store as any)?.message && (
                              <InputGroup.Error>
                                {(errors.store as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="store"
                              control={control}
                              render={({ field }) => (
                                <AsyncSelect<SubmitMissionModalStoreFragment>
                                  loadOptions={loadStoreSelectOptions}
                                  defaultOptions
                                  styles={{
                                    input: (base) => ({
                                      ...base,
                                      "input:focus": {
                                        boxShadow: "none",
                                      },
                                    }),
                                  }}
                                  formatOptionLabel={(store) => (
                                    <>
                                      {store.retailer.name} | {store.name}
                                    </>
                                  )}
                                  cacheOptions
                                  isDisabled={isSubmitting}
                                  getOptionValue={(store) => store.id}
                                  {...field}
                                  onChange={(value) => {
                                    if (value) setStoreId(value.id);
                                    field.onChange(value);
                                  }}
                                />
                              )}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="storeClerk">
                              Vendeur
                            </InputGroup.Label>
                          }
                          error={
                            (errors.storeClerk as any)?.message && (
                              <InputGroup.Error>
                                {(errors.storeClerk as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="storeClerk"
                              control={control}
                              render={({ field }) =>
                                storeId ? (
                                  <AsyncSelect<SubmitMissionModalStoreClerkFragment>
                                    loadOptions={loadStoreClerkSelectOptions}
                                    styles={{
                                      input: (base) => ({
                                        ...base,
                                        "input:focus": {
                                          boxShadow: "none",
                                        },
                                      }),
                                    }}
                                    defaultOptions
                                    formatOptionLabel={(storeClerk) => (
                                      <>{storeClerk.displayName}</>
                                    )}
                                    cacheOptions
                                    getOptionValue={(storeClerk) =>
                                      storeClerk.id
                                    }
                                    isDisabled={isSubmitting}
                                    {...field}
                                  />
                                ) : (
                                  <Select<SubmitMissionModalOfferFragment>
                                    isDisabled={!storeId}
                                  />
                                )
                              }
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="offerId">
                              <InputGroup.RequiredAsterisk /> Offre
                            </InputGroup.Label>
                          }
                          error={
                            (errors.offer as any)?.message && (
                              <InputGroup.Error>
                                {(errors.offer as any).message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <Controller
                              name="offer"
                              control={control}
                              /**
                               * this is the part you will have to delete if you
                               * reactivate the offers by store feature
                               */
                              render={({ field }) => (
                                <AsyncSelect<SubmitMissionModalOfferFragment>
                                  loadOptions={loadOfferOptions}
                                  styles={{
                                    input: (base) => ({
                                      ...base,
                                      "input:focus": {
                                        boxShadow: "none",
                                      },
                                    }),
                                  }}
                                  defaultOptions
                                  formatOptionLabel={(offer) => (
                                    <>
                                      {offer.name} |{" "}
                                      {offer.grossPrice
                                        ? offer.grossPrice
                                        : "Offer non compatible"}
                                    </>
                                  )}
                                  cacheOptions
                                  getOptionValue={(offer) => offer.id}
                                  isDisabled={isSubmitting}
                                  {...field}
                                  onChange={(value) => {
                                    if (value) setOfferId(value.id);
                                    field.onChange(value);
                                  }}
                                />
                              )}
                              /**
                               * this commented part is about filter the offers by store
                               * if you want to reactivate this feature please uncomment this part and the
                               * backend code in the query server/src/application/graphql/back-office-api/types/queries/offers.ts
                               */
                              // render={({ field }) =>
                              //   storeId ? (
                              //     <AsyncSelect<SubmitMissionModalOfferFragment>
                              //       loadOptions={loadOfferOptions}
                              //       styles={{
                              //         input: (base) => ({
                              //           ...base,
                              //           "input:focus": {
                              //             boxShadow: "none",
                              //           },
                              //         }),
                              //       }}
                              //       defaultOptions
                              //       formatOptionLabel={(offer) => offer.name}
                              //       cacheOptions
                              //       getOptionValue={(offer) => offer.id}
                              //       isDisabled={isSubmitting || !storeId}
                              //       {...field}
                              //     />
                              //   ) : (
                              //     <Select<SubmitMissionModalOfferFragment>
                              //       isDisabled={!storeId}
                              //     />
                              //   )
                              // }
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="purchaseOrder">
                              Numéro de commande
                            </InputGroup.Label>
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="purchaseOrder"
                              readOnly={isSubmitting}
                              {...register("purchaseOrder")}
                            />
                          }
                        />
                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="purchaseOrder">
                              Prix total des produits vendus
                            </InputGroup.Label>
                          }
                          input={
                            <InputGroup.Input
                              autoComplete="on"
                              autoCorrect="off"
                              id="productPriceTtc"
                              readOnly={isSubmitting}
                              {...register("productPriceTtc")}
                            />
                          }
                        />

                        <Transition.Child as="div" className="mt-3">
                          {offer?.isDateMandatory === "MANDATORY" && (
                            <span className="text-red-600">&#42; </span>
                          )}
                          <span className="text-sm">
                            Date d&apos;intervention
                          </span>
                        </Transition.Child>

                        <Transition.Child
                          className={`w-full border-gray-300 !mt-1 ${
                            questionsAnswersErrors.get("date") &&
                            "border border-red-600 rounded"
                          }`}
                          as="input"
                          id="date"
                          name="date"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => setDate(event.target.value)}
                          type="date"
                          min={new Date().toISOString().split("T")[0]}
                        />
                        {questionsAnswersErrors.get("date") && (
                          <div className="mt-2 text-sm text-red-600 flex">
                            {questionsAnswersErrors.get("date")}
                          </div>
                        )}

                        <Transition
                          as="div"
                          show={offerQuestions.length > 0}
                          enter="transition-opacity duration-75"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition-opacity duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          {offerQuestions.map((question) => (
                            <Transition.Child
                              as="div"
                              key={question.reference}
                              className="mt-3"
                            >
                              <Transition.Child
                                as="span"
                                className="text-red-600"
                              >
                                &#42;{" "}
                              </Transition.Child>

                              <Transition.Child
                                as="label"
                                htmlFor={question.reference}
                                className="text-sm mb-2"
                              >
                                {question.label}
                              </Transition.Child>
                              <Question
                                question={question}
                                onChange={onQuestionAnswerChange}
                                errors={questionsAnswersErrors}
                              />
                            </Transition.Child>
                          ))}
                        </Transition>

                        <InputGroup
                          label={
                            <InputGroup.Label htmlFor="details">
                              Détails sur la prestation
                            </InputGroup.Label>
                          }
                          error={
                            errors.details?.message && (
                              <InputGroup.Error>
                                {errors.details.message}
                              </InputGroup.Error>
                            )
                          }
                          input={
                            <InputGroup.TextArea
                              autoComplete="off"
                              autoCorrect="off"
                              id="details"
                              readOnly={isSubmitting}
                              {...register("details")}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end bg-gray-50 px-4 py-3 sm:px-6">
                  <Button
                    type="submit"
                    className="inline-flex w-full justify-center sm:w-auto"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Envoyer
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubmitMissionModal;
