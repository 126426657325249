import { PhoneIcon, DotsHorizontalIcon } from "@heroicons/react/solid";
import React, { useMemo } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import DropDownMenu from "../molecules/drop-down-menu";
import StackedList from "../molecules/stacked-list";
import { UserCircleIcon } from "@heroicons/react/outline";

const RightSection: React.FC<{
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
}> = ({ title, subtitle }) => (
  <div className="h-full">
    {title && (
      <p className="flex items-center text-sm text-gray-900">{title}</p>
    )}
    {subtitle && (
      <p className="mt-2 flex items-center text-sm text-gray-500">{subtitle}</p>
    )}
  </div>
);

const OfferStackedListItem: React.FC<
  React.ComponentProps<typeof StackedList.Item> & {
    expert: {
      id: string;
      name: string;
      phoneNumber: string;
      profilePictureURL?: string | null;
      expertTrackingUrl?: string | null;
    };
    actions?: React.ReactNode;
    rightSection?: React.ReactNode;
  }
> = ({ expert, actions, rightSection, ...props }) => {
  const phoneNumber = useMemo(
    () => parsePhoneNumber(expert.phoneNumber),
    [expert.phoneNumber]
  );

  return (
    <StackedList.Item {...props}>
      <div className="flex w-full items-center">
        <div className="shrink-0">
          {expert.profilePictureURL ? (
            <img
              className="h-12 w-12 rounded-full"
              src={expert.profilePictureURL}
              alt={`Profil de ${expert.name}`}
            />
          ) : (
            <UserCircleIcon className="h-12 w-12 rounded-full" />
          )}
        </div>
        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
          <div>
            <a
              href={`https://app.forestadmin.com/retail/Production/Customer%20Success/data/experts/index/record/experts/${expert.id}/details`}
            >
              <p className="truncate text-sm font-medium text-blue-600">
                {expert.name}
              </p>
            </a>
            <p className="mt-2 flex items-center text-sm text-gray-500">
              <PhoneIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <a href={phoneNumber.getURI()} className="truncate">
                {phoneNumber.formatNational()}
              </a>
            </p>
          </div>
          {rightSection && <div>{rightSection}</div>}
        </div>
        {actions && (
          <div className="shrink-0">
            <DropDownMenu
              button={
                <DropDownMenu.Button className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <DotsHorizontalIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </DropDownMenu.Button>
              }
            >
              {actions}
            </DropDownMenu>
          </div>
        )}
      </div>
      {expert.expertTrackingUrl && (
        <div className="flex justify-end">
          <a
            href={expert.expertTrackingUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="underline text-[blue] text-sm mt-3 block "
          >
            Lien de suivi
          </a>
        </div>
      )}
    </StackedList.Item>
  );
};

export default Object.assign(OfferStackedListItem, { RightSection });
