import {
  HomeIcon,
  ShoppingBagIcon,
  ClipboardListIcon,
  UsersIcon,
} from "@heroicons/react/outline";
import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import urlJoin from "url-join";
import MainLayout from "./components/layouts/main/main";
import MissionPage from "./components/pages/mission/mission";
import OnboardingRequestPage from "./components/pages/onboarding-request/onboarding-request";
import QuoteRequestPage from "./components/pages/quote-request/quote-request";
import QuoteRequestsPage from "./components/pages/quote-request/quote-requests";
import RequestsPage from "./components/pages/requests/requests";
import RetailerPage from "./components/pages/retailers/retailer";
import RetailersPage from "./components/pages/retailers/retailers";
import UsersPage from "./components/pages/user/users";
import GetBackOfficeUser from "./login-provider";

const RootRouter: React.FC = () => {
  const routeMatch = useRouteMatch();
  const backOfficeUser = GetBackOfficeUser();

  return (
    <MainLayout
      sidebarNavigationItems={[
        {
          icon: HomeIcon,
          name: "Missions",
          target: urlJoin(routeMatch.url, `requests`),
          visible: true,
        },
        {
          icon: ClipboardListIcon,
          name: "Devis",
          target: urlJoin(routeMatch.url, `quotes`),
          visible: true,
        },
        {
          icon: ShoppingBagIcon,
          name: "Retailers",
          target: urlJoin(routeMatch.url, `retailers`),
          visible: !backOfficeUser?.isViewer ?? false,
        },
        {
          icon: UsersIcon,
          name: "Utilisateurs",
          target: urlJoin(routeMatch.url, `users`),
          visible: backOfficeUser?.isAdmin ?? false,
        },
      ]}
    >
      <Switch>
        <Route exact path={routeMatch.path}>
          <Redirect to={urlJoin(routeMatch.url, `requests`)} />
        </Route>
        <Route path={urlJoin(routeMatch.path, `requests`)}>
          <RequestsPage
            getRequestPageLocation={({ id }) => {
              return urlJoin(routeMatch.url, "mission", id);
            }}
          />
        </Route>
        {!backOfficeUser?.isViewer && (
          <Route path={urlJoin(routeMatch.path, `retailers`)}>
            <RetailersPage
              getRetailersPageLocation={({ id }) =>
                urlJoin(routeMatch.url, "retailer", id)
              }
            />
          </Route>
        )}
        {!backOfficeUser?.isViewer && (
          <Route path={urlJoin(routeMatch.path, `retailer`, ":retailerId")}>
            {({ match }) => (
              <RetailerPage retailerId={match?.params["retailerId"] ?? ""} />
            )}
          </Route>
        )}
        <Route path={urlJoin(routeMatch.path, `mission`, `:missionId`)}>
          {({ match }) => (
            <MissionPage missionId={match?.params["missionId"] ?? ""} />
          )}
        </Route>
        <Route
          path={urlJoin(
            routeMatch.path,
            `onboarding-request`,
            `:onboardingRequestId`
          )}
        >
          {({ match }) => (
            <OnboardingRequestPage
              onboardingRequestId={match?.params["onboardingRequestId"] ?? ""}
            />
          )}
        </Route>
        <Route path={urlJoin(routeMatch.path, `quotes`)}>
          <QuoteRequestsPage
            getQuoteRequestPageLocation={({ id }) =>
              urlJoin(routeMatch.url, "quote-request", id)
            }
          />
        </Route>
        <Route
          path={urlJoin(routeMatch.path, `quote-request`, `:quoteRequestId`)}
        >
          {({ match }) => (
            <QuoteRequestPage
              quoteRequestId={match?.params["quoteRequestId"] ?? ""}
            />
          )}
        </Route>
        {backOfficeUser?.isAdmin && (
          <Route path={urlJoin(routeMatch.path, `users`)}>
            <UsersPage
              getUsersPageLocation={({ id }) =>
                urlJoin(routeMatch.url, "user", id)
              }
            />
          </Route>
        )}
        <Route path={urlJoin(routeMatch.path, `user`, `:userId`)}>
          {({ match }) => (
            <QuoteRequestPage quoteRequestId={match?.params["userId"] ?? ""} />
          )}
        </Route>
        <Route>
          <Redirect to={routeMatch.url} />
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default RootRouter;
