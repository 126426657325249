
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddUser_Result": [
      "AddUser_Failure",
      "AddUser_Success"
    ],
    "AssignExpertToMission_Result": [
      "AssignExpertToMission_Failure",
      "AssignExpertToMission_Success"
    ],
    "AssignExpertToQuoteRequest_Result": [
      "AssignExpertToQuoteRequest_Failure",
      "AssignExpertToQuoteRequest_Success"
    ],
    "CancelMission_Result": [
      "CancelMission_Failure",
      "CancelMission_Success"
    ],
    "CancelQuoteRequest_Result": [
      "CancelQuoteRequest_Failure",
      "CancelQuoteRequest_Success"
    ],
    "Coupon": [
      "MiraklCoupon",
      "PlatformCoupon"
    ],
    "DeleteQuoteRequest_Result": [
      "DeleteQuoteRequest_Failure",
      "DeleteQuoteRequest_Success"
    ],
    "DeleteUser_Result": [
      "DeleteUser_Failure",
      "DeleteUser_Success"
    ],
    "GenerateRetailerInvoice_Result": [
      "GenerateRetailerInvoice_Failure",
      "GenerateRetailerInvoice_Success"
    ],
    "GenerateRetailerViban_Result": [
      "GenerateRetailerViban_Failure",
      "GenerateRetailerViban_Success"
    ],
    "IncreaseTimerQuoteOffer_Result": [
      "IncreaseTimerQuoteOffer_Failure",
      "IncreaseTimerQuoteOffer_Success"
    ],
    "LogInMutation_Result": [
      "LogInMutation_Failure",
      "LogInMutation_Success"
    ],
    "MarkAcceptedMissionAsDone_Result": [
      "MarkAcceptedMissionAsDone_Failure",
      "MarkAcceptedMissionAsDone_Success"
    ],
    "MatchNextExpert_Result": [
      "MatchNextExpert_Failure",
      "MatchNextExpert_Success"
    ],
    "MissionQuestionAnswer": [
      "NumberMissionQuestionAnswer",
      "StringMissionQuestionAnswer"
    ],
    "ModifyUser_Result": [
      "ModifyUser_Failure",
      "ModifyUser_Success"
    ],
    "Question": [
      "MultipleChoiceQuestion",
      "MultipleChoicesQuestion",
      "NumberQuestion",
      "TextQuestion"
    ],
    "QuoteRequestQuestionAnswer": [
      "NumberQuoteRequestQuestionAnswer",
      "StringQuoteRequestQuestionAnswer"
    ],
    "RelaunchTimerQuoteOffer_Result": [
      "RelaunchTimerQuoteOffer_Failure",
      "RelaunchTimerQuoteOffer_Success"
    ],
    "SubmitMissionComment_Result": [
      "SubmitMissionComment_Failure",
      "SubmitMissionComment_Success"
    ],
    "SubmitMissionEdition_Result": [
      "SubmitMissionEdition_Failure",
      "SubmitMissionEdition_Success"
    ],
    "SubmitMission_Result": [
      "SubmitMission_Failure",
      "SubmitMission_Success"
    ],
    "SubmitOnboardingRequestComment_Result": [
      "SubmitOnboardingRequestComment_Failure",
      "SubmitOnboardingRequestComment_Success"
    ],
    "SubmitQuoteRequestComment_Result": [
      "SubmitQuoteRequestComment_Failure",
      "SubmitQuoteRequestComment_Success"
    ],
    "SubmitQuoteRequestEdition_Result": [
      "SubmitQuoteRequestEdition_Failure",
      "SubmitQuoteRequestEdition_Success"
    ],
    "SubmitQuoteRequest_Result": [
      "SubmitQuoteRequest_Failure",
      "SubmitQuoteRequest_Success"
    ]
  }
};
      export default result;
    