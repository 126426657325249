import React, { useCallback } from "react";
import { FormattedDate } from "react-intl";
import { NumberParam, useQueryParam, withDefault } from "use-query-params";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Table from "../../../molecules/table";
import {
  PriorityMissionsQuery,
  TabMissionRequestFragment,
} from "../../../../graphql-operations-types";
import Pagination from "../../../molecules/pagination";
import FilterDropDown from "../../../organisms/filter-drop-down";

const QUERY = gql`
  fragment PriorityMissions on Mission {
    id
    missionStatus: status
    maybeAddress: address {
      zipCode
    }
    createdAt
    date
    customerSuccessReminderDate
    offer {
      id
      name
    }
    retailer {
      id
      name
    }
    # acceptedJobOffer {
    #   ...JobOfferMission
    # }
    customer {
      firstName
      lastName
      phoneNumber
    }
    doneAt
  }

  query PriorityMissions {
    priorityMissions {
      totalCount
      priorityMissions {
        id
        ...PriorityMissions
      }
    }
  }
`;
const MissionRow: React.FC<
  React.ComponentProps<typeof Table.RowLink> & {
    mission: TabMissionRequestFragment;
  }
> = ({ mission, ...props }) => {
  return (
    <Table.RowLink {...props}>
      {/* Date creation */}
      <Table.DataCell className="font-medium">
        <FormattedDate value={mission.createdAt} />
      </Table.DataCell>

      {/* DATE DE RELANCE CS */}
      <Table.DataCell className="font-medium">
        {mission.customerSuccessReminderDate ? (
          <FormattedDate value={mission.customerSuccessReminderDate} />
        ) : (
          "-"
        )}
      </Table.DataCell>

      {/* Offre */}
      <Table.DataCell className="text-gray-500">
        {mission.offer.name}
      </Table.DataCell>

      {/* Code postal */}
      <Table.DataCell className="text-gray-500">
        {mission.maybeAddress?.zipCode ?? "-"}
      </Table.DataCell>

      {/* Customer */}
      <Table.DataCell className="text-gray-500">
        {mission.customer
          ? `${mission.customer.firstName} ${mission.customer.lastName}`
          : "-"}
      </Table.DataCell>

      {/* Phone number */}
      <Table.DataCell className="text-gray-500">
        {mission.customer ? `${mission.customer.phoneNumber}` : "-"}
      </Table.DataCell>

      {/* Retailer */}
      <Table.DataCell className="text-gray-500">
        {mission.retailer.name}
      </Table.DataCell>
    </Table.RowLink>
  );
};

const _PriorityMissionsTab: React.FC<{
  missions: PriorityMissionsQuery;
  currentPage: number;
  pageSize: number;
  getRequestPageLocation: (
    mission: TabMissionRequestFragment
  ) => React.ComponentProps<typeof Link>["to"];
  handleFilterMissionSubmit: (properties: {
    afterCreatedDate?: string | null;
    afterSubmittedDate?: string | null;
    afterCsReminderDate?: string | null;
    customer?: string | null;
    phoneNumber?: string | null;
    postCode?: string | null;
  }) => void;
}> = ({
  missions,
  currentPage,
  pageSize,
  getRequestPageLocation,
  handleFilterMissionSubmit,
}) => {
  return (
    <div className="space-y-2">
      <div className="mb-4 text-lg text-gray-900">
        Toutes les missions prioritaires
        <div className="absolute right-8 top-24 z-20 place-items-end">
          <FilterDropDown
            onSubmit={handleFilterMissionSubmit}
            afterCreatedDateLabel="Created depuis"
            afterSubmittedDateLabel="Submitted depuis"
            customerLabel="Customer"
            phoneNumberLabel="Téléphone"
            postCodeLabel="Code postal"
            expertLabel="Expert"
            storeLabel="Store"
            retailerLabel="Retailer"
          />
        </div>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Date de creation</Table.HeadCell>
            <Table.HeadCell>Date de relance</Table.HeadCell>
            <Table.HeadCell>Offre</Table.HeadCell>
            <Table.HeadCell>Code Postal</Table.HeadCell>
            <Table.HeadCell>Nom du client</Table.HeadCell>
            <Table.HeadCell>Téléphone du client</Table.HeadCell>
            <Table.HeadCell>Retailer</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {missions.priorityMissions?.priorityMissions.map((mission, i) => {
            return (
              <MissionRow
                odd={i % 2 === 0}
                key={mission.id}
                to={getRequestPageLocation(
                  mission as TabMissionRequestFragment
                )}
                mission={mission}
              />
            );
          })}
        </Table.Body>
      </Table>
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={missions.priorityMissions?.totalCount ?? 0}
        getPageLocation={(page) => (location) => {
          const search = new URLSearchParams(location.search);
          search.set("page", page.toString());

          return {
            ...location,
            search: search.toString(),
          };
        }}
      />
    </div>
  );
};

const PriorityMissionsTab: React.FC<{
  getRequestPageLocation: React.ComponentProps<
    typeof _PriorityMissionsTab
  >["getRequestPageLocation"];
}> = ({ getRequestPageLocation }) => {
  const pageSize = 50;
  const [currentPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const offset = (currentPage - 1) * pageSize;
  const queryResult = useQuery<PriorityMissionsQuery>(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const handleFilterMissionSubmit = useCallback(
    ({
      afterCreatedDate,
      afterSubmittedDate,
      beforeCsReminderDate,
      afterCsReminderDate,
      customer,
      phoneNumber,
      postCode,
      expert,
    }: {
      afterCreatedDate?: string | null;
      afterSubmittedDate?: string | null;
      beforeCsReminderDate?: string | null;
      afterCsReminderDate?: string | null;
      customer?: string | null;
      phoneNumber?: string | null;
      postCode?: string | null;
      expert?: string | null;
    }) => {
      queryResult.refetch({
        query: queryResult,
        requestsQueryParameters: {
          limit: pageSize,
          offset,
          where: {
            createdAfter: afterCreatedDate,
            submittedAfter: afterSubmittedDate,
            csReminderBefore: beforeCsReminderDate,
            csReminderAfter: afterCsReminderDate,
            customer,
            phoneNumber,
            postCode,
            expert,
          },
        },
      });
    },
    [queryResult, offset]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <_PriorityMissionsTab
      currentPage={currentPage}
      pageSize={pageSize}
      missions={queryResult.data}
      getRequestPageLocation={getRequestPageLocation}
      handleFilterMissionSubmit={handleFilterMissionSubmit}
    />
  );
};

export default PriorityMissionsTab;
