import { gql, useQuery } from "@apollo/client";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { FormattedDate } from "react-intl";
import { useQueryParam, withDefault, StringParam } from "use-query-params";
import Table from "../../../molecules/table";
import {
  PriorityQuoteRequestsTabQuery,
  PriorityQuoteRequestsTabQueryVariables,
  PriorityQuoteRequestsTabQuoteRequestFragment,
} from "../../../../graphql-operations-types";
import FilterDropDown from "../../../organisms/filter-drop-down";

const QUERY = gql`
  fragment PriorityQuoteRequestsTabQuoteRequest on QuoteRequest {
    id
    createdAt
    customerSuccessReminderDate
    offer {
      id
      name
    }

    retailer {
      id
      name
    }
    address {
      zipCode
    }
    customerFirstName
    customerLastName
    customerPhoneNumber
  }

  query PriorityQuoteRequestsTab(
    $priorityQuoteRequestsQueryParametersWhere: PriorityQuoteRequestsQueryParametersWhere
  ) {
    priorityQuoteRequests(where: $priorityQuoteRequestsQueryParametersWhere) {
      totalCount
      priorityQuoteRequests {
        id
        ...PriorityQuoteRequestsTabQuoteRequest
      }
    }
  }
`;

const QuoteRequestRow: React.FC<
  React.ComponentProps<typeof Table.RowLink> & {
    quoteRequest: PriorityQuoteRequestsTabQuoteRequestFragment;
  }
> = ({ quoteRequest, ...props }) => {
  return (
    <Table.RowLink {...props}>
      {/* date de création */}
      <Table.DataCell className="font-normal">
        <FormattedDate value={quoteRequest.createdAt} />
      </Table.DataCell>

      {/* date de relance CS */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerSuccessReminderDate ? (
          <FormattedDate value={quoteRequest.customerSuccessReminderDate} />
        ) : (
          "-"
        )}
      </Table.DataCell>

      {/* offre */}
      <Table.DataCell className="font-normal">
        {quoteRequest.offer.name}
      </Table.DataCell>

      {/* code postal */}
      <Table.DataCell className="font-normal">
        {quoteRequest.address.zipCode ?? "-"}
      </Table.DataCell>

      {/* nom du client */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerLastName && quoteRequest.customerFirstName
          ? `${quoteRequest.customerFirstName} ${quoteRequest.customerLastName}`
          : "-"}
      </Table.DataCell>

      {/* numéro de téléphone du client */}
      <Table.DataCell className="font-normal">
        {quoteRequest.customerPhoneNumber ?? "-"}
      </Table.DataCell>

      {/* retailer */}
      <Table.DataCell className="font-normal">
        {quoteRequest.retailer.name ?? "-"}
      </Table.DataCell>
    </Table.RowLink>
  );
};

const _PriorityQuoteRequestsTab: React.FC<{
  priorityQuoteRequestsQueryResult: PriorityQuoteRequestsTabQuery;
  getQuoteRequestPageLocation: (
    quoteRequest: PriorityQuoteRequestsTabQuoteRequestFragment
  ) => React.ComponentProps<typeof Link>["to"];
  handleFilterQuoteSubmit: (properties: {
    afterCreatedDate?: string | null;
    afterSubmittedDate?: string | null;
    afterCsReminderDate?: string | null;
    customer?: string | null;
    phoneNumber?: string | null;
    postCode?: string | null;
    store?: string | null;
    retailer?: string | null;
  }) => void;
}> = ({
  priorityQuoteRequestsQueryResult,
  getQuoteRequestPageLocation,
  handleFilterQuoteSubmit,
}) => {
  return (
    <div className="space-y-2">
      <div className="mb-10">
        <div className="absolute right-8 top-24 z-20 place-items-end text-lg text-gray-900">
          <FilterDropDown
            onSubmit={handleFilterQuoteSubmit}
            afterCreatedDateLabel="Created depuis"
            beforeCsReminderDateLabel="Relance CS jusqu’à"
            afterCsReminderDateLabel="Relance CS depuis"
            customerLabel="Customer"
            phoneNumberLabel="Téléphone"
            postCodeLabel="Code postal"
            storeLabel="Store"
            retailerLabel="Retailer"
          />
        </div>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>date de création</Table.HeadCell>
            <Table.HeadCell>date de relance CS</Table.HeadCell>
            <Table.HeadCell>offre</Table.HeadCell>
            <Table.HeadCell>code postal</Table.HeadCell>
            <Table.HeadCell>nom du client</Table.HeadCell>
            <Table.HeadCell>téléphone du client</Table.HeadCell>
            <Table.HeadCell>retailer</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {priorityQuoteRequestsQueryResult.priorityQuoteRequests?.priorityQuoteRequests.map(
            (quoteRequest, i) => (
              <QuoteRequestRow
                key={quoteRequest.id}
                quoteRequest={quoteRequest}
                odd={i % 2 === 0}
                to={getQuoteRequestPageLocation(quoteRequest)}
              />
            )
          )}
        </Table.Body>
      </Table>
    </div>
  );
};

const PriorityQuoteRequestsTab: React.FC<{
  getQuoteRequestPageLocation: React.ComponentProps<
    typeof _PriorityQuoteRequestsTab
  >["getQuoteRequestPageLocation"];
  onfetchingCompleted: () => void;
}> = ({ getQuoteRequestPageLocation, onfetchingCompleted }) => {
  const [afterCreatedDateQuery] = useQueryParam(
    "after_created_date",
    withDefault(StringParam, null)
  );
  const [beforeCsReminderDateQuery] = useQueryParam(
    "before_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [afterCsReminderDateQuery] = useQueryParam(
    "after_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [customerQuery] = useQueryParam(
    "customer",
    withDefault(StringParam, null)
  );
  const [phoneNumberQuery] = useQueryParam(
    "phone_number",
    withDefault(StringParam, null)
  );
  const [postCodeQuery] = useQueryParam(
    "post_code",
    withDefault(StringParam, null)
  );
  const [storeQuery] = useQueryParam("store", withDefault(StringParam, null));
  const [retailerQuery] = useQueryParam(
    "retailer",
    withDefault(StringParam, null)
  );
  const queryResult = useQuery<
    PriorityQuoteRequestsTabQuery,
    PriorityQuoteRequestsTabQueryVariables
  >(QUERY, {
    variables: {
      priorityQuoteRequestsQueryParametersWhere: {
        createdAfter: afterCreatedDateQuery,
        csReminderBefore: beforeCsReminderDateQuery,
        csReminderAfter: afterCsReminderDateQuery,
        customer: customerQuery,
        phoneNumber: phoneNumberQuery,
        postCode: postCodeQuery,
        store: storeQuery,
        retailer: retailerQuery,
      },
    },
    fetchPolicy: "cache-and-network",
    onCompleted: () => onfetchingCompleted(),
  });

  const handleFilterQuoteSubmit = useCallback(
    ({
      afterCreatedDate,
      beforeCsReminderDate,
      afterCsReminderDate,
      customer,
      phoneNumber,
      postCode,
      store,
      retailer,
    }: {
      afterCreatedDate?: string | null;
      beforeCsReminderDate?: string | null;
      afterCsReminderDate?: string | null;
      customer?: string | null;
      phoneNumber?: string | null;
      postCode?: string | null;
      store?: string | null;
      retailer?: string | null;
    }) => {
      queryResult.refetch({
        priorityQuoteRequestsQueryParametersWhere: {
          createdAfter: afterCreatedDate,
          csReminderBefore: beforeCsReminderDate,
          csReminderAfter: afterCsReminderDate,
          customer,
          phoneNumber,
          postCode,
          store,
          retailer,
        },
      });
    },
    [queryResult]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <_PriorityQuoteRequestsTab
      priorityQuoteRequestsQueryResult={queryResult.data}
      getQuoteRequestPageLocation={getQuoteRequestPageLocation}
      handleFilterQuoteSubmit={handleFilterQuoteSubmit}
    />
  );
};

export default PriorityQuoteRequestsTab;
