import React, { useCallback } from "react";
import { FormattedDate } from "react-intl";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";
import { Link } from "react-router-dom";
import Table from "../../../molecules/table";
import {
  MissionsQuery,
  TabMissionRequestFragment,
} from "../../../../graphql-operations-types";
import Pagination from "../../../molecules/pagination";
import FilterDropDown from "../../../organisms/filter-drop-down";
import { RefetchMissions, RetrieveMissions } from "./retrieve-missions";

const MissionRow: React.FC<
  React.ComponentProps<typeof Table.RowLink> & {
    mission: TabMissionRequestFragment;
  }
> = ({ mission, ...props }) => {
  return (
    <Table.RowLink {...props}>
      {/* Date creation */}
      <Table.DataCell className="font-medium">
        <FormattedDate value={mission.createdAt} />
      </Table.DataCell>

      {/* DATE DE RELANCE CS */}
      <Table.DataCell className="font-medium">
        {mission.customerSuccessReminderDate ? (
          <FormattedDate value={mission.customerSuccessReminderDate} />
        ) : (
          "-"
        )}
      </Table.DataCell>

      {/* Offre */}
      <Table.DataCell className="text-gray-500">
        {mission.offer.name}
      </Table.DataCell>

      {/* Code postal */}
      <Table.DataCell className="text-gray-500">
        {mission.maybeAddress?.zipCode ?? "-"}
      </Table.DataCell>

      {/* Customer */}
      <Table.DataCell className="text-gray-500">
        {mission.customer
          ? `${mission.customer.firstName} ${mission.customer.lastName}`
          : "-"}
      </Table.DataCell>

      {/* Phone number */}
      <Table.DataCell className="text-gray-500">
        {mission.customer ? `${mission.customer.phoneNumber}` : "-"}
      </Table.DataCell>

      {/* Retailer */}
      <Table.DataCell className="text-gray-500">
        {mission.retailer.name}
      </Table.DataCell>
    </Table.RowLink>
  );
};

const _DraftRequestsTab: React.FC<{
  requestsQueryResult: MissionsQuery;
  currentPage: number;
  pageSize: number;
  getRequestPageLocation: (
    mission: TabMissionRequestFragment
  ) => React.ComponentProps<typeof Link>["to"];
  handleFilterMissionSubmit: (properties: {
    afterCreatedDate?: string | null;
    afterCsReminderDate?: string | null;
    postCode?: string | null;
  }) => void;
}> = ({
  requestsQueryResult,
  currentPage,
  pageSize,
  getRequestPageLocation,
  handleFilterMissionSubmit,
}) => {
  return (
    <div className="space-y-2">
      <div className="mb-4 text-lg text-gray-900">
        Toutes les prestations draft
        <div className="absolute right-8 top-24 z-20 place-items-end">
          <FilterDropDown
            onSubmit={handleFilterMissionSubmit}
            afterCreatedDateLabel="Created depuis"
            beforeCsReminderDateLabel="Relance CS jusqu’à"
            afterCsReminderDateLabel="Relance CS depuis"
            postCodeLabel="Code postal"
            storeLabel="Store"
            retailerLabel="Retailer"
          />
        </div>
      </div>
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.HeadCell>Date de creation</Table.HeadCell>
            <Table.HeadCell>Date de relance</Table.HeadCell>
            <Table.HeadCell>Offre</Table.HeadCell>
            <Table.HeadCell>Code Postal</Table.HeadCell>
            <Table.HeadCell>Nom du client</Table.HeadCell>
            <Table.HeadCell>Téléphone du client</Table.HeadCell>
            <Table.HeadCell>Retailer</Table.HeadCell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {requestsQueryResult.missions.missions.map((mission, i) => (
            <MissionRow
              odd={i % 2 === 0}
              key={mission.id}
              to={getRequestPageLocation(mission)}
              mission={mission}
            />
          ))}
        </Table.Body>
      </Table>
      <Pagination
        currentPage={currentPage}
        pageSize={pageSize}
        totalCount={requestsQueryResult.missions.totalCount}
        getPageLocation={(page) => (location) => {
          const search = new URLSearchParams(location.search);
          search.set("page", page.toString());

          return {
            ...location,
            search: search.toString(),
          };
        }}
      />
    </div>
  );
};

const DraftRequestsTab: React.FC<{
  getRequestPageLocation: React.ComponentProps<
    typeof _DraftRequestsTab
  >["getRequestPageLocation"];
}> = ({ getRequestPageLocation }) => {
  const [afterCreatedDateQuery] = useQueryParam(
    "after_created_date",
    withDefault(StringParam, null)
  );
  const [beforeCsReminderDateQuery] = useQueryParam(
    "before_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [afterCsReminderDateQuery] = useQueryParam(
    "after_cs_reminder_date",
    withDefault(StringParam, null)
  );
  const [postCodeQuery] = useQueryParam(
    "post_code",
    withDefault(StringParam, null)
  );
  const [storeQuery] = useQueryParam("store", withDefault(StringParam, null));
  const [retailerQuery] = useQueryParam(
    "retailer",
    withDefault(StringParam, null)
  );
  const pageSize = 50;
  const [currentPage] = useQueryParam("page", withDefault(NumberParam, 1));
  const offset = (currentPage - 1) * pageSize;
  const queryResult = RetrieveMissions({
    requestsQueryParameters: {
      limit: pageSize,
      offset,
      where: {
        status: "DRAFT",
        createdAfter: afterCreatedDateQuery,
        csReminderBefore: beforeCsReminderDateQuery,
        csReminderAfter: afterCsReminderDateQuery,
        postCode: postCodeQuery,
        store: storeQuery,
        retailer: retailerQuery,
      },
    },
  });

  const handleFilterMissionSubmit = useCallback(
    ({
      afterCreatedDate,
      beforeCsReminderDate,
      afterCsReminderDate,
      postCode,
    }: {
      afterCreatedDate?: string | null;
      beforeCsReminderDate?: string | null;
      afterCsReminderDate?: string | null;
      postCode?: string | null;
    }) => {
      RefetchMissions({
        query: queryResult,
        requestsQueryParameters: {
          limit: pageSize,
          offset,
          where: {
            status: "DRAFT",
            createdAfter: afterCreatedDate,
            csReminderBefore: beforeCsReminderDate,
            csReminderAfter: afterCsReminderDate,
            postCode,
          },
        },
      });
    },
    [queryResult]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <_DraftRequestsTab
      currentPage={currentPage}
      pageSize={pageSize}
      requestsQueryResult={queryResult.data}
      getRequestPageLocation={getRequestPageLocation}
      handleFilterMissionSubmit={handleFilterMissionSubmit}
    />
  );
};

export default DraftRequestsTab;
