import { useMutation, useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
import { sortBy } from "lodash";
import React, { useCallback } from "react";
import {
  OnboardingRequestPageQuery,
  OnboardingRequestPageQueryVariables,
  OnboardingRequestPageOnboardingRequestFragment,
  OnboardingRequestPageSubmitOnboardingRequestCommentMutation,
  OnboardingRequestPageSubmitOnboardingRequestCommentMutationVariables,
} from "../../../graphql-operations-types";
import CommentsCard from "../../organisms/comments-card";

const ONBOARDING_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT = gql`
  ${CommentsCard.COMMENT_FRAGMENT}

  fragment OnboardingRequestPageOnboardingRequest on OnboardingRequest {
    id
    comments {
      ...CommentsCardComment
    }
  }
`;

const ONBOARDING_REQUEST_PAGE_QUERY = gql`
  ${ONBOARDING_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  query OnboardingRequestPage($onboardingRequestId: String!) {
    onboardingRequest(id: $onboardingRequestId) {
      ...OnboardingRequestPageOnboardingRequest
    }
  }
`;

const SUBMIT_ONBOARDING_REQUEST_COMMENT_MUTATION = gql`
  ${ONBOARDING_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation OnboardingRequestPageSubmitOnboardingRequestComment(
    $onboardingRequestId: UUID!
    $content: String!
  ) {
    submitOnboardingRequestComment(
      onboardingRequestId: $onboardingRequestId
      content: $content
    ) {
      ... on SubmitOnboardingRequestComment_Success {
        onboardingRequest {
          ...OnboardingRequestPageOnboardingRequest
        }
      }
    }
  }
`;

const _OnboardingRequestPage: React.FC<{
  onboardingRequest: OnboardingRequestPageOnboardingRequestFragment;
  onSubmitComment: (content: string) => void;
}> = ({ onboardingRequest: quoteRequest, onSubmitComment }) => {
  return (
    <div>
      <div className="mx-auto mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6">
          <CommentsCard
            onSubmitComment={onSubmitComment}
            comments={sortBy(
              quoteRequest.comments,
              (comment) => comment.createdAt
            ).map((comment) => (
              <CommentsCard.Comment key={comment.id} comment={comment} />
            ))}
          />
        </div>
      </div>
    </div>
  );
};

const OnboardingRequestPage: React.FC<{ onboardingRequestId: string }> = ({
  onboardingRequestId,
}) => {
  const queryResult = useQuery<
    OnboardingRequestPageQuery,
    OnboardingRequestPageQueryVariables
  >(ONBOARDING_REQUEST_PAGE_QUERY, { variables: { onboardingRequestId } });
  const [submitOnboardingRequestCommentMutation] = useMutation<
    OnboardingRequestPageSubmitOnboardingRequestCommentMutation,
    OnboardingRequestPageSubmitOnboardingRequestCommentMutationVariables
  >(SUBMIT_ONBOARDING_REQUEST_COMMENT_MUTATION);
  const handleSubmitComment = useCallback(
    (content: string) => {
      submitOnboardingRequestCommentMutation({
        variables: { content, onboardingRequestId },
      });
    },
    [onboardingRequestId, submitOnboardingRequestCommentMutation]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (queryResult.loading) {
    return null;
  }

  if (!queryResult.data?.onboardingRequest) {
    return <>Not Found</>;
  }

  return (
    <_OnboardingRequestPage
      onboardingRequest={queryResult.data.onboardingRequest}
      onSubmitComment={handleSubmitComment}
    />
  );
};

export default OnboardingRequestPage;
