import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import classNames from "classnames";

const RequiredAsterisk: React.FC<React.ComponentProps<"span">> = ({
  className,
  ...props
}) => (
  <span className={classNames("text-red-600", className)} {...props}>
    &#42;
  </span>
);

const Label: React.FC<React.ComponentProps<"label">> = ({
  className,
  ...props
}) => (
  <label
    className={classNames("block text-sm font-medium text-gray-700", className)}
    {...props}
  />
);

const Error: React.FC<React.ComponentProps<"p">> = ({
  className,
  ...props
}) => (
  <p
    className={classNames("mt-2 text-sm text-red-600", className)}
    {...props}
  />
);

type InputProps = { error?: boolean };
const Input = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & InputProps
>(function Input({ className, error, ...props }, ref) {
  return (
    <input
      ref={ref}
      className={classNames(
        "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm",
        {
          "!border-red-300 !text-red-900 !placeholder-red-400 focus:!border-red-500 focus:!ring-red-500":
            error,
        },
        className
      )}
      {...props}
    />
  );
});

const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  React.ComponentProps<"textarea"> & InputProps
>(function TextArea({ className, error, ...props }, ref) {
  return (
    <textarea
      ref={ref}
      className={classNames(
        "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm",
        {
          "!border-red-300 !text-red-900 !placeholder-red-400 focus:!border-red-500 focus:!ring-red-500":
            error,
        },
        className
      )}
      {...props}
    />
  );
});

const _InputGroup: React.FC<
  React.ComponentProps<"div"> & {
    label?: React.ReactNode;
    error?: React.ReactNode;
    input: React.ReactNode;
    loading?: boolean;
  }
> = ({ label, error, input, className, loading, ...props }) => {
  return (
    <div
      className={classNames(
        {
          "animate-pulse": loading,
        },
        className
      )}
      {...props}
    >
      {label}
      <div className="relative mt-1 rounded-md shadow-sm">
        {React.isValidElement(input)
          ? React.cloneElement(input, { error: !!error })
          : input}
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error}
    </div>
  );
};

const InputGroup = Object.assign(_InputGroup, {
  Label,
  Error,
  Input,
  TextArea,
  RequiredAsterisk,
});

export default InputGroup;
