import { useMutation, useQuery } from "@apollo/client";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
  TrashIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";
import { differenceInSeconds } from "date-fns";
import { gql } from "graphql-tag";
import { sortBy } from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  FormattedRelativeTime,
  useIntl,
} from "react-intl";
import {
  CanceledQuoteRequest_CancelationReason,
  CancelQuoteRequestMutation,
  MutationCancelQuoteRequestArgs,
  QuoteOfferDeclineReason,
  QuoteRequestPageQuery,
  QuoteRequestPageQueryVariables,
  QuoteRequestPageQuoteOfferFragment,
  QuoteRequestPageQuoteRequestFragment,
  QuoteRequestPageSubmitQuoteRequestCommentMutation,
  QuoteRequestPageSubmitQuoteRequestCommentMutationVariables,
  QuoteRequestPageCouponFragment,
  AssignExpertToQuoteRequestMutation,
  AssignExpertToQuoteRequestMutationVariables,
  QuoteRequestPageSubmitQuoteRequestEditionMutation,
  QuoteRequestPageSubmitQuoteRequestEditionMutationVariables,
  DeleteQuoteRequestMutation,
  MutationDeleteQuoteRequestArgs,
  RelaunchTimerQuoteOfferMutationVariables,
  RelaunchTimerQuoteOfferMutation,
  IncreaseTimerQuoteOfferMutation,
  IncreaseTimerQuoteOfferMutationVariables,
} from "../../../graphql-operations-types";
import Card from "../../molecules/card";
import DataGrid from "../../molecules/data-grid";
import StackedList from "../../molecules/stacked-list";
import Table from "../../molecules/table";
import CommentsCard from "../../organisms/comments-card";
import OfferStackedListItem from "../../organisms/offer-stacked-list-item";
import DropDownMenu from "../../molecules/drop-down-menu";
import Toaster from "../../atoms/toaster";
import CancelQuoteRequestAlert from "../../organisms/cancel-quote-request-alert";
import AssignExpertAlert from "../../organisms/assign-expert-alert";
import GetBackOfficeUser from "../../../login-provider";
import Input from "../../atoms/input";
import Button from "../../atoms/button";
import DeleteQuoteRequestAlert from "../../organisms/delete-quote-request-modal";
import RelaunchTimerAlert from "../../organisms/relaunch-timer-alert";
import IncreaseTimerAlert, {
  IFormInputs,
} from "../../organisms/increase-timer-alert";
import MultipleChoiceQuestion from "../../molecules/questions/multiple-choice-question-input";
import {
  MULTIPLE_CHOICES_FRAGMENT,
  MULTIPLE_CHOICE_FRAGMENT,
  NUMBER_QUESTION_FRAGMENT,
  TEXT_QUESTION_FRAGMENT,
} from "../../organisms/submit-quote-request-modal";
import MultipleChoicesQuestion from "../../molecules/questions/multiple-choices-question-input";
import TextQuestionInput from "../../molecules/questions/text-question-input";
import NumberQuestion from "../../molecules/questions/number-question-input";

const QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT = gql`
  ${CommentsCard.COMMENT_FRAGMENT}
  ${NUMBER_QUESTION_FRAGMENT}
  ${MULTIPLE_CHOICE_FRAGMENT}
  ${MULTIPLE_CHOICES_FRAGMENT}
  ${TEXT_QUESTION_FRAGMENT}

  fragment QuoteRequestPageExpert on Expert {
    id
    phoneNumber
    fullName
    profilePictureURL
  }

  fragment QuoteRequestPageQuoteOffer on QuoteOffer {
    id
    status
    declineReasons
    createdAt
    expiresAt
    expiredAt
    expert {
      id
      ...QuoteRequestPageExpert
    }
    expertTrackingUrl
  }

  fragment QuoteRequestPageMission on Mission {
    id
    date
    retailerProvidedDetails
    price {
      TTC {
        amount
        currencyCode
      }
    }
  }

  fragment QuoteRequestPageCoupon on Coupon {
    __typename
    ... on PlatformCoupon {
      code
    }
  }

  fragment QuoteRequestPageQuestion on Question {
    ... on TextQuestion {
      ...TextQuestion
    }
    ... on NumberQuestion {
      ...NumberQuestion
    }
    ... on MultipleChoiceQuestion {
      ...MultipleChoiceQuestion
    }
    ... on MultipleChoicesQuestion {
      ...MultipleChoicesQuestion
    }
  }

  fragment QuoteRequestPageQuoteRequestQuestionAnswer on QuoteRequestQuestionAnswer {
    ... on StringQuoteRequestQuestionAnswer {
      questionReference
      labels
      question {
        ...QuoteRequestPageQuestion
      }
    }
    ... on NumberQuoteRequestQuestionAnswer {
      questionReference
      value
      question {
        ...QuoteRequestPageQuestion
      }
    }
  }

  fragment QuoteRequestPageQuoteRequest on QuoteRequest {
    id
    customerLastName
    customerFirstName
    customerEmailAddress
    customerPhoneNumber
    customerCorporateName
    customerVatNumber
    date
    billingAddress {
      street1
      zipCode
      city
      country
    }
    address {
      street1
      zipCode
      city
      country
      postalFormat
    }
    offer {
      id
      name
      requirements
    }
    createdAt
    status
    retailer {
      id
      name
    }
    details
    quoteOffers {
      ...QuoteRequestPageQuoteOffer
    }
    upcomingMatchingExperts {
      id
      ...QuoteRequestPageExpert
    }
    comments {
      ...CommentsCardComment
    }
    mission {
      coupon {
        ...QuoteRequestPageCoupon
      }
      ...QuoteRequestPageMission
    }
    customerSuccessReminderDate
    quoteRequestQuestionsAnswers {
      ...QuoteRequestPageQuoteRequestQuestionAnswer
    }
    mediaFiles {
      mediaFileId
      mediaFileUrl
    }
    customerTrackingUrl
    quote {
      grossPrice {
        amount
        currencyCode
      }
      grossExpertProfit {
        amount
        currencyCode
      }
      grossServiceFees {
        amount
        currencyCode
      }
    }
  }
`;

const QUOTE_REQUEST_PAGE_QUERY = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  query QuoteRequestPage($quoteRequestId: String!) {
    quoteRequest(id: $quoteRequestId) {
      ...QuoteRequestPageQuoteRequest
    }
  }
`;

const SUBMIT_QUOTE_REQUEST_COMMENT_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation QuoteRequestPageSubmitQuoteRequestComment(
    $quoteRequestId: UUID!
    $content: String!
  ) {
    submitQuoteRequestComment(
      quoteRequestId: $quoteRequestId
      content: $content
    ) {
      ... on SubmitQuoteRequestComment_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
    }
  }
`;

const CANCEL_QUOTE_REQUEST_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation CancelQuoteRequest(
    $quoteRequestId: UUID!
    $cancelationReason: CanceledQuoteRequest_CancelationReason!
    $cancelationComment: String!
  ) {
    cancelQuoteRequest(
      quoteRequestId: $quoteRequestId
      cancelationReason: $cancelationReason
      cancelationComment: $cancelationComment
    ) {
      ... on CancelQuoteRequest_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
      ... on CancelQuoteRequest_Failure {
        reason
      }
    }
  }
`;

const DELETE_QUOTE_REQUEST_MUTATION = gql`
  mutation DeleteQuoteRequest($quoteRequestId: UUID!) {
    deleteQuoteRequest(quoteRequestId: $quoteRequestId) {
      __typename
      ... on DeleteQuoteRequest_Failure {
        reason
      }
    }
  }
`;

const SUBMIT_QUOTE_REQUEST_EDITION_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation QuoteRequestPageSubmitQuoteRequestEdition(
    $quoteRequestId: UUID!
    $lastName: String!
    $firstName: String!
    $email: String!
    $phoneNumber: String!
    $address: SubmitQuoteRequestEdition_Address!
    $date: String!
    $csReminderDate: String!
    $customerDetails: String!
    $mediaFiles: [SubmitQuoteRequestEdition_MediaFiles]
    $questionsAnswers: [SubmitQuoteRequestEdition_QuestionAnswer!]
  ) {
    submitQuoteRequestEdition(
      quoteRequestId: $quoteRequestId
      lastName: $lastName
      firstName: $firstName
      email: $email
      phoneNumber: $phoneNumber
      address: $address
      date: $date
      csReminderDate: $csReminderDate
      customerDetails: $customerDetails
      mediaFiles: $mediaFiles
      questionsAnswers: $questionsAnswers
    ) {
      ... on SubmitQuoteRequestEdition_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
      ... on SubmitQuoteRequestEdition_Failure {
        reason
      }
    }
  }
`;

const ASSIGN_EXPERT_TO_QUOTE_REQUEST_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation AssignExpertToQuoteRequest(
    $quoteRequestId: String!
    $expertId: String!
  ) {
    assignExpertToQuoteRequest(
      quoteRequestId: $quoteRequestId
      expertId: $expertId
    ) {
      ... on AssignExpertToQuoteRequest_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
      ... on AssignExpertToQuoteRequest_Failure {
        reason
      }
    }
  }
`;

const RELAUNCH_TIMER_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation RelaunchTimerQuoteOffer($quoteRequestId: String!) {
    relaunchTimerQuoteOffer(quoteRequestId: $quoteRequestId) {
      ... on RelaunchTimerQuoteOffer_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
      ... on RelaunchTimerQuoteOffer_Failure {
        reason
      }
    }
  }
`;

const INCREASE_TIMER_MUTATION = gql`
  ${QUOTE_REQUEST_PAGE_QUOTE_REQUEST_FRAGMENT}

  mutation IncreaseTimerQuoteOffer(
    $quoteRequestId: String!
    $timerIncrease: Int!
  ) {
    increaseTimerQuoteOffer(
      quoteRequestId: $quoteRequestId
      timerIncrease: $timerIncrease
    ) {
      ... on IncreaseTimerQuoteOffer_Success {
        quoteRequest {
          ...QuoteRequestPageQuoteRequest
        }
      }
      ... on IncreaseTimerQuoteOffer_Failure {
        reason
      }
    }
  }
`;

const DECLINE_REASON_MAP: Record<QuoteOfferDeclineReason, string> = {
  NOT_AVAILABLE: "pas disponible",
  NOT_ENOUGH_DETAILS: "pas assez de details",
  NOT_SKILLED: "pas compétent",
  TOO_FAR: "trop loin",
};

const QuoteOfferStatusOfferStackedListItemRightSection: React.FC<{
  quoteOffer: QuoteRequestPageQuoteOfferFragment;
}> = ({ quoteOffer }) => {
  const intl = useIntl();

  switch (quoteOffer.status) {
    case "ACCEPTED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-green-400"
                aria-hidden="true"
              />
              Acceptée
            </>
          }
        />
      );
    case "NEED_INFORMATION":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <QuestionMarkCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-yellow-400"
                aria-hidden="true"
              />
              Besoin d&apos;information
            </>
          }
        />
      );
    case "ANSWERED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-green-400"
                aria-hidden="true"
              />
              Répondue
            </>
          }
        />
      );
    case "SUBMITTED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <CheckCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-green-400"
                aria-hidden="true"
              />
              Envoyée
              {quoteOffer.expiresAt && (
                <>
                  , expire&nbsp;
                  <span
                    title={intl.formatDate(quoteOffer.expiresAt, {
                      dateStyle: "full",
                      timeStyle: "long",
                    })}
                  >
                    <FormattedRelativeTime
                      value={differenceInSeconds(
                        new Date(quoteOffer.expiresAt),
                        new Date()
                      )}
                      updateIntervalInSeconds={1}
                    />
                  </span>
                </>
              )}{" "}
            </>
          }
        />
      );
    case "DECLINED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Refusée par l&apos;expert
            </>
          }
          subtitle={`Raison(s): ${quoteOffer.declineReasons
            ?.map((declineReason) => DECLINE_REASON_MAP[declineReason])
            ?.join(", ")}`}
        />
      );
    case "CANCELED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Annulée
            </>
          }
        />
      );
    case "EXPIRED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Expirée
              {quoteOffer.expiresAt && (
                <>
                  {" "}
                  le{" "}
                  <FormattedDate
                    value={quoteOffer.expiresAt}
                    dateStyle="full"
                    timeStyle="long"
                  />
                </>
              )}
            </>
          }
        />
      );
    case "REJECTED":
      return (
        <OfferStackedListItem.RightSection
          title={
            <>
              <XCircleIcon
                className="mr-1.5 h-5 w-5 shrink-0 text-red-400"
                aria-hidden="true"
              />
              Rejettée par le client
            </>
          }
        />
      );
  }
};

const CouponDataTableRowValueCell: React.FC<{
  coupon: QuoteRequestPageCouponFragment;
}> = ({ coupon }) => {
  if (!coupon) {
    return <>-</>;
  }

  if (coupon.__typename === "PlatformCoupon") {
    return <>{coupon.code}</>;
  }
  return <>-</>;
};

interface QuoteRequestMediaFile {
  mediaFileId?: string;
  mediaFileName?: string;
  mediaFileBuffer?: string;
  mediaFileType?: string;
  mediaFileUrl?: string;
}

const DataTableRow: React.FC<{
  title: React.ReactNode;
}> = ({ title, children }) => (
  <Table.Row>
    <Table.DataCell className="w-[1%] text-sm font-medium text-gray-500">
      {title}
    </Table.DataCell>
    <Table.DataCell className="text-sm">{children}</Table.DataCell>
  </Table.Row>
);

const DataCard: React.FC<{
  editionValues: {
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber: string;
    street: string;
    zipCode: string;
    city: string;
    country: string;
    date: string;
    csReminderDate: string;
    customerDetails: string;
  };
  setEditionValues: Dispatch<
    SetStateAction<{
      lastName: string;
      firstName: string;
      email: string;
      phoneNumber: string;
      street: string;
      zipCode: string;
      city: string;
      country: string;
      date: string;
      csReminderDate: string;
      customerDetails: string;
    }>
  >;
  onMediaFileAdded: (mediaFile: QuoteRequestMediaFile) => void;
  onMediaFileDeleted: (index: number) => void;
  mediaFiles: QuoteRequestMediaFile[];
  edition: boolean;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
  onQuestionAnswersChange: (
    reference: string,
    value: string,
    typeName?: string
  ) => void;
  questionsAnswers: Map<string, string[]>;
}> = ({
  editionValues,
  setEditionValues,
  onMediaFileAdded,
  onMediaFileDeleted,
  mediaFiles,
  edition,
  quoteRequest,
  onQuestionAnswersChange,
  questionsAnswers,
}) => {
  const editionChangeHandler = (e: { target: { name: any; value: any } }) => {
    setEditionValues({ ...editionValues, [e.target.name]: e.target.value });
  };
  const hiddenFileInputRef = React.useRef<HTMLInputElement>(null);
  const [mediaUploadError, displayMediaUploadError] = useState("");
  const [mediaFilesList, setMediaFilesList] =
    useState<QuoteRequestMediaFile[]>(mediaFiles);

  const handleProfilePictureInputChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.files) {
        return;
      }

      const file = event.currentTarget.files[0];
      if (
        file &&
        !mediaFilesList.some((item) => file.name === item.mediaFileName)
      ) {
        // don't submit if file is greater than 5MB
        if (file.size > 5 * 1024 * 1024) {
          displayMediaUploadError(
            "Le fichier est trop volumineux pour être chargé. (5MB max)"
          );
          return;
        }
        const arrayBuffer = await file.arrayBuffer();
        if (!arrayBuffer) {
          return;
        }
        const buffer = Buffer.from(arrayBuffer);
        if (!buffer) {
          return;
        }

        setMediaFilesList([
          ...mediaFilesList,
          {
            mediaFileName: file.name,
            mediaFileBuffer: buffer.toString("base64"),
            mediaFileType: file.type,
            mediaFileUrl: undefined,
          },
        ]);
        onMediaFileAdded({
          mediaFileName: file.name,
          mediaFileBuffer: buffer.toString("base64"),
          mediaFileType: file.type,
        });
        displayMediaUploadError("");
      }
    },
    [mediaFilesList, onMediaFileAdded]
  );

  const onItemTrachClicked = useCallback(
    (index: number) => {
      const array = [...mediaFilesList];
      array.splice(index, 1);
      setMediaFilesList(array);
      onMediaFileDeleted(index);
      displayMediaUploadError("");
    },
    [mediaFilesList, onMediaFileDeleted]
  );

  return (
    <Card>
      <Card.Header title="Informations" />
      <Card.Section title="Client">
        <DataGrid>
          <DataGrid.Cell title="Nom">
            {edition && quoteRequest.status !== "DRAFT" ? (
              <Input
                className="w-full border-gray-300"
                id="lastName"
                name="lastName"
                onChange={editionChangeHandler}
                defaultValue={quoteRequest.customerLastName ?? ""}
                type="text"
              />
            ) : (
              quoteRequest.customerLastName ?? "-"
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Email">
            {edition && quoteRequest.status !== "DRAFT" ? (
              <Input
                className="w-full border-gray-300"
                id="email"
                name="email"
                onChange={editionChangeHandler}
                defaultValue={quoteRequest.customerEmailAddress ?? ""}
                type="email"
              />
            ) : quoteRequest.customerEmailAddress ? (
              <a href={`mailto:${quoteRequest.customerEmailAddress}`}>
                {quoteRequest.customerEmailAddress}
              </a>
            ) : (
              "-"
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Prénom">
            {edition && quoteRequest.status !== "DRAFT" ? (
              <Input
                className="w-full border-gray-300"
                id="firstName"
                name="firstName"
                onChange={editionChangeHandler}
                defaultValue={quoteRequest.customerFirstName ?? ""}
                type="text"
              />
            ) : (
              quoteRequest.customerFirstName ?? "-"
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Téléphone">
            {!edition && (
              <a href={`tel:${quoteRequest.customerPhoneNumber}`}>
                {quoteRequest.customerPhoneNumber}
              </a>
            )}
            {edition && (
              <Input
                className="w-full border-gray-300"
                id="phoneNumber"
                name="phoneNumber"
                onChange={editionChangeHandler}
                defaultValue={quoteRequest.customerPhoneNumber}
                type="text"
              />
            )}
          </DataGrid.Cell>
          <DataGrid.Cell title="Adresse">
            {!edition && quoteRequest.address.postalFormat}
          </DataGrid.Cell>
          <DataGrid.Cell title="Dénomination sociale">
            {!edition && quoteRequest.customerCorporateName}
          </DataGrid.Cell>
        </DataGrid>
        {edition && (
          <div className="flex flex-wrap">
            <Input
              className="border-gray-300 w-1/3"
              id="street"
              name="street"
              onChange={editionChangeHandler}
              defaultValue={quoteRequest.address.street1 ?? ""}
              type="text"
              placeholder="Street"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="zipCode"
              name="zipCode"
              onChange={editionChangeHandler}
              defaultValue={quoteRequest.address.zipCode}
              type="text"
              placeholder="Post code"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="city"
              name="city"
              onChange={editionChangeHandler}
              defaultValue={quoteRequest.address.city ?? ""}
              type="text"
              placeholder="City"
            />
            <Input
              className="border-gray-300 w-1/5 ml-2"
              id="country"
              name="country"
              onChange={editionChangeHandler}
              defaultValue={quoteRequest.address.country}
              type="text"
              placeholder="Country"
            />
          </div>
        )}
      </Card.Section>
      <Card.Section title="Prestation" padded={false}>
        <Table>
          <Table.Body>
            <DataTableRow title="Date de relance CS">
              {!edition &&
                (quoteRequest.customerSuccessReminderDate ? (
                  <FormattedDate
                    value={quoteRequest.customerSuccessReminderDate}
                  />
                ) : (
                  "-"
                ))}
              {edition && (
                <Input
                  className="w-full border-gray-300"
                  id="csReminderDate"
                  name="csReminderDate"
                  onChange={editionChangeHandler}
                  defaultValue={
                    quoteRequest.customerSuccessReminderDate
                      ? quoteRequest.customerSuccessReminderDate.slice(0, 10)
                      : ""
                  }
                  type="date"
                />
              )}
            </DataTableRow>
            <DataTableRow title="Offre">{quoteRequest.offer.name}</DataTableRow>

            <DataTableRow title="Rémunération Expert">
              {quoteRequest.quote?.grossExpertProfit ? (
                <FormattedNumber
                  style="currency"
                  value={quoteRequest.quote?.grossExpertProfit.amount}
                  currency={quoteRequest.quote?.grossExpertProfit.currencyCode}
                />
              ) : (
                "-"
              )}
            </DataTableRow>

            <DataTableRow title="Frais de service">
              {quoteRequest.quote?.grossServiceFees ? (
                <FormattedNumber
                  style="currency"
                  value={quoteRequest.quote?.grossServiceFees.amount}
                  currency={quoteRequest.quote?.grossServiceFees.currencyCode}
                />
              ) : (
                "-"
              )}
            </DataTableRow>

            <DataTableRow title="Prix Total TTC">
              {quoteRequest.quote?.grossPrice ? (
                <FormattedNumber
                  style="currency"
                  value={quoteRequest.quote?.grossPrice.amount}
                  currency={quoteRequest.quote?.grossPrice.currencyCode}
                />
              ) : (
                "-"
              )}
            </DataTableRow>

            <DataTableRow title="Code coupon">
              {quoteRequest.mission?.coupon ? (
                <CouponDataTableRowValueCell
                  coupon={quoteRequest.mission?.coupon}
                />
              ) : (
                "-"
              )}
            </DataTableRow>

            <DataTableRow title="Date de création">
              <FormattedDate value={quoteRequest.createdAt} />
            </DataTableRow>
            <DataTableRow title="Date d'intervention">
              {!edition &&
                (quoteRequest.date ? (
                  <FormattedDate value={quoteRequest.date} />
                ) : (
                  "-"
                ))}
              {edition && (
                <Input
                  className="w-full border-gray-300"
                  id="date"
                  name="date"
                  onChange={editionChangeHandler}
                  defaultValue={
                    quoteRequest.date ? quoteRequest.date.slice(0, 10) : ""
                  }
                  type="date"
                />
              )}
            </DataTableRow>
            <DataTableRow title="Statut">{quoteRequest.status}</DataTableRow>
            <DataTableRow title="Lien du devis">
              <a
                href={quoteRequest.customerTrackingUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-[blue]"
              >
                {quoteRequest.customerTrackingUrl}
              </a>
            </DataTableRow>
            <DataTableRow title="Retailer">
              {quoteRequest.retailer.name}
            </DataTableRow>
            <DataTableRow title="Détails client">
              {!edition && (quoteRequest.details ?? "-")}
              {edition && (
                <Input
                  className="w-full border-gray-300"
                  id="customerDetails"
                  name="customerDetails"
                  onChange={editionChangeHandler}
                  defaultValue={quoteRequest.details ?? ""}
                  type="text"
                />
              )}
            </DataTableRow>
            <DataTableRow title="Détails retailer">
              {quoteRequest.mission?.retailerProvidedDetails ?? "-"}
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.conditions"
                  defaultMessage="Conditions d’exécutions"
                />
              }
            >
              <ul className="list-inside list-disc">
                {quoteRequest.offer.requirements.map((requirement) => (
                  <li key={requirement}>{requirement}</li>
                ))}
              </ul>
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.questions.answers"
                  defaultMessage="Questions / Réponses"
                />
              }
            >
              {quoteRequest.quoteRequestQuestionsAnswers.length > 0 ? (
                <ul className="list-inside list-disc">
                  {quoteRequest.quoteRequestQuestionsAnswers.map(
                    (questionAnswer, i) => (
                      <>
                        <li key={i} className="font-medium">
                          {questionAnswer.question?.label}
                        </li>
                        {questionAnswer.__typename ===
                        "NumberQuoteRequestQuestionAnswer" ? (
                          <div
                            key={questionAnswer.value}
                            className="italic px-5"
                          >
                            {!edition && questionAnswer.value}
                            {edition &&
                              questionAnswer.question?.__typename ===
                                "NumberQuestion" && (
                                <NumberQuestion
                                  question={questionAnswer.question}
                                  onChange={onQuestionAnswersChange}
                                  value={questionsAnswers}
                                />
                              )}
                          </div>
                        ) : (
                          questionAnswer.__typename ===
                            "StringQuoteRequestQuestionAnswer" &&
                          questionAnswer.labels.map((answer) => (
                            <div key={answer} className="italic px-5">
                              {!edition && answer}
                            </div>
                          ))
                        )}

                        {edition &&
                          questionAnswer.question?.__typename ===
                            "MultipleChoiceQuestion" && (
                            <MultipleChoiceQuestion
                              question={questionAnswer.question}
                              onChange={onQuestionAnswersChange}
                              value={questionsAnswers}
                            />
                          )}
                        {edition &&
                          questionAnswer.question?.__typename ===
                            "MultipleChoicesQuestion" && (
                            <MultipleChoicesQuestion
                              question={questionAnswer.question}
                              onChange={onQuestionAnswersChange}
                              value={questionsAnswers}
                            />
                          )}
                        {edition &&
                          questionAnswer.question?.__typename ===
                            "TextQuestion" && (
                            <TextQuestionInput
                              question={questionAnswer.question}
                              onChange={onQuestionAnswersChange}
                              value={questionsAnswers}
                            />
                          )}
                        <br />
                      </>
                    )
                  )}
                </ul>
              ) : (
                "-"
              )}
            </DataTableRow>
            <DataTableRow
              title={
                <FormattedMessage
                  id="backoffice.fields.medias"
                  defaultMessage="Pièces jointes"
                />
              }
            >
              {mediaFilesList.length > 0 ? (
                <ul className="list-inside list-disc">
                  {mediaFilesList.map((mediaFile, i) => (
                    <div key={i} className="flex flex-row">
                      <li className="font-medium">
                        {mediaFile.mediaFileName ? (
                          mediaFile.mediaFileName
                        ) : (
                          <a
                            href={mediaFile.mediaFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="underline text-blue-600"
                          >{`Fichier ${i + 1}`}</a>
                        )}
                      </li>
                      {edition && (
                        <div className="ml-3">
                          <TrashIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            onClick={() => onItemTrachClicked(i)}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
              ) : (
                "-"
              )}
              {edition && mediaUploadError && (
                <div className="mt-2 flex flex-row text-red-600">
                  <ExclamationCircleIcon
                    className="h-5 w-5 mr-2"
                    aria-hidden="true"
                  />
                  {mediaUploadError}
                </div>
              )}
              {edition && mediaFilesList.length !== 5 && (
                <Button
                  className="mt-2"
                  onClick={() => {
                    hiddenFileInputRef?.current?.click();
                  }}
                >
                  Ajouter
                </Button>
              )}
              <input
                name="profile_picture"
                id="profile_picture"
                type="file"
                className="hidden"
                accept="image/png, image/jpeg, application/pdf"
                onChange={(event) => {
                  handleProfilePictureInputChange(event);
                  event.target.value = "";
                }}
                disabled={mediaFilesList.length === 5}
                ref={hiddenFileInputRef}
              />
            </DataTableRow>
          </Table.Body>
        </Table>
      </Card.Section>
    </Card>
  );
};

const _CancelQuoteRequestAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
}> = ({ quoteRequest, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [handleCancelQuoteRequestMutation] = useMutation<
    CancelQuoteRequestMutation,
    MutationCancelQuoteRequestArgs
  >(CANCEL_QUOTE_REQUEST_MUTATION);
  const handleCancelQuoteRequest = useCallback(
    async (
      choice: CanceledQuoteRequest_CancelationReason,
      cancelationComment: string
    ) => {
      const result = await handleCancelQuoteRequestMutation({
        variables: {
          cancelationReason: choice,
          quoteRequestId: quoteRequest.id,
          cancelationComment,
        },
      });

      if (
        result.data?.cancelQuoteRequest.__typename ===
        "CancelQuoteRequest_Success"
      ) {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast onCloseClick={onClose} body="Devis annulé" />
        ));
      } else if (
        result.data?.cancelQuoteRequest.__typename ===
        "CancelQuoteRequest_Failure"
      ) {
        const reason = result.data?.cancelQuoteRequest?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [handleCancelQuoteRequestMutation, quoteRequest.id, onClose, toaster]
  );

  return (
    <CancelQuoteRequestAlert
      show={show}
      onClose={onClose}
      onSubmit={handleCancelQuoteRequest}
    />
  );
};

const _AssignExpertAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
}> = ({ quoteRequest, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [assignExpertToQuoteRequestMutation] = useMutation<
    AssignExpertToQuoteRequestMutation,
    AssignExpertToQuoteRequestMutationVariables
  >(ASSIGN_EXPERT_TO_QUOTE_REQUEST_MUTATION);
  const handleAssignExpert = useCallback(
    async (expertId: string) => {
      const result = await assignExpertToQuoteRequestMutation({
        variables: {
          quoteRequestId: quoteRequest.id,
          expertId,
        },
      });
      if (
        result.data?.assignExpertToQuoteRequest.__typename ===
        "AssignExpertToQuoteRequest_Success"
      ) {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast onCloseClick={onClose} body="Expert assigné" />
        ));
      } else if (
        result.data?.assignExpertToQuoteRequest.__typename ===
        "AssignExpertToQuoteRequest_Failure"
      ) {
        const reason = result.data?.assignExpertToQuoteRequest?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [assignExpertToQuoteRequestMutation, quoteRequest.id, onClose, toaster]
  );

  return (
    <AssignExpertAlert
      show={show}
      onClose={onClose}
      onSubmit={handleAssignExpert}
    />
  );
};

const _RelaunchTimerAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
}> = ({ quoteRequest, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [relaunchTimerQuoteOfferMutation] = useMutation<
    RelaunchTimerQuoteOfferMutation,
    RelaunchTimerQuoteOfferMutationVariables
  >(RELAUNCH_TIMER_MUTATION);
  const relaunchTimer = useCallback(async () => {
    const result = await relaunchTimerQuoteOfferMutation({
      variables: {
        quoteRequestId: quoteRequest.id,
      },
    });
    if (
      result.data?.relaunchTimerQuoteOffer.__typename ===
      "RelaunchTimerQuoteOffer_Success"
    ) {
      onClose();
      toaster.push(({ onClose }) => (
        <Toaster.SuccessToast onCloseClick={onClose} body="temps relancé" />
      ));
    } else if (
      result.data?.relaunchTimerQuoteOffer.__typename ===
      "RelaunchTimerQuoteOffer_Failure"
    ) {
      const reason = result.data?.relaunchTimerQuoteOffer?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [relaunchTimerQuoteOfferMutation, quoteRequest.id, onClose, toaster]);

  return (
    <RelaunchTimerAlert
      show={show}
      onClose={onClose}
      onSubmit={relaunchTimer}
    />
  );
};

const _IncreaseTimerAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
}> = ({ quoteRequest, show = false, onClose }) => {
  const toaster = Toaster.useToaster();

  const [increaseTimerQuoteOfferMutation] = useMutation<
    IncreaseTimerQuoteOfferMutation,
    IncreaseTimerQuoteOfferMutationVariables
  >(INCREASE_TIMER_MUTATION);
  const relaunchTimer = useCallback(
    async (data: IFormInputs) => {
      const result = await increaseTimerQuoteOfferMutation({
        variables: {
          quoteRequestId: quoteRequest.id,
          timerIncrease: data.choice,
        },
      });
      if (
        result.data?.increaseTimerQuoteOffer.__typename ===
        "IncreaseTimerQuoteOffer_Success"
      ) {
        onClose();
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast
            onCloseClick={onClose}
            body={`temps augmenté de ${data.choice}h`}
          />
        ));
      } else if (
        result.data?.increaseTimerQuoteOffer.__typename ===
        "IncreaseTimerQuoteOffer_Failure"
      ) {
        const reason = result.data?.increaseTimerQuoteOffer?.reason;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [increaseTimerQuoteOfferMutation, quoteRequest.id, onClose, toaster]
  );

  return (
    <IncreaseTimerAlert
      show={show}
      onClose={onClose}
      onSubmit={relaunchTimer}
    />
  );
};

const _DeleteQuoteRequestAlert: React.FC<{
  show?: boolean;
  onClose: () => void;
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
}> = ({ quoteRequest, show = false, onClose }) => {
  const toaster = Toaster.useToaster();
  const history = useHistory();

  const [handleDeleteQuoteRequestMutation] = useMutation<
    DeleteQuoteRequestMutation,
    MutationDeleteQuoteRequestArgs
  >(DELETE_QUOTE_REQUEST_MUTATION);
  const handleDeleteQuoteRequest = useCallback(async () => {
    const result = await handleDeleteQuoteRequestMutation({
      variables: {
        quoteRequestId: quoteRequest.id,
      },
    });

    if (
      result.data?.deleteQuoteRequest.__typename ===
      "DeleteQuoteRequest_Success"
    ) {
      history.push("/quotes/draft");
      toaster.push(({ onClose }) => (
        <Toaster.SuccessToast onCloseClick={onClose} body="Devis supprimé" />
      ));
    } else if (
      result.data?.deleteQuoteRequest.__typename ===
      "DeleteQuoteRequest_Failure"
    ) {
      const reason = result.data?.deleteQuoteRequest?.reason;
      toaster.push(({ onClose }) => (
        <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
      ));
    }
  }, [handleDeleteQuoteRequestMutation, quoteRequest.id, history, toaster]);

  return (
    <DeleteQuoteRequestAlert
      show={show}
      onClose={onClose}
      onSubmit={handleDeleteQuoteRequest}
    />
  );
};

const _QuoteRequestPage: React.FC<{
  quoteRequest: QuoteRequestPageQuoteRequestFragment;
  onSubmitComment: (content: string) => void;
}> = ({ quoteRequest, onSubmitComment }) => {
  const [openCancelQuoteRequestAlert, setOpenCancelQuoteRequestAlert] =
    useState(false);
  const [openDeleteQuoteRequestAlert, setOpenDeleteQuoteRequestAlert] =
    useState(false);
  const [openAssignExpertAlert, setOpenAssignExpertAlert] = useState(false);
  const [openRelaunchTimerAlert, setOpenRelaunchTimerAlert] = useState(false);
  const [openIncreaseTimerAlert, setOpenIncreaseTimerAlert] = useState(false);
  const backOfficeUser = GetBackOfficeUser();
  const [edition, setEdition] = useState(false);
  const [editionValues, setEditionValues] = useState({
    lastName: "",
    firstName: "",
    email: "",
    phoneNumber: "",
    street: "",
    zipCode: "",
    city: "",
    country: "",
    date: "",
    csReminderDate: "",
    customerDetails: "",
  });

  const mediaFiles: QuoteRequestMediaFile[] = useMemo(() => {
    return quoteRequest.mediaFiles.map((mediaFile) => {
      return {
        mediaFileId: mediaFile.mediaFileId,
        mediaFileName: undefined,
        mediaFileBuffer: undefined,
        mediaFileType: undefined,
        mediaFileUrl: mediaFile.mediaFileUrl,
      };
    });
  }, [quoteRequest.mediaFiles]);
  const onMediaFileAdded = useCallback(
    (mediaFile: QuoteRequestMediaFile) => {
      mediaFiles.push({
        mediaFileName: mediaFile.mediaFileName,
        mediaFileBuffer: mediaFile.mediaFileBuffer,
        mediaFileType: mediaFile.mediaFileType,
      });
    },
    [mediaFiles]
  );
  const onMediaFileDeleted = useCallback(
    (index: number) => {
      mediaFiles.splice(index, 1);
    },
    [mediaFiles]
  );
  const questionsAnswers = useMemo(() => {
    const questionsAnswersValues = new Map<string, string[]>();
    quoteRequest.quoteRequestQuestionsAnswers.map((questionsAnswers) => {
      if (questionsAnswers.__typename === "StringQuoteRequestQuestionAnswer") {
        if (
          questionsAnswers.question?.__typename === "MultipleChoiceQuestion" ||
          questionsAnswers.question?.__typename === "MultipleChoicesQuestion"
        ) {
          const options = questionsAnswers.question.options;
          questionsAnswersValues.set(
            questionsAnswers.questionReference,
            questionsAnswers.labels.map((label) => {
              const option = options.find((obj) => {
                return obj.label === label;
              });
              return option?.value ?? "";
            })
          );
        } else {
          questionsAnswersValues.set(
            questionsAnswers.questionReference,
            questionsAnswers.labels.map((label) => label)
          );
        }
      }
      if (questionsAnswers.__typename === "NumberQuoteRequestQuestionAnswer") {
        questionsAnswersValues.set(questionsAnswers.questionReference, [
          questionsAnswers.value.toString(),
        ]);
      }
    });
    return questionsAnswersValues;
  }, [quoteRequest.quoteRequestQuestionsAnswers]);
  const onQuestionAnswersChange = useCallback(
    (reference: string, value: string, typeName?: string) => {
      if (typeName && typeName === "MultipleChoicesQuestion") {
        const choicesSelected = questionsAnswers.get(reference);
        if (choicesSelected) {
          const index = choicesSelected.indexOf(value, 0);
          if (index > -1) {
            choicesSelected.splice(index, 1);
          } else {
            choicesSelected.push(value);
          }
        } else {
          questionsAnswers.set(reference, [value]);
        }
      } else if (typeName && typeName === "TextQuestion" && value === "") {
        questionsAnswers.delete(reference);
      } else {
        questionsAnswers.set(reference, [value]);
      }
    },
    [questionsAnswers]
  );

  function createQuestionsAnswers(reference: string, values: string[]) {
    return {
      reference,
      values,
    };
  }

  const toaster = Toaster.useToaster();
  const [submitQuoteRequestEditionMutation] = useMutation<
    QuoteRequestPageSubmitQuoteRequestEditionMutation,
    QuoteRequestPageSubmitQuoteRequestEditionMutationVariables
  >(SUBMIT_QUOTE_REQUEST_EDITION_MUTATION);
  const handleEditionSubmit = useCallback(
    async ({
      lastName,
      firstName,
      email,
      phoneNumber,
      street,
      zipCode,
      city,
      country,
      date,
      csReminderDate,
      customerDetails,
      mediaFiles,
      questionsAnswers,
    }: {
      lastName: string;
      firstName: string;
      email: string;
      phoneNumber: string;
      street: string;
      zipCode: string;
      city: string;
      country: string;
      date: string;
      csReminderDate: string;
      customerDetails: string;
      mediaFiles: QuoteRequestMediaFile[];
      questionsAnswers: { reference: string; values: string[] }[];
    }) => {
      const result = await submitQuoteRequestEditionMutation({
        variables: {
          quoteRequestId: quoteRequest.id,
          lastName,
          firstName,
          email,
          phoneNumber,
          address: {
            street,
            zipCode,
            city,
            country,
          },
          date,
          csReminderDate,
          customerDetails,
          mediaFiles,
          questionsAnswers,
        },
      });

      if (
        result.data?.submitQuoteRequestEdition.__typename ===
        "SubmitQuoteRequestEdition_Success"
      ) {
        toaster.push(({ onClose }) => (
          <Toaster.SuccessToast onCloseClick={onClose} body="Devis modifiée" />
        ));
        setEdition(false);
      } else if (
        result.data?.submitQuoteRequestEdition.__typename ===
        "SubmitQuoteRequestEdition_Failure"
      ) {
        const { reason } = result.data?.submitQuoteRequestEdition;
        toaster.push(({ onClose }) => (
          <Toaster.ErrorToast onCloseClick={onClose} body={reason} />
        ));
      }
    },
    [submitQuoteRequestEditionMutation, quoteRequest.id, toaster]
  );

  return (
    <div className="flex w-full flex-col">
      <_CancelQuoteRequestAlert
        show={openCancelQuoteRequestAlert}
        onClose={() => {
          setOpenCancelQuoteRequestAlert(false);
        }}
        quoteRequest={quoteRequest}
      />

      <_AssignExpertAlert
        show={openAssignExpertAlert}
        onClose={() => {
          setOpenAssignExpertAlert(false);
        }}
        quoteRequest={quoteRequest}
      />

      <_RelaunchTimerAlert
        show={openRelaunchTimerAlert}
        onClose={() => {
          setOpenRelaunchTimerAlert(false);
        }}
        quoteRequest={quoteRequest}
      />

      <_IncreaseTimerAlert
        show={openIncreaseTimerAlert}
        onClose={() => {
          setOpenIncreaseTimerAlert(false);
        }}
        quoteRequest={quoteRequest}
      />

      <_DeleteQuoteRequestAlert
        show={openDeleteQuoteRequestAlert}
        onClose={() => {
          setOpenDeleteQuoteRequestAlert(false);
        }}
        quoteRequest={quoteRequest}
      />

      {!backOfficeUser?.isViewer && (
        <div className="place-self-end">
          {quoteRequest.status !== "CANCELED" && (
            <>
              {edition && (
                <>
                  <button
                    onClick={() => {
                      const questionsAnswersValues = Array.from(
                        {} as {
                          reference: string;
                          values: string[];
                        }[]
                      );
                      questionsAnswers.forEach((values, key) => {
                        questionsAnswersValues.push(
                          createQuestionsAnswers(key, values)
                        );
                      });
                      handleEditionSubmit({
                        lastName: editionValues.lastName,
                        firstName: editionValues.firstName,
                        email: editionValues.email,
                        phoneNumber: editionValues.phoneNumber,
                        street: editionValues.street,
                        zipCode: editionValues.zipCode,
                        city: editionValues.city,
                        country: editionValues.country,
                        date: editionValues.date,
                        csReminderDate: editionValues.csReminderDate,
                        customerDetails: editionValues.customerDetails,
                        mediaFiles,
                        questionsAnswers: questionsAnswersValues,
                      });
                    }}
                    type="button"
                    className="bg-green-400 hover:bg-green-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                  >
                    <span className="w-12 z-10">Appliquer</span>
                  </button>
                  <button
                    type="button"
                    className="bg-red-400 hover:bg-red-500 text-white font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                    onClick={() => setEdition(false)}
                  >
                    <span className="w-12 z-10">Annuler</span>
                  </button>
                </>
              )}
              {!edition && (
                <button
                  type="button"
                  className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mr-2"
                  onClick={() => setEdition(true)}
                >
                  <span className="w-12 z-10">Modifier</span>
                </button>
              )}
            </>
          )}
          {(quoteRequest.status === "CONFIRMED" ||
            quoteRequest.status === "DRAFT") && (
            <DropDownMenu
              button={
                <DropDownMenu.Button className="rounded-lg border-2 border-blue-600 p-2 text-blue-600">
                  Actions
                </DropDownMenu.Button>
              }
            >
              {quoteRequest.status === "CONFIRMED" && (
                <>
                  {!quoteRequest.quoteOffers.find(
                    (quoteOffer) => quoteOffer.status === "ANSWERED"
                  ) && (
                    <DropDownMenu.ItemGroup>
                      <DropDownMenu.ButtonItem
                        onClick={() => {
                          setOpenAssignExpertAlert(true);
                        }}
                      >
                        Assigner un Expert
                      </DropDownMenu.ButtonItem>
                    </DropDownMenu.ItemGroup>
                  )}
                  {quoteRequest.quoteOffers.find(
                    (quoteOffer) => quoteOffer.status === "NEED_INFORMATION"
                  ) && (
                    <DropDownMenu.ItemGroup>
                      <DropDownMenu.ButtonItem
                        onClick={() => {
                          setOpenRelaunchTimerAlert(true);
                        }}
                      >
                        Relancer le temps et notifier l’expert
                      </DropDownMenu.ButtonItem>
                    </DropDownMenu.ItemGroup>
                  )}
                  {quoteRequest.quoteOffers.find(
                    (quoteOffer) => quoteOffer.status === "ACCEPTED"
                  ) && (
                    <DropDownMenu.ItemGroup>
                      <DropDownMenu.ButtonItem
                        onClick={() => {
                          setOpenIncreaseTimerAlert(true);
                        }}
                      >
                        Ajouter du temps à la candidature
                      </DropDownMenu.ButtonItem>
                    </DropDownMenu.ItemGroup>
                  )}
                  <DropDownMenu.ItemGroup>
                    <DropDownMenu.ButtonItem
                      onClick={() => {
                        setOpenCancelQuoteRequestAlert(true);
                      }}
                    >
                      Annuler le devis
                    </DropDownMenu.ButtonItem>
                  </DropDownMenu.ItemGroup>
                </>
              )}
              {quoteRequest.status === "DRAFT" && (
                <DropDownMenu.ItemGroup>
                  <DropDownMenu.ButtonItem
                    onClick={() => {
                      setOpenDeleteQuoteRequestAlert(true);
                    }}
                  >
                    Supprimer le devis
                  </DropDownMenu.ButtonItem>
                </DropDownMenu.ItemGroup>
              )}
            </DropDownMenu>
          )}
        </div>
      )}
      <div className="grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3 mt-4">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <DataCard
            editionValues={editionValues}
            setEditionValues={setEditionValues}
            edition={edition}
            quoteRequest={quoteRequest}
            onMediaFileAdded={onMediaFileAdded}
            onMediaFileDeleted={onMediaFileDeleted}
            mediaFiles={mediaFiles}
            onQuestionAnswersChange={onQuestionAnswersChange}
            questionsAnswers={questionsAnswers}
          />
          <Card>
            <Card.Header title="Offres" />
            <Card.Section padded={false} title="Envoyées">
              <StackedList>
                {sortBy(
                  quoteRequest.quoteOffers,
                  (quoteOffer) => quoteOffer.createdAt
                ).map((quoteOffer) => (
                  <OfferStackedListItem
                    key={quoteOffer.id}
                    expert={{
                      id: quoteOffer.expert.id,
                      name: quoteOffer.expert.fullName,
                      phoneNumber: quoteOffer.expert.phoneNumber,
                      profilePictureURL: quoteOffer.expert.profilePictureURL,
                      expertTrackingUrl: quoteOffer.expertTrackingUrl,
                    }}
                    rightSection={
                      <QuoteOfferStatusOfferStackedListItemRightSection
                        quoteOffer={quoteOffer}
                      />
                    }
                  />
                ))}
              </StackedList>
            </Card.Section>
            <Card.Section padded={false} title="À venir">
              <StackedList>
                {quoteRequest.upcomingMatchingExperts.map((expert) => (
                  <OfferStackedListItem
                    key={expert.id}
                    expert={{
                      id: expert.id,
                      name: expert.fullName,
                      phoneNumber: expert.phoneNumber,
                      profilePictureURL: expert.profilePictureURL,
                    }}
                  />
                ))}
              </StackedList>
            </Card.Section>
          </Card>
        </div>
        <div className="lg:col-span-1 lg:col-start-3">
          <CommentsCard
            onSubmitComment={onSubmitComment}
            comments={sortBy(
              quoteRequest.comments,
              (comment) => comment.createdAt
            ).map((comment) => (
              <CommentsCard.Comment key={comment.id} comment={comment} />
            ))}
          />
        </div>
      </div>
    </div>
  );
};

const QuoteRequestPage: React.FC<{ quoteRequestId: string }> = ({
  quoteRequestId,
}) => {
  const queryResult = useQuery<
    QuoteRequestPageQuery,
    QuoteRequestPageQueryVariables
  >(QUOTE_REQUEST_PAGE_QUERY, { variables: { quoteRequestId } });
  const [submitQuoteRequestCommentMutation] = useMutation<
    QuoteRequestPageSubmitQuoteRequestCommentMutation,
    QuoteRequestPageSubmitQuoteRequestCommentMutationVariables
  >(SUBMIT_QUOTE_REQUEST_COMMENT_MUTATION);
  const handleSubmitComment = useCallback(
    (content: string) => {
      submitQuoteRequestCommentMutation({
        variables: { content, quoteRequestId },
      });
    },
    [quoteRequestId, submitQuoteRequestCommentMutation]
  );

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (queryResult.loading) {
    return null;
  }

  if (!queryResult.data?.quoteRequest) {
    return <>Not Found</>;
  }

  return (
    <_QuoteRequestPage
      quoteRequest={queryResult.data.quoteRequest}
      onSubmitComment={handleSubmitComment}
    />
  );
};

export default QuoteRequestPage;
