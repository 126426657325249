import classNames from "classnames";
import React from "react";

const TextArea: React.FC<React.ComponentProps<"textarea">> = ({
  className,
  ...props
}) => (
  <textarea
    className={classNames(
      "rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm",
      className
    )}
    {...props}
  />
);

export default TextArea;
