import classNames from "classnames";
import React from "react";

const Item: React.FC<React.ComponentProps<"li">> = ({ children, ...props }) => (
  <li {...props}>
    <div className="px-4 py-4 sm:px-6">{children}</div>
  </li>
);

const StackedList: React.FC<React.ComponentProps<"div">> = ({
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames("overflow-hidden bg-white", className)}
      {...props}
    >
      <ul role="list" className="divide-y divide-gray-200">
        {children}
      </ul>
    </div>
  );
};

export default Object.assign(StackedList, { Item });
