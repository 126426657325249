import { gql, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  QuoteRequestQueryQuoteOfferStatus,
  QuoteRequestQueryQuoteRequestStatus,
  QuoteRequestQueryQuoteStatus,
  QuoteRequestsNumTabQuery,
  QuoteRequestsNumTabQueryVariables,
  QuoteRequestsPriorityNumTabQuery,
  QuoteRequestsPriorityNumTabQueryVariables,
} from "../../../graphql-operations-types";
import GetBackOfficeUser from "../../../login-provider";
import DropDownMenu from "../../molecules/drop-down-menu";
import TabBar from "../../molecules/tab-bar";
import { TabCount } from "../../molecules/tab-count";
import SubmitQuoteRequestModal from "../../organisms/submit-quote-request-modal";
import AcceptedQuoteRequestsTab from "./tabs/accepted";
import AllQuoteRequestsTab from "./tabs/all";
import DraftQuoteRequestsTab from "./tabs/draft";
import NeedInformationQuoteRequestsTab from "./tabs/need-information";
import PriorityQuoteRequestsTab from "./tabs/priority";
import ProposedQuoteRequestsTab from "./tabs/proposed";
import SubmittedQuoteRequestsTab from "./tabs/submitted";

const QUOTE_REQUESTS_NUM_TAB_QUERY = gql`
  query QuoteRequestsNumTab(
    $quoteRequestStatus: QuoteRequestQueryQuoteRequestStatus!
    $quoteOfferStatus: QuoteRequestQueryQuoteOfferStatus
    $quoteStatus: QuoteRequestQueryQuoteStatus
  ) {
    quoteRequestsByQuoteOfferStatus(
      quoteRequestStatus: $quoteRequestStatus
      quoteOfferStatus: $quoteOfferStatus
      quoteStatus: $quoteStatus
    ) {
      totalCount
    }
  }
`;

const QUOTE_REQUESTS_PRIORITY_NUM_TAB_QUERY = gql`
  query QuoteRequestsPriorityNumTab {
    priorityQuoteRequests {
      totalCount
    }
  }
`;

const QuoteRequestsPage: React.FC<{
  getQuoteRequestPageLocation: (props: {
    id: string;
  }) => React.ComponentProps<typeof Link>["to"];
}> = ({ getQuoteRequestPageLocation }) => {
  const routeMatch = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const [openSubmitQuoteRequestModal, setOpenSubmitQuoteRequestModal] =
    useState(false);
  const backOfficeUser = GetBackOfficeUser();
  const [fetchingCompleted, setFetchingCompleted] = useState(false);
  return (
    <div>
      <SubmitQuoteRequestModal
        open={openSubmitQuoteRequestModal}
        onSuccess={(quoteRequest) => {
          const getRequestPageLocationResult =
            getQuoteRequestPageLocation(quoteRequest);
          if (typeof getRequestPageLocationResult === "function") {
            const requestPageLocation = getRequestPageLocationResult(location);
            history.push(requestPageLocation);
          } else {
            history.push(getRequestPageLocationResult);
          }
        }}
        onRequestClose={() => {
          setOpenSubmitQuoteRequestModal(false);
        }}
      />
      <div className="flex flex-wrap items-center gap-4">
        <TabBar className="flex-1 overflow-x-auto" title="Devis">
          <TabBar.Tab to={`${routeMatch.url}/priority`}>
            Prioritaires
            <PriorityNumRequestsTab fetchingCompleted={fetchingCompleted} />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/need-information`}>
            Besoin d&apos;information
            <NumRequestsTab
              quoteRequestStatus="CONFIRMED"
              quoteOfferStatus="NEED_INFORMATION"
              fetchingCompleted={fetchingCompleted}
            />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/accepted`}>
            Acceptés
            <NumRequestsTab
              quoteRequestStatus="CONFIRMED"
              quoteOfferStatus="ACCEPTED"
              fetchingCompleted={fetchingCompleted}
            />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/draft`}>
            A transformer
            <NumRequestsTab
              quoteRequestStatus="DRAFT"
              fetchingCompleted={fetchingCompleted}
            />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/proposed`}>
            Proposition de devis
            <NumRequestsTab
              quoteRequestStatus="CONFIRMED"
              quoteOfferStatus="ANSWERED"
              quoteStatus="SUBMITTED"
              fetchingCompleted={fetchingCompleted}
            />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/submitted`}>
            Postés
            <NumRequestsTab
              quoteRequestStatus="CONFIRMED"
              quoteOfferStatus="SUBMITTED"
              fetchingCompleted={fetchingCompleted}
            />
          </TabBar.Tab>
          <TabBar.Tab to={`${routeMatch.url}/all`}>Tous</TabBar.Tab>
        </TabBar>
        {!backOfficeUser?.isViewer && (
          <div className="flex gap-3">
            <DropDownMenu
              button={
                <DropDownMenu.Button className="rounded-lg border-2 border-blue-600 p-2 text-blue-600">
                  Actions
                </DropDownMenu.Button>
              }
            >
              <DropDownMenu.ItemGroup className="absolute z-30 rounded bg-white shadow-md">
                <DropDownMenu.ButtonItem
                  onClick={() => {
                    setOpenSubmitQuoteRequestModal(true);
                  }}
                >
                  Soumettre une demande de devis
                </DropDownMenu.ButtonItem>
              </DropDownMenu.ItemGroup>
            </DropDownMenu>
          </div>
        )}
      </div>
      <div className="pt-6">
        <Switch>
          <Route exact path={routeMatch.path}>
            <Redirect to={`${routeMatch.url}/priority`} />
          </Route>
          <Route path={`${routeMatch.path}/priority`}>
            <PriorityQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
              onfetchingCompleted={() => setFetchingCompleted(true)}
            />
          </Route>
          <Route path={`${routeMatch.path}/need-information`}>
            <NeedInformationQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/all`}>
            <AllQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/submitted`}>
            <SubmittedQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/accepted`}>
            <AcceptedQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/draft`}>
            <DraftQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route path={`${routeMatch.path}/proposed`}>
            <ProposedQuoteRequestsTab
              getQuoteRequestPageLocation={getQuoteRequestPageLocation}
            />
          </Route>
          <Route>
            <Redirect to={routeMatch.url} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

const NumRequestsTab: React.FC<{
  quoteRequestStatus: QuoteRequestQueryQuoteRequestStatus;
  quoteOfferStatus?: QuoteRequestQueryQuoteOfferStatus;
  quoteStatus?: QuoteRequestQueryQuoteStatus;
  fetchingCompleted: boolean;
}> = ({
  quoteRequestStatus,
  quoteOfferStatus,
  quoteStatus,
  fetchingCompleted,
}) => {
  const queryResult = useQuery<
    QuoteRequestsNumTabQuery,
    QuoteRequestsNumTabQueryVariables
  >(QUOTE_REQUESTS_NUM_TAB_QUERY, {
    variables: {
      quoteRequestStatus,
      quoteOfferStatus,
      quoteStatus,
    },
    fetchPolicy: "cache-and-network",
    skip: !fetchingCompleted,
  });

  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return (
    <TabCount
      count={queryResult.data.quoteRequestsByQuoteOfferStatus.totalCount}
    />
  );
};

const PriorityNumRequestsTab: React.FC<{
  fetchingCompleted: boolean;
}> = (fetchingCompleted) => {
  const queryResult = useQuery<
    QuoteRequestsPriorityNumTabQuery,
    QuoteRequestsPriorityNumTabQueryVariables
  >(QUOTE_REQUESTS_PRIORITY_NUM_TAB_QUERY, {
    fetchPolicy: "cache-and-network",
    skip: !fetchingCompleted,
  });
  if (queryResult.error) {
    throw queryResult.error;
  }

  if (!queryResult.data || queryResult.loading) {
    return null;
  }

  return <TabCount count={queryResult.data.priorityQuoteRequests.totalCount} />;
};

export default QuoteRequestsPage;
