import React, { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { MenuAlt2Icon, XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { LocationDescriptor } from "history";
import { UserCircleIcon } from "@heroicons/react/solid";
import logoSrc from "./logo.svg";

type SidebarNavigationItem = {
  name: string;
  target: LocationDescriptor;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  visible: boolean;
};

const userNavigation = [{ name: "Déconnexion", href: "#" }];

const MainLayout: React.FC<
  React.ComponentProps<"div"> & {
    sidebarNavigationItems: SidebarNavigationItem[];
  }
> = ({ sidebarNavigationItems, children, className, ...props }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div
      className={classNames(
        "flex h-screen overflow-hidden bg-gray-50",
        className
      )}
      {...props}
    >
      {/* Narrow sidebar */}
      <div className="hidden w-28 overflow-y-auto bg-blue-700 md:block">
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex shrink-0 items-center">
            <img className="h-8 w-auto" src={logoSrc} alt="Workflow" />
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
            {sidebarNavigationItems.map(
              (item) =>
                item.visible && (
                  <NavLink
                    key={item.name}
                    to={item.target}
                    activeClassName="!bg-blue-800 !text-white"
                    className={classNames(
                      "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium text-blue-100 hover:bg-blue-800 hover:text-white"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        "h-6 w-6 text-blue-300 group-hover:text-white"
                      )}
                      aria-hidden="true"
                    />
                    <span className="mt-2">{item.name}</span>
                  </NavLink>
                )
            )}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="md:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-blue-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button
                      type="button"
                      className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex shrink-0 items-center px-4">
                  <img
                    className="h-8 w-auto"
                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                    alt="Workflow"
                  />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {sidebarNavigationItems.map(
                        (item) =>
                          item.visible && (
                            <NavLink
                              key={item.name}
                              to={item.target}
                              activeClassName="!bg-blue-800 !text-white"
                              className={classNames(
                                "group flex items-center rounded-md py-2 px-3 text-sm font-medium text-blue-100 hover:bg-blue-800 hover:text-white"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  "mr-3 h-6 w-6 text-blue-300 group-hover:text-white"
                                )}
                                aria-hidden="true"
                              />
                              <span>{item.name}</span>
                            </NavLink>
                          )
                      )}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Content area */}
      <div className="flex flex-1 flex-col overflow-hidden">
        <header className="w-full">
          <div className="relative z-10 flex h-16 shrink-0 border-b border-gray-200 bg-white shadow-sm">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4 sm:px-6">
              <div className="flex flex-1" />
              <div className="ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6">
                {/* Profile dropdown */}
                <Menu as="div" className="relative shrink-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon className="h-8 w-8" />
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <a
                                  href={item.href}
                                  className={classNames(
                                    {
                                      "bg-gray-100": active,
                                    },
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {item.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </header>

        {/* Main content */}
        <div className="flex flex-1 items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            {/* Primary column */}
            <section
              aria-labelledby="primary-heading"
              className="relative mx-auto px-8"
            >
              <div className="pt-10 pb-16">{children}</div>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
