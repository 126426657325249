import React, { useMemo } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { NavLink } from "react-router-dom";

const range = (start: number, end: number) => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

const DOTS = "DOTS" as const;

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}: {
  totalCount: number;
  pageSize: number;
  siblingCount?: number;
  currentPage: number;
}): Array<number | typeof DOTS> => {
  return useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    const middleRange = range(leftSiblingIndex, rightSiblingIndex);
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
  }, [totalCount, pageSize, siblingCount, currentPage]);
};

const Pagination: React.FC<
  React.ComponentProps<"div"> & {
    currentPage: number;
    pageSize: number;
    totalCount: number;
    getPageLocation: (
      page: number
    ) => React.ComponentProps<typeof NavLink>["to"];
  }
> = ({
  className,
  pageSize,
  currentPage,
  totalCount,
  getPageLocation,
  ...props
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount: 2,
    pageSize,
  });

  const lastPage = paginationRange[paginationRange.length - 1] ?? 1;

  return (
    <div
      className={classNames(
        "flex items-center justify-between px-4 py-3 sm:px-6",
        className
      )}
      {...props}
    >
      <div className="flex flex-1 justify-between space-x-3 sm:hidden">
        <NavLink
          to={
            currentPage === 1
              ? getPageLocation(currentPage)
              : getPageLocation(currentPage - 1)
          }
          isActive={() => currentPage === 1}
          className={classNames(
            "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50",
            {
              "cursor-not-allowed bg-gray-200 text-gray-400": currentPage === 1,
            }
          )}
        >
          Précécent
        </NavLink>
        <NavLink
          to={
            currentPage === lastPage
              ? getPageLocation(currentPage)
              : getPageLocation(currentPage + 1)
          }
          isActive={() => currentPage === lastPage}
          className={classNames(
            "relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50",
            {
              "cursor-not-allowed bg-gray-200 text-gray-400":
                currentPage === lastPage,
            }
          )}
        >
          Suivant
        </NavLink>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Elements{" "}
            <span className="font-medium">
              {(currentPage - 1) * pageSize + 1}
            </span>{" "}
            à <span className="font-medium">{currentPage * pageSize}</span> sur{" "}
            <span className="font-medium">{totalCount}</span> résultats
          </p>
        </div>
        <div>
          <nav
            className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <NavLink
              to={
                currentPage === 1
                  ? getPageLocation(currentPage)
                  : getPageLocation(currentPage - 1)
              }
              isActive={() => currentPage === 1}
              className={classNames(
                "relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50",
                {
                  "cursor-not-allowed bg-gray-200 text-gray-400":
                    currentPage === 1,
                }
              )}
            >
              <span className="sr-only">Précécent</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>
            {paginationRange.map((pageNumber) => {
              if (pageNumber === DOTS) {
                return (
                  <span
                    key={pageNumber}
                    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
                  >
                    ...
                  </span>
                );
              }

              return (
                <NavLink
                  key={pageNumber}
                  className={classNames({
                    "relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50":
                      pageNumber !== currentPage,
                    "relative z-10 inline-flex items-center border border-blue-500 bg-blue-50 px-4 py-2 text-sm font-medium text-blue-600":
                      pageNumber === currentPage,
                  })}
                  to={getPageLocation(pageNumber)}
                >
                  {pageNumber}
                </NavLink>
              );
            })}
            <NavLink
              to={
                currentPage === lastPage
                  ? getPageLocation(currentPage)
                  : getPageLocation(currentPage + 1)
              }
              className={classNames(
                "relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50",
                {
                  "cursor-not-allowed bg-gray-200 text-gray-400":
                    currentPage === lastPage,
                }
              )}
            >
              <span className="sr-only">Suivant</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
