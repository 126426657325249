import classNames from "classnames";
import React from "react";

const Cell: React.FC<{
  title: React.ReactNode;
}> = ({ title, children }) => (
  <div className="space-y-1">
    <p className="text-sm font-medium text-gray-500">{title}</p>
    <p className="text-sm font-normal text-gray-900">{children}</p>
  </div>
);

const _DataGrid: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      "grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2",
      className
    )}
    {...props}
  />
);

const DataGrid = Object.assign(_DataGrid, { Cell });

export default DataGrid;
