import { gql } from "graphql-tag";
import React, { useState, useCallback, useRef } from "react";
import { FormattedRelativeTime, useIntl } from "react-intl";
import { differenceInSeconds } from "date-fns";
import { CommentsCardCommentFragment } from "../../graphql-operations-types";
import Card from "../molecules/card";
import StackedList from "../molecules/stacked-list";
import TextArea from "../atoms/text-area";
import Button from "../atoms/button";
import GetBackOfficeUser from "../../login-provider";

const COMMENT_FRAGMENT = gql`
  fragment CommentsCardComment on BackOfficeComment {
    id
    createdAt
    content
    backOfficeUser {
      id
      fullName
      emailAddress
    }
  }
`;

const Comment: React.FC<
  React.ComponentProps<typeof StackedList.Item> & {
    comment: CommentsCardCommentFragment;
  }
> = ({ comment, ...props }) => {
  const intl = useIntl();
  return (
    <StackedList.Item {...props}>
      <p className="text-sm font-medium text-gray-900">
        {comment.backOfficeUser.fullName}{" "}
        <a href={`mailto:${comment.backOfficeUser.emailAddress}`}>
          <small>&lt;{comment.backOfficeUser.emailAddress}&gt;</small>
        </a>
      </p>
      <p
        title={intl.formatDate(comment.createdAt, {
          dateStyle: "full",
          timeStyle: "long",
        })}
        className="mt-0.5 text-sm text-gray-500"
      >
        <FormattedRelativeTime
          value={differenceInSeconds(new Date(comment.createdAt), new Date())}
          updateIntervalInSeconds={1}
        />
      </p>
      <p className="mt-2 text-sm">{comment.content}</p>
    </StackedList.Item>
  );
};

const CommentsCard: React.FC<
  React.ComponentProps<typeof Card> & {
    comments?: React.ReactNode;
    onSubmitComment?: (content: string) => void;
  }
> = ({ comments, onSubmitComment, ...props }) => {
  const [inputContent, setInputContent] = useState("");
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = useCallback(() => {
    onSubmitComment && onSubmitComment(inputContent);
    setInputContent("");
  }, [inputContent, onSubmitComment]);
  const handleFormSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit();
    },
    [handleSubmit]
  );
  const handleTextAreaKeyPress: React.KeyboardEventHandler<HTMLTextAreaElement> =
    useCallback(
      (event) => {
        if (event.key === "Enter" && event.shiftKey) {
          event.preventDefault();
          handleSubmit();
        }
      },
      [handleSubmit]
    );
  const backofficeUser = GetBackOfficeUser();

  return (
    <Card {...props}>
      <Card.Header title="Commentaires" />
      <Card.Section padded={false}>
        <StackedList>{comments}</StackedList>
      </Card.Section>
      {!backofficeUser?.isViewer && (
        <Card.Section className="bg-gray-50">
          <form ref={formRef} onSubmit={handleFormSubmit}>
            <TextArea
              className="block w-full"
              rows={4}
              placeholder="Ajouter une note"
              onChange={(event) => setInputContent(event.currentTarget.value)}
              value={inputContent}
              onKeyPress={handleTextAreaKeyPress}
            />
            <div className="mt-4 flex justify-end">
              <Button type="submit" disabled={inputContent.length === 0}>
                Ajouter
              </Button>
            </div>
          </form>
        </Card.Section>
      )}
    </Card>
  );
};

export default Object.assign(CommentsCard, {
  Comment,
  COMMENT_FRAGMENT,
});
